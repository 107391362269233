import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Box,
    Typography,
    CircularProgress,
    Fade,
    useTheme,
    IconButton,
    alpha,
} from '@mui/material';
import {
    AccessTime as AccessTimeIcon,
    ExitToApp as ExitToAppIcon,
    Refresh as RefreshIcon,
    Close as CloseIcon,
} from '@mui/icons-material';
import api from '../services/api';

interface SessionTimeoutModalProps {
    open: boolean;
    onExtend: () => void;
    onLogout: () => void;
    remainingTime: number;
}

const SessionTimeoutModal: React.FC<SessionTimeoutModalProps> = ({ 
    open, 
    onExtend, 
    onLogout, 
    remainingTime 
}) => {
    const theme = useTheme();
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    const progress = (remainingTime / 300) * 100; // Assuming 5 minutes total

    return (
        <Dialog 
            open={open}
            TransitionComponent={Fade}
            transitionDuration={400}
            PaperProps={{
                elevation: 5,
                sx: {
                    borderRadius: 3,
                    minWidth: 400,
                    background: alpha(theme.palette.background.paper, 0.8),
                    backdropFilter: 'blur(10px)',
                    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                }
            }}
        >
            <Box sx={{ position: 'relative' }}>
                <IconButton
                    onClick={onLogout}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: alpha(theme.palette.error.main, 0.7),
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                            color: theme.palette.error.main,
                            transform: 'rotate(90deg)',
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogTitle sx={{ 
                    pr: 6,
                    pt: 3,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.5,
                }}>
                    <AccessTimeIcon 
                        sx={{ 
                            color: theme.palette.warning.main,
                            fontSize: 28,
                            filter: `drop-shadow(0 2px 4px ${alpha(theme.palette.warning.main, 0.4)})`,
                        }} 
                    />
                    <Typography 
                        variant="h6" 
                        component="span" 
                        sx={{
                            fontWeight: 700,
                            background: `linear-gradient(135deg, ${theme.palette.warning.main}, ${theme.palette.warning.dark})`,
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            textShadow: `0 2px 4px ${alpha(theme.palette.warning.main, 0.2)}`,
                        }}
                    >
                        Session Timeout Warning
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box sx={{ 
                        position: 'relative', 
                        mb: 2,
                        height: 120, 
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CircularProgress
                            variant="determinate"
                            value={100}
                            size={100} 
                            thickness={4}
                            sx={{
                                color: alpha(theme.palette.divider, 0.1),
                                position: 'absolute',
                            }}
                        />
                        <CircularProgress
                            variant="determinate"
                            value={progress}
                            size={100} 
                            thickness={4}
                            sx={{
                                color: theme.palette.warning.main,
                                position: 'absolute',
                                filter: `drop-shadow(0 0 8px ${alpha(theme.palette.warning.main, 0.4)})`,
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <Typography 
                                variant="h4" 
                                color="text.primary" 
                                sx={{ 
                                    fontWeight: 700,
                                    lineHeight: 1.2,
                                    letterSpacing: 1,
                                }}
                            >
                                {minutes}:{seconds.toString().padStart(2, '0')}
                            </Typography>
                            <Typography 
                                variant="caption" 
                                sx={{ 
                                    mt: 0.5,
                                    color: alpha(theme.palette.text.secondary, 0.8),
                                    fontWeight: 500,
                                    letterSpacing: 0.5,
                                }} 
                            >
                                remaining
                            </Typography>
                        </Box>
                    </Box>

                    <DialogContentText sx={{ 
                        textAlign: 'center',
                        color: alpha(theme.palette.text.secondary, 0.8),
                        mt: 2,
                        px: 2,
                        fontWeight: 500,
                    }}>
                        Your session is about to expire due to inactivity.
                        Would you like to extend your session?
                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ 
                    justifyContent: 'center',
                    gap: 2,
                    pb: 4,
                    px: 3,
                }}>
                    <Button 
                        onClick={onLogout}
                        variant="outlined"
                        color="error"
                        startIcon={<ExitToAppIcon />}
                        fullWidth
                        sx={{
                            py: 1,
                            borderRadius: 2,
                            borderWidth: 2,
                            fontWeight: 600,
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                                borderWidth: 2,
                                transform: 'translateY(-2px)',
                                boxShadow: `0 4px 12px ${alpha(theme.palette.error.main, 0.2)}`,
                            },
                        }}
                    >
                        Logout Now
                    </Button>
                    <Button 
                        onClick={onExtend}
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon />}
                        fullWidth
                        autoFocus
                        sx={{
                            py: 1,
                            borderRadius: 2,
                            fontWeight: 600,
                            background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                            boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                                background: `linear-gradient(135deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                                transform: 'translateY(-2px)',
                                boxShadow: `0 6px 16px ${alpha(theme.palette.primary.main, 0.3)}`,
                            },
                            '&:active': {
                                transform: 'translateY(1px)',
                            }
                        }}
                    >
                        Extend Session
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default SessionTimeoutModal;
