import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { saveSavingsAllocations } from '../store/userDataSlice';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    Grid,
    Select,
    MenuItem,
    TextField,
    Alert,
    IconButton,
    Paper,
    Chip,
    Tooltip,
    useTheme,
    Fade,
    Slide,
    Stack,
    Divider,
    LinearProgress,
    alpha
} from '@mui/material';
import { 
    Add as AddIcon,
    Delete as DeleteIcon,
    Info as InfoIcon,
    AccountBalance as AccountBalanceIcon,
} from '@mui/icons-material';
import { savingsAllocation } from '../types';
import { v4 as uuidv4 } from 'uuid';
import api from '../services/api';

interface NetSavingsAllocationProps {
    open: boolean;
    onClose: () => void;
    initialAllocations: savingsAllocation[];
    allCategories: string[];
}

const NetSavingsAllocation: React.FC<NetSavingsAllocationProps> = ({
    open,
    onClose,
    initialAllocations,
    allCategories,
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [currentAllocations, setCurrentAllocations] = useState(initialAllocations);
    const [editedAllocations, setEditedAllocations] = useState<savingsAllocation[]>(initialAllocations);
    const [allocationError, setAllocationError] = useState<string | null>('');
    
    useEffect(() => {
        setEditedAllocations(initialAllocations);
        setCurrentAllocations(initialAllocations);
    }, [initialAllocations]);
    
    const handleAllocationChange = (allocationId: string, category: string, percentage: number, index: number) => {
        const newAllocations = [...editedAllocations];
        newAllocations[index] = { id: allocationId, category, percentage };
        setEditedAllocations(newAllocations);
        validateAllocations(newAllocations);
    };

    const handleAddAllocation = () => {
        const unusedCategories = allCategories.filter(
            category => !editedAllocations.some(allocation => allocation.category === category)
        );
        if (unusedCategories.length > 0) {
            setEditedAllocations([
                ...editedAllocations,
                { id: uuidv4(), category: unusedCategories[0], percentage: 0 }
            ]);
        }
    };

    const handleRemoveAllocation = (allocationId: string) => {
        setEditedAllocations(editedAllocations.filter(
            allocation => allocation.id !== allocationId
        ));
    };

    const validateAllocations = (allocations: savingsAllocation[]) => {
        const total = allocations.reduce((sum, allocation) => sum + allocation.percentage, 0);
        if (Math.abs(total - 100) > 0.01) { // Allow for small floating point errors
            setAllocationError('Total allocation must equal 100%');
            return false;
        }
        setAllocationError('');
        return true;
    };

    const onSave = async (savedAllocations: savingsAllocation[], updatedAllocations: savingsAllocation[]) => {
        let deleteIds: string[] = [];
        let updateIds: savingsAllocation[] = [];
        let addIds: savingsAllocation[] = [];

        savedAllocations.forEach(allocation => {
            if (!updatedAllocations.some(updated => updated.id === allocation.id)) {
                deleteIds.push(allocation.id);
            }
        });

        updatedAllocations.forEach(updated => {
            if (!savedAllocations.some(saved => saved.id === updated.id)) {
                addIds.push(updated);
        }
        });

        updatedAllocations.forEach(updated => {
            if (savedAllocations.some(saved => (saved.id === updated.id) && ((saved.percentage !== updated.percentage) || (saved.category !== updated.category)))) {
                updateIds.push(updated);
            } 
        });
        
        if (deleteIds.length > 0) {
            await Promise.all(deleteIds.map(async (id) => {
                try {
                    await api.delete(`/deleteSavingAllocation/${id}`);
                } catch (error) {
                    console.error('Error deleting saving allocation:', error);
                }
            }));
        }
        
        if (addIds.length > 0) {
            await Promise.all(addIds.map(async (allocation) => {
                try {
                    await api.post(`/addSavingAllocation`,
                        allocation
                    );
        } catch (error) {
                    console.error('Error adding saving allocation:', error);
                }
            }));
        }

        if (updateIds.length > 0) {
            await Promise.all(updateIds.map(async (allocation) => {
                try {
                    await api.put(`/updateSavingAllocation/${allocation.id}`,
                        allocation
                    );
                } catch (error) {
                    console.error('Error updating saving allocation:', error);
        }
            }));
        }
        setCurrentAllocations(editedAllocations);
        dispatch(saveSavingsAllocations(editedAllocations));
    };

    const handleSave = async () => {
        if (validateAllocations(editedAllocations)) {
            await onSave(currentAllocations,editedAllocations);
            onClose();
        }
    };

    const totalAllocation = editedAllocations.reduce((sum, allocation) => sum + allocation.percentage, 0);

    return (
        <Dialog 
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            TransitionComponent={Fade}
            transitionDuration={400}
            PaperProps={{
                elevation: theme.palette.mode === 'dark' ? 2 : 1,
                sx: {
                    borderRadius: 3,
                    background: theme.palette.mode === 'dark'
                        ? alpha(theme.palette.background.paper, 0.8)
                        : theme.palette.background.paper,
                    backdropFilter: 'blur(10px)',
                    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                    boxShadow: theme.palette.mode === 'dark'
                        ? `0 4px 20px 0 ${alpha(theme.palette.common.black, 0.3)}`
                        : `0 8px 32px 0 ${alpha(theme.palette.primary.main, 0.1)}`,
                }
            }}
        >
            <DialogTitle sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                p: 3,
                pb: 0,
            }}>
                <AccountBalanceIcon 
                    sx={{ 
                        fontSize: 32,
                        color: theme.palette.primary.main,
                        filter: `drop-shadow(0 2px 4px ${alpha(theme.palette.primary.main, 0.4)})`,
                    }} 
                />
                <Typography variant="h5" component="span" fontWeight={700}>
                    Net Savings Allocation
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ p: 3 }}>
                <Box sx={{ mb: 4 }}>
                    <Typography 
                        variant="body1" 
                        color="text.secondary" 
                        sx={{ 
                            mb: 3,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <InfoIcon fontSize="small" />
                        Allocate your net savings across different investment categories. The total allocation must equal 100%.
                    </Typography>

                    <Paper 
                        elevation={0} 
                        sx={{ 
                            p: 3, 
                            border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                            borderRadius: 2,
                            mb: 3,
                            background: alpha(theme.palette.background.default, 0.5),
                        }}
                    >
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Box flexGrow={1}>
                                <LinearProgress 
                                    variant="determinate" 
                                    value={totalAllocation} 
                                    sx={{
                                        height: 10,
                                        borderRadius: 5,
                                        backgroundColor: alpha(theme.palette.action.hover, 0.1),
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: totalAllocation === 100 
                                                ? theme.palette.success.main 
                                                : theme.palette.warning.main,
                                            transition: 'transform 0.4s ease-in-out, background-color 0.4s ease-in-out',
                                        }
                                    }}
                                />
                            </Box>
                            <Chip
                                label={`${totalAllocation}% Allocated`}
                                color={totalAllocation === 100 ? "success" : "warning"}
                                size="small"
                                variant="outlined"
                                sx={{ 
                                    minWidth: 110,
                                    fontWeight: 600,
                                    borderWidth: 2,
                                }}
                            />
                        </Stack>
                    </Paper>

                    {allocationError && (
                        <Slide direction="down" in={true}>
                            <Alert 
                                severity="error" 
                                sx={{ 
                                    mb: 3,
                                    borderRadius: 2,
                                    boxShadow: `0 2px 8px ${alpha(theme.palette.error.main, 0.15)}`,
                                }}
                                onClose={() => setAllocationError(null)}
                            >
                                {allocationError}
                            </Alert>
                        </Slide>
                    )}

                    <Grid container spacing={2}>
                        {editedAllocations.map((allocation, index) => (
                            <Slide 
                                direction="right" 
                                in={true} 
                                key={allocation.id}
                                timeout={300 + index * 100}
                            >
                                <Grid item xs={12}>
                                    <Paper 
                                        elevation={0}
                                        sx={{ 
                                            p: { xs: 2, sm: 3 },
                                            border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                                            borderRadius: 2,
                                            transition: 'all 0.2s ease-in-out',
                                            '&:hover': {
                                                background: alpha(theme.palette.primary.main, 0.02),
                                                borderColor: alpha(theme.palette.primary.main, 0.1),
                                            }
                                        }}
                                    >
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={12} sm={5}>
                                                <Select
                                                    value={allocation.category}
                                                    onChange={(e) => handleAllocationChange(
                                                        allocation.id,
                                                        e.target.value as string,
                                                        allocation.percentage,
                                                        index
                                                    )}
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: alpha(theme.palette.divider, 0.2),
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: theme.palette.primary.main,
                                                        },
                                                        borderRadius: 1.5,
                                                    }}
                                                >
                                                    {allCategories.map((category) => (
                                                        <MenuItem 
                                                            key={category} 
                                                            value={category}
                                                            disabled={editedAllocations.some(
                                                                a => a.category === category && a.id !== allocation.id
                                                            )}
                                                        >
                                                            {category}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <TextField
                                                    type="number"
                                                    value={allocation.percentage}
                                                    onChange={(e) => handleAllocationChange(
                                                        allocation.id,
                                                        allocation.category,
                                                        parseFloat(e.target.value) || 0,
                                                        index
                                                    )}
                                                    fullWidth
                                                    size="small"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <Typography 
                                                                variant="body2" 
                                                                color="text.secondary"
                                                                sx={{ fontWeight: 600 }}
                                                            >
                                                                %
                                                            </Typography>
                                                        ),
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: 1.5,
                                                            '& fieldset': {
                                                                borderColor: alpha(theme.palette.divider, 0.2),
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={2} sx={{ textAlign: 'right' }}>
                                                <Tooltip title="Remove allocation">
                                                    <IconButton
                                                        onClick={() => handleRemoveAllocation(allocation.id)}
                                                        color="error"
                                                        size="small"
                                                        sx={{
                                                            transition: 'all 0.2s ease-in-out',
                                                            '&:hover': {
                                                                backgroundColor: alpha(theme.palette.error.main, 0.1),
                                                                transform: 'scale(1.1)',
                                                            },
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Slide>
                        ))}
                    </Grid>

                    {editedAllocations.length < allCategories.length && (
                        <Button
                            startIcon={<AddIcon />}
                            onClick={handleAddAllocation}
                            sx={{ 
                                mt: 3,
                                borderRadius: 2,
                                textTransform: 'none',
                                fontWeight: 600,
                                px: 2,
                                py: 1,
                                
                                border: `2px dashed ${alpha(theme.palette.primary.main, 0.2)}`,
                                backgroundColor: alpha(theme.palette.primary.main, 0.02),
                                '&:hover': {
                                    backgroundColor: alpha(theme.palette.primary.main, 0.05),
                                    border: `2px dashed ${alpha(theme.palette.primary.main, 0.3)}`,
                                }
                            }}
                        >
                            Add Allocation
                        </Button>
                    )}
                </Box>
            </DialogContent>

            <DialogActions sx={{ px: 3, pb: 3, gap: 2 }}>
                <Button 
                    onClick={onClose} 
                    variant="outlined"
                    sx={{
                        borderRadius: 2,
                        px: 3,
                        py: 1,
                        borderWidth: 2,
                        '&:hover': {
                            borderWidth: 2,
                        }
                    }}
                >
                    Cancel
                </Button>
                <Button 
                    onClick={handleSave}
                    variant="contained"
                    disabled={!!allocationError || totalAllocation !== 100}
                    sx={{
                        borderRadius: 2,
                        px: 3,
                        py: 1,
                        boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
                        background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                            transform: 'translateY(-1px)',
                            boxShadow: `0 6px 16px ${alpha(theme.palette.primary.main, 0.3)}`,
                        },
                        '&:active': {
                            transform: 'translateY(1px)',
                        }
                    }}
                >
                    Save Allocations
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NetSavingsAllocation;
