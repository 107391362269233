import React, { useState } from 'react';
import api from '../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { addOneTimeExpense, removeOneTimeExpense } from '../store/userDataSlice';
import {
    TextField,
    Button,
    Grid,
    Typography,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    useTheme,
    Fade,
    Grow,
    Zoom,
    Alert,
    Divider,
    Tooltip,
    ListItemIcon,
} from '@mui/material';
import {
    Delete as DeleteIcon,
    Add as AddIcon,
    Event as EventIcon,
    AttachMoney as MoneyIcon,
    Description as DescriptionIcon,
} from '@mui/icons-material';
import { OneTimeExpense } from '../types';
import { v4 as uuidv4 } from 'uuid';

const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value);
};

const OneTimeExpenseForm: React.FC = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { oneTimeExpenses } = useSelector((state: RootState) => state.userData);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const [newExpense, setNewExpense] = useState<OneTimeExpense>({
        id: '',
        description: '',
        amount: 0,
        year: new Date().getFullYear(),
    });

    const [displayAmount, setDisplayAmount] = useState('');

    const handleInputChange = (field: keyof OneTimeExpense) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setError(null);
        if (field === 'amount') {
            // Remove all non-numeric characters from input
            const numericValue = event.target.value.replace(/[^0-9]/g, '');
            const numberValue = parseInt(numericValue) || 0;

            setNewExpense({
                ...newExpense,
                amount: numberValue
            });

            setDisplayAmount(formatCurrency(numberValue));
        } else {
            setNewExpense({
                ...newExpense,
                [field]: field === 'description' 
                    ? event.target.value 
                    : parseFloat(event.target.value)
            });
        }
    };

    const handleAddExpense = async () => {
        try {
            setIsSubmitting(true);
            setError(null);
            
            if (newExpense.description && newExpense.amount > 0) {
                const newId = uuidv4();
                const response = await api.post('/addOneTimeExpense', {
                    ...newExpense,
                    id: newId
                });

                // Update local state with the new expense
                dispatch(addOneTimeExpense(response.data));
                
                // Reset form
                setNewExpense({
                    id: '',
                    description: '',
                    amount: 0,
                    year: new Date().getFullYear(),
                });
                setDisplayAmount('');
                setSuccess('Expense added successfully');
                setTimeout(() => setSuccess(null), 3000);
            }
        } catch (error) {
            console.error('Error adding one-time expense:', error);
            setError('Failed to add expense. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleRemoveExpense = async (id: string) => {
        try {
            setError(null);
            await api.delete(`/deleteOneTimeExpense/${id}`);
            
            // Update local state by removing the deleted expense
            dispatch(removeOneTimeExpense(id));
            setSuccess('Expense removed successfully');
            setTimeout(() => setSuccess(null), 3000);
        } catch (error) {
            console.error('Error deleting one-time expense:', error);
            setError('Failed to remove expense. Please try again.');
        }
    };

    const isFormValid = newExpense.description && newExpense.amount > 0 || undefined;

    return (
        <Box>
            {(error || success) && (
                <Fade in timeout={500}>
                    <Alert 
                        severity={error ? "error" : "success"}
                        sx={{ 
                            mb: 2,
                            borderRadius: 2,
                            boxShadow: theme => `0 2px 8px ${theme.palette[error ? "error" : "success"].main}`,
                            '& .MuiAlert-icon': {
                                fontSize: '1.5rem'
                            }
                        }}
                    >
                        {error || success}
                    </Alert>
                </Fade>
            )}

            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Description"
                        value={newExpense.description}
                        onChange={handleInputChange('description')}
                        InputProps={{
                            startAdornment: (
                                <DescriptionIcon 
                                    sx={{ 
                                        mr: 1, 
                                        color: theme.palette.mode === 'dark' 
                                            ? theme.palette.primary.main
                                            : theme.palette.primary.main
                                    }} 
                                />
                            ),
                            sx: {
                                borderRadius: 2,
                            }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: theme.palette.divider,
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.primary.main,
                                    borderWidth: 2,
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Amount"
                        value={displayAmount}
                        onChange={handleInputChange('amount')}
                        InputProps={{
                            startAdornment: (
                                <MoneyIcon 
                                    sx={{ 
                                        mr: 1, 
                                        color: theme.palette.mode === 'dark' 
                                            ? theme.palette.primary.main
                                            : theme.palette.primary.main
                                    }} 
                                />
                            ),
                            inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
                            sx: {
                                borderRadius: 2,
                            }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: theme.palette.divider,
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.primary.main,
                                    borderWidth: 2,
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Year"
                        type="number"
                        value={newExpense.year}
                        onChange={handleInputChange('year')}
                        InputProps={{ 
                            startAdornment: (
                                <EventIcon 
                                    sx={{ 
                                        mr: 1, 
                                        color: theme.palette.mode === 'dark' 
                                            ? theme.palette.primary.main
                                            : theme.palette.primary.main
                                    }} 
                                />
                            ),
                            inputProps: { 
                                min: new Date().getFullYear(),
                                max: new Date().getFullYear() + 80 
                            },
                            sx: {
                                borderRadius: 2,
                            }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: theme.palette.divider,
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.primary.main,
                                    borderWidth: 2,
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Zoom in={isFormValid}>
                            <Button 
                                variant="contained"
                                onClick={handleAddExpense}
                                disabled={!isFormValid || isSubmitting}
                                startIcon={<AddIcon />}
                                sx={{
                                    px: 4,
                                    py: 1.5,
                                    borderRadius: 2,
                                    fontWeight: 600,
                                    background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                                    boxShadow: `0 4px 12px ${theme.palette.primary.main}`,
                                    transition: 'all 0.2s ease-in-out',
                                    '&:hover': {
                                        transform: 'translateY(-2px)',
                                        boxShadow: `0 6px 16px ${theme.palette.primary.main}`,
                                    },
                                    '&:active': {
                                        transform: 'translateY(1px)',
                                    }
                                }}
                            >
                                {isSubmitting ? 'Adding...' : 'Add Expense'}
                            </Button>
                        </Zoom>
                    </Box>
                </Grid>
            </Grid>

            <Box mt={4}>
                {oneTimeExpenses.length > 0 && (
                    <>
                        <Divider sx={{ 
                            my: 3,
                            borderColor: theme.palette.divider,
                        }} />
                        <Typography 
                            variant="h6" 
                            sx={{ 
                                mb: 3,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                fontWeight: 700,
                                color: theme.palette.text.primary,
                            }}
                        >
                            <EventIcon 
                                sx={{ 
                                    color: theme.palette.primary.main,
                                    filter: `drop-shadow(0 2px 4px ${theme.palette.primary.main})`,
                                }} 
                            />
                            Planned Expenses
                        </Typography>
                    </>
                )}
                
                <List>
                    {oneTimeExpenses.map((expense, index) => (
                        <Grow 
                            key={expense.id} 
                            in 
                            timeout={(index + 1) * 200}
                        >
                            <ListItem
                                sx={{
                                    mb: 1.5,
                                    borderRadius: 2,
                                    border: `1px solid ${theme.palette.divider}`,
                                    bgcolor: theme.palette.mode === 'dark'
                                        ? theme.palette.background.paper
                                        : theme.palette.background.paper,
                                    backdropFilter: 'blur(10px)',
                                    transition: 'all 0.2s ease-in-out',
                                    '&:hover': {
                                        transform: 'translateX(4px)',
                                        boxShadow: `0 4px 12px ${theme.palette.primary.main}`,
                                        borderColor: theme.palette.primary.main,
                                    },
                                }}
                            >
                                <ListItemIcon>
                                    <MoneyIcon 
                                        sx={{ 
                                            color: theme.palette.primary.main,
                                            filter: `drop-shadow(0 2px 4px ${theme.palette.primary.main})`,
                                        }} 
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={expense.description}
                                    secondary={`${formatCurrency(expense.amount)} in ${expense.year}`}
                                    primaryTypographyProps={{
                                        fontWeight: 600,
                                        color: theme.palette.text.primary,
                                    }}
                                    secondaryTypographyProps={{
                                        sx: {
                                            color: theme.palette.text.secondary,
                                            fontWeight: 500,
                                        }
                                    }}
                                />
                                <ListItemSecondaryAction>
                                    <Tooltip title="Remove expense" placement="left">
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => handleRemoveExpense(expense.id)}
                                            sx={{
                                                color: theme.palette.error.main,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    transform: 'scale(1.1) rotate(8deg)',
                                                    bgcolor: 'white',
                                                },
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Grow>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default OneTimeExpenseForm;
