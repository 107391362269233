import React from 'react';
import {
    TableRow,
    TableCell,
    IconButton,
    Typography,
    Collapse,
    Box,
    useTheme,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { alpha } from '@mui/material/styles';
import dayjs from 'dayjs';

interface CollapsibleSectionProps {
    title: string;
    isOpen: boolean;
    onToggle: () => void;
    content: React.ReactNode;
    months?: Date[];
    monthlyTotals?: number[];
    formatCurrency?: (value: number) => string;
    yearlyFlag?: boolean;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
    title,
    isOpen,
    onToggle,
    content,
    months,
    monthlyTotals,
    formatCurrency = (value: number) => value.toString(),
    yearlyFlag
}) => {
    const theme = useTheme();
    
    return (
        <>
            <TableRow 
                sx={{ 
                    backgroundColor: alpha(theme.palette.primary.main, 0.04),
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    },
                    '& > *': { 
                        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                        py: 1.5
                    },
                    cursor: 'pointer'
                }}
                onClick={onToggle}
            >
                <TableCell sx={{ width: 48, pl: 1 }}>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            onToggle();
                        }}
                        sx={{
                            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                            transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
                            color: theme.palette.primary.main,
                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.15),
                                transform: isOpen 
                                    ? 'rotate(-180deg) scale(1.1)' 
                                    : 'rotate(0) scale(1.1)',
                            },
                            '&:active': {
                                transform: isOpen 
                                    ? 'rotate(-180deg) scale(0.95)' 
                                    : 'rotate(0) scale(0.95)',
                            }
                        }}
                    >
                        <KeyboardArrowDownIcon />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Typography 
                        variant="subtitle1" 
                        sx={{
                            fontWeight: 600,
                            color: theme.palette.text.primary,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            transition: 'color 0.2s ease',
                            '&:hover': {
                                color: theme.palette.primary.main
                            }
                        }}
                    >
                        {title}
                    </Typography>
                </TableCell>
                {months && monthlyTotals && monthlyTotals.map((total, index) => (
                    <TableCell 
                        key={months[index] ? dayjs(months[index]).toISOString() : index} 
                        align="right"
                        sx={{
                            fontWeight: 500,
                            transition: 'all 0.2s ease',
                            position: 'relative',
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.08),
                            },
                            '&::after': isOpen ? {
                                content: '""',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                height: 2,
                                backgroundColor: theme.palette.primary.main,
                                transform: 'scaleX(0)',
                                transition: 'transform 0.3s ease',
                            } : {},
                            '&:hover::after': isOpen ? {
                                transform: 'scaleX(1)',
                            } : {}
                        }}
                    >
                        {formatCurrency(total)}
                    </TableCell>
                ))}

                {monthlyTotals && yearlyFlag && (
                    <TableCell 
                        align="right"
                        sx={{
                            fontWeight: 600,
                            color: theme.palette.text.primary,
                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                            borderLeft: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                            position: 'relative',
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.12),
                            }
                        }}
                    >
                        {formatCurrency(monthlyTotals.reduce((sum, value) => sum + value, 0))}
                    </TableCell>
                )}
            </TableRow>
            <TableRow>
                <TableCell 
                    style={{ padding: 0 }} 
                    colSpan={months ? months.length + 3 : 2}
                >
                    <Collapse 
                        in={isOpen} 
                        timeout={300}
                        unmountOnExit
                    >
                        <Box 
                            sx={{ 
                                margin: 0,
                                backgroundColor: alpha(theme.palette.background.paper, 0.5),
                                borderRadius: theme.shape.borderRadius,
                                overflow: 'hidden',
                                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                                boxShadow: isOpen 
                                    ? `inset 0 2px 4px ${alpha(theme.palette.common.black, 0.05)},
                                       inset 0 -1px 1px ${alpha(theme.palette.common.black, 0.02)}`
                                    : 'none',
                                '& .MuiTableCell-root': {
                                    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.08)}`
                                }
                            }}
                        >
                            {content}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default CollapsibleSection;
