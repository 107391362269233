import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { addLineItem, removeLineItem, updateLineItem } from '../../store/userDataSlice';
import dayjs from 'dayjs';
import api from '../../services/api';
import {
    Container,
    Typography,
    Paper,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tab,
} from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridRowModesModel, GridRowModes, GridEventListener, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { 
    LineItem, 
    LineItemType, 
    FrequencyType, 
    LineItemCategory, 
    IncomeCategory, 
    ExpenseCategory, 
    MonthlyProjection
} from '../../types/index';
import { v4 as uuidv4 } from 'uuid';
import ManageSavingsButton from '../ManageSavingsButton';
import CollapsibleSection from '../CollapsibleSection';
import { formatCurrency, formatDate } from '../../utils/formattingFunctions';
import {calculateYearlyTotal, aggregateByCategory, calculateYearlyCategoryTotal} from '../../utils/aggregationFunctions'
import { motion } from 'framer-motion';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

const StyledPaper = motion(Paper);

interface SummaryCardProps {
    title: string;
    amount: number;
    color: string;
}

const SummaryCard: React.FC<SummaryCardProps> = ({ title, amount, color }) => {
    return (
        <Paper 
            elevation={0}
            sx={{ 
                p: 3, 
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                bgcolor: 'background.paper',
            }}
        >
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                {title}
            </Typography>
            <Typography variant="h4" color={color} fontWeight="bold">
                {formatCurrency(amount)}
            </Typography>
        </Paper>
    );
};

const IncomeStatement = () => {
    const dispatch = useDispatch();
    const currentStatement = useSelector((state: RootState) => state.userData.currentStatement);
    const monthlyIncomeProjections = useSelector((state: RootState) => state.userData.monthlyIncomeProjections);
    const [displayedProjections, setDisplayedProjections] = useState<MonthlyProjection[]>([]);
    
    const [itemType, setItemType] = useState<LineItemType>('Income');
    const [category, setCategory] = useState<LineItemCategory>('Salary');
    const [frequency, setFrequency] = useState<FrequencyType>('Monthly');
    const [activeTab, setActiveTab] = useState(0);
    const [openSections, setOpenSections] = useState({
        Income: false,
        Taxes: false,
        Expenses: false
    });

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const yearlyFlag = true;

    const lineItemStringToDate = (lineItem: LineItem) => {
        const stringDate = lineItem.startDate.toString();
        lineItem.startDate = new Date(stringDate);
        return lineItem; 
    }

    // Update displayed projections when monthlyIncomeProjections changes
    useEffect(() => {
        setDisplayedProjections(monthlyIncomeProjections.slice(0, 12));
    }, [monthlyIncomeProjections]);

    const toggleSection = (section: 'Income' | 'Taxes' | 'Expenses') => {
        setOpenSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    // Format the line items for the DataGrid
    const rows = currentStatement.lineItems.map(item => ({
        ...item,
        type: item.type || 'N/A',
        frequency: item.frequency || 'N/A'
    }));

    // Handle datagrid changes
    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: string) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: string) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: string) => async () => {
        try {
            await api.delete(`/deleteIncomeStatementItem/${id}`);
            dispatch(removeLineItem(id));
        } catch (error) {
            console.error('Error deleting line item:', error);
        }
    };

    const handleCancelClick = (id: string) => async () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.isNew) {
            try {
                await api.delete(`/deleteIncomeStatementItem/${id}`);
                dispatch(removeLineItem(id));
            } catch (error) {
                console.error('Error deleting line item:', error);
            }
        }
    };

    const processRowUpdate = async (newRow: LineItem) => {
        try {
            const response = await api.put(`/updateIncomeStatementItem/${newRow.id}`, 
                { ...newRow, isNew: false }
            );
            const updatedRow = response.data;
            const updatedRowWithDate = lineItemStringToDate(updatedRow);
            dispatch(updateLineItem(updatedRowWithDate));
            return updatedRow;
        } catch (error) {
            console.error('Error updating line item:', error);
            throw error;
        }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    function EditToolbar() {
        const handleClick = async () => {
            const id = uuidv4();
            const newItem = {
                id: id,
                description: '',
                amount: 0,
                type: itemType,
                category: category,
                frequency: frequency,
                startDate: new Date(),
                isNew: true
            };
            
            try {
                const response = await api.post('/addIncomeStatementItem', newItem);
                const savedItem = response.data;
                const savedItemWithDate = lineItemStringToDate(savedItem);
                dispatch(addLineItem(savedItemWithDate));
                setRowModesModel((oldModel) => ({
                    ...oldModel,
                    [id]: { mode: GridRowModes.Edit, fieldToFocus: 'description' },
                }));
            } catch (error) {
                console.error('Error adding line item:', error);
            }
        };

        return (
            <GridToolbarContainer>
                <Button 
                    variant="contained"
                    startIcon={<AddIcon />} 
                    onClick={handleClick}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        borderRadius: 2,
                        px: 3,
                        bgcolor: (theme) => theme.palette.primary.main,
                        '&:hover': {
                            bgcolor: (theme) => theme.palette.primary.dark,
                        }
                    }}
                >
                    Add Line Item
                </Button>
            </GridToolbarContainer>
        );
    }

    const columns: GridColDef[] = [
        { 
            field: 'description', 
            headerName: 'Description', 
            flex: 1,
            minWidth: 150,
            editable: true
        },
        { 
            field: 'amount', 
            headerName: 'Amount', 
            type: 'number',
            preProcessEditCellProps: (params) => {
                const value = params.props.value;
                if (value < 0 ) {
                    return { ...params.props, error: 'Amount cannot be negative' };
                }
                return params.props;
            },
            width: 150,
            editable: true,
            renderCell: (params) => (
                <Box sx={{ 
                    fontWeight: 'bold',
                    color: params.row.type === 'Income' ? 'success.main' : 'error.main'
                }}>
                    {formatCurrency(params.value)}
                </Box>
            )
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 130,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['Income', 'Expense'],
            renderCell: (params) => (
                <Typography 
                    variant="body2" 
                    sx={{ 
                        fontWeight: 'medium',
                        
                    }}
                >
                    {params.value}
                </Typography>
            )
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 180,
            editable: true,
            type: 'singleSelect',
            valueOptions: ({ row }) => row.type === 'Income' 
                ? ['Salary', 'Bonus', 'Stock', 'Interest', 'Dividends', 'Other Income'] 
                : ['Housing', 'Food', 'Transportation', 'Travel', 'Healthcare', 'Entertainment', 'Shopping', 'Utilities', 'Insurance', 'Debt', 'Other Expense'],
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                    {params.value}
                </Typography>
            )
        },
        {
            field: 'frequency',
            headerName: 'Frequency',
            width: 130,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['Bi-weekly', 'Monthly', 'Annual', 'One-Time'],
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                    {params.value}
                </Typography>
            )
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            type: 'date',
            width: 130,
            editable: true,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                    {formatDate(dayjs(params.value))}
                </Typography>
            )
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                const string_id = id.toString();
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(string_id)}
                            sx={{ color: 'success.main' }}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            onClick={handleCancelClick(string_id)}
                            sx={{ color: 'error.main' }}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={handleEditClick(string_id)}
                        sx={{ color: 'primary.main' }}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(string_id)}
                        sx={{ color: 'error.main' }}
                    />,
                ];
            },
        },
    ];

    const totalIncome = displayedProjections.map((proj) => proj.totalIncome).reduce((a, b) => a + b, 0) || 0;
    const totalExpenses = displayedProjections.map((proj) => proj.totalExpenses).reduce((a, b) => a + b, 0) || 0;
    const netAmount = displayedProjections.map((proj) => proj.netAmount).reduce((a, b) => a + b, 0) || 0;

    const theme = useTheme();

    return (
        <Container maxWidth="xl">
            <Box sx={{ width: '100%', py: 4 }}>
                <StyledPaper
                    elevation={0}
                    
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    sx={{
                        borderRadius: 3,
                        overflow: 'hidden',
                        bgcolor: 'background.paper',
                        border: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    {/* Header Section */}
                    <Box 
                        sx={{ 
                            p: { xs: 3, sm: 4 },
                            background: (theme) => `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            color: 'white',
                            mb: 2,
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: { xs: 'flex-start', sm: 'center' },
                            justifyContent: 'space-between',
                            gap: 2
                        }}
                    >
                        <Box>
                            <Typography variant="h4" fontWeight="bold" gutterBottom>
                                Income Statement
                            </Typography>
                            <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
                                Track your income, expenses, and cash flow
                            </Typography>
                        </Box>
                        <Box sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                            width: { xs: '100%', sm: 'auto' }
                        }}>
                            <ManageSavingsButton />
                        </Box>
                    </Box>

                    {/* Summary Cards */}
                    <Box sx={{ 
                        px: { xs: 2, sm: 3, md: 4 }, 
                        mb: 4, 
                        display: 'grid', 
                        gap: 3, 
                        gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' } 
                    }}>
                        <SummaryCard
                            title="Total Income"
                            amount={totalIncome}
                            color="success.main"
                        />
                        <SummaryCard
                            title="Total Expenses"
                            amount={totalExpenses}
                            color="error.main"
                        />
                        <SummaryCard
                            title="Net Income"
                            amount={netAmount}
                            color={netAmount >= 0 ? 'success.main' : 'error.main'}
                        />
                    </Box>

                    {/* Tabs Section */}
                    <Box sx={{ px: { xs: 2, sm: 3, md: 4 }, pb: 4 }}>
                        <Tabs 
                            value={activeTab} 
                            onChange={(_, newValue) => setActiveTab(newValue)}
                            sx={{ 
                                mb: 3,
                                borderBottom: 1,
                                borderColor: 'divider',
                                '& .MuiTab-root': {
                                    textTransform: 'none',
                                    fontWeight: 600,
                                    fontSize: { xs: '0.875rem', sm: '1rem' },
                                    minHeight: 48,
                                },
                            }}
                        >
                            <Tab label="Line Items" />
                            <Tab label="Monthly Projections" />
                        </Tabs>

                        {activeTab === 0 && (
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                editMode="row"
                                rowModesModel={rowModesModel}
                                onRowModesModelChange={handleRowModesModelChange}
                                onRowEditStop={handleRowEditStop}
                                processRowUpdate={processRowUpdate}
                                slots={{
                                    toolbar: EditToolbar
                                }}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'description', sort: 'asc' }]
                                    },
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10
                                        }
                                    }
                                }}
                                pageSizeOptions={[5, 10, 25, 50, 100]}
                                disableRowSelectionOnClick
                                autoHeight
                                sx={{
                                    border: 'none',
                                    '& .MuiDataGrid-cell': {
                                        borderColor: 'divider',
                                    },
                                    '& .MuiDataGrid-columnHeaders': {
                                        bgcolor: 'background.paper',
                                        borderColor: 'divider',
                                    },
                                    '& .MuiDataGrid-footerContainer': {
                                        borderColor: 'divider',
                                    },
                                    '& .MuiDataGrid-row:hover': {
                                        bgcolor: alpha(theme.palette.primary.main, 0.04),
                                    },
                                }}
                            />
                        )}

                        {activeTab === 1 && displayedProjections.length > 0 && (
                            <TableContainer 
                                component={motion.div}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                sx={{ 
                                    mb: 4,
                                    overflowX: 'auto',
                                    '& .MuiTableCell-root': {
                                        borderColor: 'divider',
                                        whiteSpace: 'nowrap',
                                    },
                                }}
                            >
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox" />
                                            <TableCell sx={{ 
                                                fontWeight: 600,
                                                bgcolor: 'background.paper',
                                            }}>
                                                Item
                                            </TableCell>
                                            {displayedProjections.map((projection) => (
                                                <TableCell 
                                                    key={dayjs(projection.month).toISOString()} 
                                                    align="right"
                                                    sx={{ fontWeight: 600 }}
                                                >
                                                    {formatDate(dayjs(projection.month))}
                                                </TableCell>
                                            ))}
                                            <TableCell 
                                                align="right"
                                                sx={{ 
                                                    borderLeft: '2px solid',
                                                    borderLeftColor: 'divider',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Year Total
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Income Section */}
                                        <CollapsibleSection
                                            title="Income"
                                            isOpen={openSections.Income}
                                            onToggle={() => toggleSection('Income')}
                                            content={
                                                <Table size="small">
                                                    <TableBody>
                                                        {(['Salary', 'Bonus', 'Stock', 'Interest', 'Dividends', 'Other Income'] as IncomeCategory[])
                                                            .filter(category => 
                                                                currentStatement.lineItems.some(item => 
                                                                    item.type === 'Income' && item.category === category
                                                                )
                                                            )
                                                            .sort((a, b) => {
                                                                const totalA = calculateYearlyCategoryTotal(
                                                                    currentStatement.lineItems.filter(item => item.type === 'Income'),
                                                                    displayedProjections,
                                                                    a
                                                                );
                                                                const totalB = calculateYearlyCategoryTotal(
                                                                    currentStatement.lineItems.filter(item => item.type === 'Income'),
                                                                    displayedProjections,
                                                                    b
                                                                );
                                                                return totalB - totalA;
                                                            })
                                                            .map(category => (
                                                                <TableRow key={category}>
                                                                    <TableCell />
                                                                    <TableCell>{category}</TableCell>
                                                                    {displayedProjections.map(projection => (
                                                                        <TableCell 
                                                                            key={dayjs(projection.month).toISOString()} 
                                                                            align="right"
                                                                            sx={{ color: 'success.main' }}
                                                                        >
                                                                            {formatCurrency(
                                                                                aggregateByCategory(
                                                                                    currentStatement.lineItems.filter(item => item.type === 'Income'),
                                                                                    displayedProjections,
                                                                                    category
                                                                                )[displayedProjections.indexOf(projection)]
                                                                            )}
                                                                        </TableCell>
                                                                    ))}
                                                                    <TableCell 
                                                                        align="right"
                                                                        sx={{ 
                                                                            
                                                                            color: 'success.main'
                                                                        }}
                                                                    >
                                                                        {formatCurrency(calculateYearlyCategoryTotal(
                                                                            currentStatement.lineItems.filter(item => item.type === 'Income'),
                                                                            displayedProjections,
                                                                            category
                                                                        ))}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            }
                                            months={displayedProjections.map(p => p.month)}
                                            monthlyTotals={displayedProjections.map(p => p.totalIncome)}
                                            formatCurrency={formatCurrency}
                                            yearlyFlag={yearlyFlag}
                                        />

                                        {/* Tax Section */}
                                        <CollapsibleSection
                                            title="Taxes"
                                            isOpen={openSections.Taxes}
                                            onToggle={() => toggleSection('Taxes')}
                                            content={
                                                <Table size="small">
                                                    <TableBody>
                                                    {[
                                                        { name: 'Federal Tax', key: 'federalTax' },
                                                        { name: 'State Tax', key: 'stateTax' },
                                                        { name: 'Social Security Tax', key: 'socialSecurityTax' },
                                                        { name: 'Medicare Tax', key: 'medicareTax' },
                                                        { name: 'Property Tax', key: 'propertyTax'},
                                                        { name: 'Local Tax', key: 'localTax'}
                                                    ]
                                                        .sort((a, b) => {
                                                            const totalA = calculateYearlyTotal(displayedProjections, a.key as keyof MonthlyProjection);
                                                            const totalB = calculateYearlyTotal(displayedProjections, b.key as keyof MonthlyProjection);
                                                            return totalB - totalA;
                                                        })
                                                        .map(({ name, key }) => (
                                                            <TableRow key={key}>
                                                                <TableCell />
                                                                <TableCell>{name}</TableCell>
                                                                {displayedProjections.map((projection) => (
                                                                    <TableCell 
                                                                        key={dayjs(projection.month).toISOString()} 
                                                                        align="right"
                                                                        sx={{ color: 'error.main' }}
                                                                    >
                                                                        {formatCurrency(projection[key as keyof MonthlyProjection] as number)}
                                                                    </TableCell>
                                                                ))}
                                                                <TableCell 
                                                                    align="right"
                                                                    sx={{ 
                                                                        color: 'error.main',
                                                                    }}
                                                                >
                                                                    {formatCurrency(calculateYearlyTotal(displayedProjections, key as keyof MonthlyProjection))}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            }
                                            months={displayedProjections.map(p => p.month)}
                                            monthlyTotals={displayedProjections.map(p => p.totalTax)}
                                            formatCurrency={formatCurrency}
                                            yearlyFlag={yearlyFlag}
                                        />

                                        {/* Post Tax Income */}
                                        <TableRow sx={{ 
                                            bgcolor: alpha(theme.palette.success.main, 0.08),
                                            '&:hover': {
                                                bgcolor: alpha(theme.palette.success.main, 0.12),
                                                transition: 'background-color 0.2s ease-in-out'
                                            }
                                        }}>
                                            <TableCell />
                                            <TableCell sx={{ 
                                                bgcolor: alpha(theme.palette.success.main, 0.08),
                                                fontWeight: 'bold'
                                            }}>
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    Post Tax Income
                                                </Typography>
                                            </TableCell>
                                            {displayedProjections.map((projection) => (
                                                <TableCell
                                                    key={dayjs(projection.month).toISOString()}
                                                    align="right"
                                                    sx={{ 
                                                        fontWeight: 'bold', 
                                                        color: 'success.main'
                                                    }}
                                                >
                                                    {formatCurrency(projection.netIncome)}
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                align="right"
                                                sx={{ 
                                                    fontWeight: 'bold',
                                                    color: 'success.main',
                                                    
                                                }}
                                            >
                                                {formatCurrency(calculateYearlyTotal(displayedProjections, 'netIncome'))}
                                            </TableCell>
                                        </TableRow>

                                        {/* Expenses Section */}
                                        <CollapsibleSection
                                            title="Expenses"
                                            isOpen={openSections.Expenses}
                                            onToggle={() => toggleSection('Expenses')}
                                            content={
                                                <Table size="small">
                                                    <TableBody>
                                                        {(['Housing', 'Food', 'Transportation', 'Travel', 'Healthcare', 'Entertainment', 'Shopping', 'Utilities', 'Insurance', 'Debt', 'Other Expense'] as ExpenseCategory[])
                                                            .filter(category => 
                                                                currentStatement.lineItems.some(item => 
                                                                    item.type === 'Expense' && item.category === category
                                                                )
                                                            )
                                                            .sort((a, b) => {
                                                                const totalA = calculateYearlyCategoryTotal(
                                                                    currentStatement.lineItems.filter(item => item.type === 'Expense'),
                                                                    displayedProjections,
                                                                    a
                                                                );
                                                                const totalB = calculateYearlyCategoryTotal(
                                                                    currentStatement.lineItems.filter(item => item.type === 'Expense'),
                                                                    displayedProjections,
                                                                    b
                                                                );
                                                                return totalB - totalA;
                                                            })
                                                            .map(category => (
                                                                <TableRow key={category}>
                                                                    <TableCell />
                                                                    <TableCell>{category}</TableCell>
                                                                    {displayedProjections.map(projection => (
                                                                        <TableCell 
                                                                            key={dayjs(projection.month).toISOString()} 
                                                                            align="right"
                                                                            sx={{ color: 'error.main' }}
                                                                        >
                                                                            {formatCurrency(
                                                                                aggregateByCategory(
                                                                                    currentStatement.lineItems.filter(item => item.type === 'Expense'),
                                                                                    displayedProjections,
                                                                                    category
                                                                                )[displayedProjections.indexOf(projection)]
                                                                            )}
                                                                        </TableCell>
                                                                    ))}
                                                                    <TableCell 
                                                                        align="right"
                                                                        sx={{ 
                                                                            color: 'error.main'
                                                                        }}
                                                                    >
                                                                        {formatCurrency(calculateYearlyCategoryTotal(
                                                                            currentStatement.lineItems.filter(item => item.type === 'Expense'),
                                                                            displayedProjections,
                                                                            category
                                                                        ))}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            }
                                            months={displayedProjections.map(p => p.month)}
                                            monthlyTotals={displayedProjections.map(p => p.totalExpenses)}
                                            formatCurrency={formatCurrency}
                                            yearlyFlag={yearlyFlag}
                                        />

                                       

                                        {/* Net Income Row */}
                                        <TableRow sx={{
                                            bgcolor: alpha(theme.palette.primary.main, 0.1),
                                            '&:hover': {
                                                bgcolor: alpha(theme.palette.primary.main, 0.15),
                                            }
                                        }}>
                                            <TableCell />
                                            <TableCell sx={{ 
                                                bgcolor: alpha(theme.palette.primary.main, 0.1),
                                            }}>
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    Net Savings
                                                </Typography>
                                            </TableCell>
                                            {displayedProjections.map((projection) => (
                                                <TableCell
                                                    key={dayjs(projection.month).toISOString()}
                                                    align="right"
                                                    sx={{ 
                                                        fontWeight: 'bold',
                                                        color: projection.netAmount >= 0 ? 'success.main' : 'error.main'
                                                    }}
                                                >
                                                    {formatCurrency(projection.netAmount)}
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                align="right"
                                                sx={{ 
                                                    fontWeight: 'bold',
                                                    color: netAmount >= 0 ? 'success.main' : 'error.main'
                                                }}
                                            >
                                                {formatCurrency(netAmount)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>
                </StyledPaper>
            </Box>
        </Container>
    );
};

export default IncomeStatement;
