import axios from 'axios';

// Create a primary Axios instance for API calls
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Base URL for your API
  withCredentials: true, // Include cookies with all requests
}); 

// Create a secondary Axios instance to fetch the CSRF token
const csrfApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

// Fetch and include the CSRF token for every request
api.interceptors.request.use(
  async (config) => {
    // Fetch CSRF token if not already set in the headers
    if (!config.headers['X-CSRF-Token']) {
      try {
        const response = await csrfApi.get('/getCSRFToken'); // Use csrfApi to fetch token
        const csrfToken = response.data.csrfToken;
        console.log('Fetched CSRF token:', csrfToken);

        // Add the CSRF token to the request headers
        config.headers['X-CSRF-Token'] = csrfToken;
      } catch (error) {
        console.error('Failed to fetch CSRF token:', error);
        throw error;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
