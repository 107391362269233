import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    useTheme,
    IconButton,
    Tooltip,
    Fade,
    TablePagination,
    Chip,
    Stack,
    alpha,
} from '@mui/material';
import {
    TrendingUp as TrendingUpIcon,
    Info as InfoIcon,
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';
import { RootState } from '../store';

const ProjectionsTable: React.FC = () => {
    const theme = useTheme();
    const projections = useSelector((state: RootState) => state.userData.projections);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const formatCurrency = (value: number): string => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getGrowthIndicator = (value: number) => {
        if (value > 0) {
            return (
                <ArrowUpwardIcon 
                    fontSize="small" 
                    sx={{ 
                        color: theme.palette.success.main,
                        verticalAlign: 'middle',
                        ml: 0.5
                    }} 
                />
            );
        } else if (value < 0) {
            return (
                <ArrowDownwardIcon 
                    fontSize="small" 
                    sx={{ 
                        color: theme.palette.error.main,
                        verticalAlign: 'middle',
                        ml: 0.5
                    }} 
                />
            );
        }
        return null;
    };

    return (
        <Fade in timeout={800}>
            <Box>
                <Box 
                    sx={{ 
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 3,
                        flexWrap: 'wrap',
                        gap: 1
                    }}
                >
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            color: 'text.primary',
                            fontWeight: 700,
                            fontSize: theme => theme.breakpoints.down('sm') ? '1.1rem' : '1.25rem'
                        }}
                    >
                        <TrendingUpIcon 
                            sx={{ 
                                fontSize: theme => theme.breakpoints.down('sm') ? 24 : 28,
                                color: theme.palette.primary.main,
                                filter: `drop-shadow(0 2px 4px ${alpha(theme.palette.primary.main, 0.4)})`,
                            }}
                        />
                        Detailed Projections
                    </Typography>
                    <Tooltip 
                        title="Detailed breakdown of your financial projections year by year"
                        arrow
                        placement="left"
                    >
                        <IconButton 
                            size="small"
                            sx={{
                                '&:hover': {
                                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                }
                            }}
                        >
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Box sx={{ 
                    position: 'relative',
                    width: '100%',
                    overflow: 'hidden',
                }}>
                    <TableContainer 
                        component={Paper} 
                        elevation={0}
                        sx={{
                            border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                            borderRadius: 2,
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            backgroundColor: theme.palette.mode === 'dark'
                                ? alpha(theme.palette.background.paper, 0.6)
                                : theme.palette.background.paper,
                            backdropFilter: 'blur(10px)',
                            scrollbarWidth: 'thin',
                            scrollbarColor: `${alpha(theme.palette.primary.main, 1)} transparent`,
                            '&::-webkit-scrollbar': {
                                height: '8px',
                            },
                            '&::-webkit-scrollbar-track': {
                                background: 'transparent',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                                borderRadius: '4px',
                                '&:hover': {
                                    backgroundColor: alpha(theme.palette.primary.main, 0.3),
                                },
                            },
                        }}
                    >
                        <Table 
                            size="small" 
                            sx={{ 
                                minWidth: {
                                    xs: 1000, // Minimum width on mobile to ensure scrolling
                                    sm: 650,  // Can be smaller on desktop
                                },
                                '& .MuiTableCell-root': {
                                    borderColor: alpha(theme.palette.divider, 0.1),
                                    padding: {
                                        xs: '12px',  // Consistent padding
                                        sm: '16px',  // Regular padding on desktop
                                    },
                                    whiteSpace: 'nowrap', // Prevent text wrapping
                                },
                            }}
                        >
                            <TableHead>
                                <TableRow
                                    sx={{
                                        backgroundColor: alpha(theme.palette.primary.main, 0.02),
                                        '& th': {
                                            fontWeight: 700,
                                            color: theme.palette.text.primary,
                                            whiteSpace: 'nowrap',
                                            fontSize: '0.875rem',
                                        },
                                    }}
                                >
                                    <TableCell>Year</TableCell>
                                    <TableCell>Age</TableCell>
                                    <TableCell>Income</TableCell>
                                    <TableCell>Total Tax</TableCell>
                                    <TableCell>Net Income</TableCell>
                                    <TableCell>Expenses</TableCell>
                                    <TableCell>One-Time Expenses</TableCell>
                                    <TableCell>Savings</TableCell>
                                    <TableCell>Investment Returns</TableCell>
                                    <TableCell>Cash Balance</TableCell>
                                    <TableCell>Investment Balance</TableCell>
                                    <TableCell>Total Net Worth</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {projections
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((projection, index) => {
                                    const rowIndex = page * rowsPerPage + index;
                                    const prevProjection = rowIndex > 0 ? projections[rowIndex - 1] : null;
                                    
                                    return (
                                        <TableRow 
                                            key={index}
                                            sx={{
                                                '&:nth-of-type(odd)': {
                                                    backgroundColor: theme.palette.action.hover,
                                                },
                                                '&:hover': {
                                                    backgroundColor: alpha(theme.palette.primary.main, 0.05),
                                                },
                                                transition: 'background-color 0.2s ease-in-out',
                                            }}
                                        >
                                            <TableCell>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Typography 
                                                        variant="body2"
                                                        sx={{ fontWeight: 600 }}
                                                    >
                                                        {projection.year}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {projection.age}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" alignItems="center">
                                                    <Typography variant="body2">
                                                        {formatCurrency(projection.income)}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Typography 
                                                    variant="body2"
                                                    
                                                >
                                                    {formatCurrency(projection.totalTax)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" alignItems="center">
                                                    <Typography variant="body2">
                                                        {formatCurrency(projection.netIncome)}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {formatCurrency(projection.expenses)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {formatCurrency(projection.oneTimeExpenses)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={formatCurrency(projection.savings)}
                                                    size="small"
                                                    color={projection.savings >= 0 ? "success" : "error"}
                                                    variant="outlined"
                                                    sx={{ 
                                                        fontWeight: 600,
                                                        minWidth: 100,
                                                        borderWidth: 2,
                                                        '& .MuiChip-label': {
                                                            px: 2,
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" alignItems="center">
                                                    <Typography 
                                                        variant="body2"
                                                        sx={{ 
                                                            
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        {formatCurrency(projection.investmentReturns)}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {formatCurrency(projection.cashBalance)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {formatCurrency(projection.investmentBalance)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: theme.palette.primary.main,
                                                            letterSpacing: 0.2,
                                                        }}
                                                    >
                                                        {formatCurrency(projection.totalNetWorth)}
                                                    </Typography>
                                                    {prevProjection && getGrowthIndicator(projection.totalNetWorth - prevProjection.totalNetWorth)}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={projections.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                        '.MuiTablePagination-select': {
                            borderRadius: 1,
                        },
                        '.MuiTablePagination-selectIcon': {
                            color: theme.palette.primary.main,
                        },
                    }}
                />
            </Box>
        </Fade>
    );
};

export default ProjectionsTable;
