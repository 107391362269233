import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { logout } from '../store/authSlice';
import { resetUserData } from '../store/userDataSlice';
import api from '../services/api';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    Box,
    useTheme,
    useMediaQuery,
    ListItemButton,
    Divider,
    Avatar,
    Typography,
    Tooltip,
    Fade,
    alpha
} from '@mui/material';
import {
    Menu as MenuIcon,
    AccountBalance as AccountBalanceIcon,
    Description as DescriptionIcon,
    Timeline as TimelineIcon,
    ChevronLeft as ChevronLeftIcon,
    Person as PersonIcon,
    Logout as LogoutIcon,
    Home as HomeIcon,
    Dashboard as DashboardIcon,
} from '@mui/icons-material';

const Sidebar = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const user = useSelector((state: RootState) => state.userData.userProfile);
    const [error, setError] = useState<string | null>(null);

    const userName = user ? `${user.firstName} ${user.lastName}` : 'Loading...';

    const menuItems = [
        {
            text: 'Dashboard',
            icon: <DashboardIcon />,
            path: '/home'
        },
        {
            text: 'Personal Budget',
            icon: <DescriptionIcon />,
            path: '/income-statement'
        },
        {
            text: 'Balance Sheet',
            icon: <AccountBalanceIcon />,
            path: '/balance-sheet'
        },
        {
            text: 'Financial Planning',
            icon: <TimelineIcon />,
            path: '/financial-planning'
        }
    ];

    useEffect(() => {
        if (isMobile && open) {
            setOpen(false);
        }
    }, [isMobile]);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleLogout = async () => {
        try {
            await api.post('/logout');
            dispatch(logout());
            dispatch(resetUserData());
            navigate('/');
        } catch (error) {
            console.error('Logout failed:', error);
            // Optionally show error message to user
        }
    };

    return (
        <>
            <Tooltip 
                title={open ? "Close menu" : "Open menu"} 
                placement={isMobile ? "left" : "right"}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
            >
                <IconButton
                    color="inherit"
                    aria-label={open ? "close drawer" : "open drawer"}
                    onClick={handleDrawerToggle}
                    edge="start"
                    sx={{
                        position: 'fixed',
                        ...(isMobile ? {
                            right: 16,
                            top: 16,
                        } : {
                            left: open ? 240 : 20,
                            top: 20,
                        }),
                        zIndex: 1300,
                        bgcolor: alpha(theme.palette.background.paper, 0.8),
                        backdropFilter: 'blur(8px)',
                        boxShadow: `0 4px 20px 0 ${alpha(theme.palette.primary.main, 0.2)}`,
                        transition: theme.transitions.create(
                            ['left', 'right', 'transform', 'background-color'], 
                            {
                                duration: theme.transitions.duration.standard,
                            }
                        ),
                        '&:hover': {
                            bgcolor: alpha(theme.palette.primary.main, 0.1),
                            transform: 'scale(1.1)',
                        }
                    }}
                >
                    {open ? <ChevronLeftIcon /> : <MenuIcon />}
                </IconButton>
            </Tooltip>
            <Drawer
                variant={isMobile ? "temporary" : "persistent"}
                anchor={isMobile ? "top" : "left"}
                open={open}
                onClose={isMobile ? handleDrawerToggle : undefined}
                sx={{
                    width: isMobile ? '100%' : 240,
                    height: isMobile ? '100%' : 'auto',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: isMobile ? '100%' : 240,
                        height: isMobile ? '100%' : '100vh',
                        boxSizing: 'border-box',
                        bgcolor: 'background.default',
                        borderRight: isMobile ? 'none' : '1px solid',
                        borderBottom: isMobile ? '1px solid' : 'none',
                        borderColor: 'divider',
                        boxShadow: theme.shadows[1],
                        transition: theme.transitions.create(['width', 'height', 'transform'], {
                            duration: theme.transitions.duration.standard,
                        }),
                    },
                }}
                PaperProps={{
                    sx: {
                        ...(isMobile && {
                            background: alpha(theme.palette.background.paper, 0.95),
                            backdropFilter: 'blur(10px)',
                        })
                    }
                }}
                SlideProps={{
                    timeout: {
                        enter: 250,
                        exit: 250
                    }
                }}
            >
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    height: '100%',
                    ...(isMobile && {
                        pt: 7, // Add padding top to account for the menu button
                        bgcolor: 'transparent',
                    }),
                    ...(!isMobile && {
                        bgcolor: 'background.paper',
                    })
                }}>
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            py: isMobile ? 2 : 4,
                            flexDirection: 'column',
                            alignItems: 'center',
                            ...(isMobile ? {
                                bgcolor: 'transparent',
                            } : {
                                bgcolor: theme => alpha(theme.palette.background.paper, 0.6),
                                borderBottom: '1px solid',
                                borderColor: 'divider',
                                backdropFilter: 'blur(8px)',
                            })
                        }}
                    >
                        <Tooltip 
                            title="View Profile" 
                            placement={isMobile ? "bottom" : "right"}
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                        >
                            <IconButton 
                                onClick={() => {navigate('/profile');
                                    if (isMobile) setOpen(false);
                                }}
                                sx={{ 
                                    p: 0.5,
                                    mb: 2,
                                    transition: theme => theme.transitions.create(['transform', 'box-shadow'], {
                                        duration: theme.transitions.duration.shorter,
                                    }),
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                    }
                                }}
                            >
                                <Avatar 
                                    sx={{ 
                                        width: isMobile ? 60 : 80,
                                        height: isMobile ? 60 : 80,
                                        bgcolor: theme => location.pathname === '/profile' 
                                            ? theme.palette.primary.main 
                                            : alpha(theme.palette.primary.main, 0.2),
                                        transition: theme => theme.transitions.create(['background-color', 'box-shadow'], {
                                            duration: theme.transitions.duration.standard,
                                        }),
                                        boxShadow: theme => `0 4px 20px 0 ${alpha(theme.palette.primary.main, 0.3)}`,
                                        border: theme => `2px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                                    }}
                                >
                                    <PersonIcon sx={{ 
                                        fontSize: isMobile ? 30 : 40, 
                                        color: theme => location.pathname === '/profile' ? 'black' : 'primary.main' 
                                    }} />
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Typography 
                            variant={isMobile ? "body1" : "h6"}
                            component="div"
                            sx={{
                                fontWeight: 600,
                                color: 'text.primary',
                                textAlign: 'center',
                                mb: 0.5,
                            }}
                        >
                            {error ? 'Error loading profile' : userName}
                        </Typography>
                    </Box>

                    <List sx={{ 
                        flexGrow: 1, 
                        px: 2, 
                        py: 2,
                        ...(isMobile && {
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            gap: 1,
                        })
                    }}>
                        {menuItems.map((item) => (
                            <ListItemButton
                                key={item.text}
                                selected={location.pathname === item.path}
                                onClick={() => {
                                    navigate(item.path);
                                    if (isMobile) setOpen(false);
                                }}
                                sx={{
                                    mb: 1,
                                    borderRadius: 2,
                                    ...(isMobile && {
                                        width: 'auto',
                                        minWidth: 150,
                                        flex: '0 1 calc(50% - 8px)',
                                    }),
                                    '&.Mui-selected': {
                                        bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                                        '&:hover': {
                                            bgcolor: theme => alpha(theme.palette.primary.main, 0.25),
                                        },
                                    },
                                    '&:hover': {
                                        bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                                    },
                                }}
                            >
                                <ListItemIcon sx={{ 
                                    color: location.pathname === item.path ? 'primary.main' : 'text.secondary',
                                    minWidth: isMobile ? 32 : 40,
                                }}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText 
                                    primary={item.text} 
                                    primaryTypographyProps={{
                                        sx: {
                                            fontWeight: location.pathname === item.path ? 600 : 400,
                                            color: location.pathname === item.path ? 'primary.main' : 'text.primary',
                                            ...(isMobile && {
                                                fontSize: '0.9rem',
                                            }),
                                        }
                                    }}
                                />
                            </ListItemButton>
                        ))}
                    </List>

                    <Divider sx={{ borderColor: 'divider' }} />

                    <List sx={{ 
                        p: 2,
                        ...(isMobile && {
                            display: 'flex',
                            justifyContent: 'center',
                        })
                    }}>
                        <ListItemButton
                            onClick={handleLogout}
                            sx={{
                                borderRadius: 2,
                                ...(isMobile && {
                                    width: 'auto',
                                    minWidth: 120,
                                }),
                                '&:hover': {
                                    bgcolor: theme => alpha(theme.palette.error.main, 0.08),
                                },
                            }}
                        >
                            <ListItemIcon sx={{ 
                                color: 'error.main',
                                minWidth: isMobile ? 32 : 40,
                            }}>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Logout"
                                primaryTypographyProps={{
                                    sx: {
                                        color: 'error.main',
                                        fontWeight: 500,
                                        ...(isMobile && {
                                            fontSize: '0.9rem',
                                        }),
                                    }
                                }}
                            />
                        </ListItemButton>
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

export default Sidebar;
