import { LineItem, LineItemCategory, MonthlyProjection } from '../types';

// Income Statement Aggregation Functions
export const calculateYearlyTotal = (projections: MonthlyProjection[], propertyName: keyof MonthlyProjection): number => {
    return projections.reduce((total, projection) => {
        const value = projection[propertyName];
        if (typeof value === 'number') {
            return total + value;
        }
        return total;
    }, 0);
};

export const calculateYearlyLineItemTotal = (projections: MonthlyProjection[], itemId: string): number => {
    return projections.reduce((total, projection) => {
        const value = projection.lineItemProjections[itemId];
        return total + (value || 0);
    }, 0);
};

export const aggregateByCategory = (items: LineItem[], projections: MonthlyProjection[], category: LineItemCategory) => {
    return projections.map(projection => {
        return items
            .filter(item => item.category === category)
            .reduce((total, item) => total + (projection.lineItemProjections[item.id] || 0), 0);
    });
};

export const calculateYearlyCategoryTotal = (items: LineItem[], projections: MonthlyProjection[], category: LineItemCategory): number => {
    return items
        .filter(item => item.category === category)
        .reduce((total, item) => total + calculateYearlyLineItemTotal(projections, item.id), 0);
};

// Balance Sheet Aggregation Functions

export const addListOfLists = (lists: number[][]) => {
    return lists.reduce((acc, list) => {
        list.forEach((value, index) => {
            acc[index] = (acc[index] || 0) + value;
        });
        return acc;
    }, [] as number[]);
};
