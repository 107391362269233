import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { updateUserInputs } from '../store/userDataSlice';
import {
    TextField,
    Grid,
    Box,
    ToggleButton,
    ToggleButtonGroup,
    Button,
    Stack,
    Typography,
    useTheme,
    Tooltip,
    IconButton,
    Fade,
    Zoom,
    Alert,
    Divider,
} from '@mui/material';
import {
    Sync as SyncIcon,
    Save as SaveIcon,
    TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import { ReturnProfile, UserInputs } from '../types';
import api from '../services/api';
import { v4 as uuidv4 } from 'uuid';

const RETURN_PROFILES: ReturnProfile[] = ['Very Bad', 'Bad', 'Normal', 'Good', 'Great'];

const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value);
};

const InputForm = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { userInputs, userProfile } = useSelector((state: RootState) => state.userData);
    const balancelineItems = useSelector((state: RootState) => state.userData.currentSheet.lineItems);
    const incomelineItems = useSelector((state: RootState) => state.userData.currentStatement.lineItems);
    const [formValues, setFormValues] = useState<UserInputs>(userInputs);
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // Local state for formatted display values
    const [displayValues, setDisplayValues] = useState({
        startingCashBalance: formatCurrency(userInputs.startingCashBalance),
        startingInvestmentBalance: formatCurrency(userInputs.startingInvestmentBalance),
        currentPreTaxIncome: formatCurrency(userInputs.currentPreTaxIncome),
        currentExpenses: formatCurrency(userInputs.currentExpenses)
    });

    const editUserInputs = async () => {
        try {
            setIsSaving(true);
            setError(null);
            const id = formValues.id;
            const response = await api.put(`/editUserInputs/${id}`, formValues);
            setFormValues(response.data);
            setDisplayValues({
                startingCashBalance: formatCurrency(response.data.startingCashBalance),
                startingInvestmentBalance: formatCurrency(response.data.startingInvestmentBalance),
                currentPreTaxIncome: formatCurrency(response.data.currentPreTaxIncome),
                currentExpenses: formatCurrency(response.data.currentExpenses)
            });
            dispatch(updateUserInputs(response.data));
            setSaveSuccess(true);
            setTimeout(() => setSaveSuccess(false), 3000);
        } catch (error) {
            console.error('Error saving user inputs:', error);
            setError('Failed to save changes. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };

    const createNewUserInputs = async () => { 
        try {
            // Create new user inputs if none exist
            setIsSaving(true);
            setError(null);
            const newUserInputs = {
                ...formValues,
                id: uuidv4()
            };
            const createResponse = await api.post('/createUserInputs', newUserInputs);
            setFormValues(createResponse.data);
            setDisplayValues({
                startingCashBalance: formatCurrency(createResponse.data.startingCashBalance),
                startingInvestmentBalance: formatCurrency(createResponse.data.startingInvestmentBalance),
                currentPreTaxIncome: formatCurrency(createResponse.data.currentPreTaxIncome),
                currentExpenses: formatCurrency(createResponse.data.currentExpenses)
            });
            dispatch(updateUserInputs(createResponse.data));
            setSaveSuccess(true);
            setTimeout(() => setSaveSuccess(false), 3000);
        } catch (error) {
            console.error('Error creating user inputs:', error);
            setError('Failed to create new inputs. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };

    const handleCurrencyInputChange = (field: keyof UserInputs) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        // Remove all non-numeric characters from input
        const numericValue = event.target.value.replace(/[^0-9]/g, '');
        const numberValue = parseInt(numericValue) || 0;

        setFormValues({
            ...formValues,
            [field]: numberValue
        });

        setDisplayValues({
            ...displayValues,
            [field]: formatCurrency(numberValue)
        });
    };

    const handleInputChange = (field: keyof UserInputs) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFormValues({
            ...formValues,
            [field]: Number(event.target.value)
        });
    };

    // Check if form values are different from state
    const hasChanges = (): boolean => {
        return Object.keys(userInputs).some(key => {
            const k = key as keyof UserInputs;
            return formValues[k] !== userInputs[k];
        });
    };

    const handleReturnProfileChange = (
        _event: React.MouseEvent<HTMLElement>,
        newProfile: ReturnProfile | null
    ) => {
        if (newProfile !== null) {
            setFormValues({
                ...formValues,
                returnProfile: newProfile
            });
        }
    };

    const handleSave = async () => {
        if (formValues.id === '') {
            await createNewUserInputs();
        } else {
            await editUserInputs();
        }
    };

    const handleStatementSync = () => {
        // Calculate total cash balance from balance sheet
        const cashBalance = balancelineItems
            .filter(item => item.type === 'Liquid Assets')
            .reduce((total, item) => total + item.amount, 0);

        // Calculate total investments from balance sheet
        const investmentBalance = balancelineItems
            .filter(item => item.type === 'Investments')
            .reduce((total, item) => total + item.amount, 0);

        // Calculate total pre-tax income from income statement
        const income = incomelineItems
            .filter((item: any) => item.type === 'Income' && (new Date(item.startDate) < new Date(new Date().setFullYear(new Date().getFullYear() + 1))))
            .reduce((total, item) => {
                switch (item.frequency) {
                    case 'Bi-weekly':
                        return total + (item.amount * 26);
                    case 'Monthly':
                        return total + (item.amount * 12);
                    case 'Annual':
                        return total + item.amount;
                    case 'One-Time':
                        return total + item.amount;
                    default:
                        return total;
                }
            }, 0);

        // Calculate total expenses from income statement
        const expenses = incomelineItems
            .filter((item: any) => item.type === 'Expense' && (new Date(item.startDate) < new Date(new Date().setFullYear(new Date().getFullYear() + 1))))
            .reduce((total, item) => {
                switch (item.frequency) {
                    case 'Bi-weekly':
                        return total + (item.amount * 26);
                    case 'Monthly':
                        return total + (item.amount * 12);
                    case 'Annual':
                        return total + item.amount;
                    case 'One-Time':
                        return total + item.amount;
                    default:
                        return total;
                }
            }, 0);

        setFormValues({
            ...formValues,
            startingCashBalance: cashBalance,
            startingInvestmentBalance: investmentBalance,
            currentPreTaxIncome: income,
            currentExpenses: expenses
        });

        setDisplayValues({
            startingCashBalance: formatCurrency(cashBalance),
            startingInvestmentBalance: formatCurrency(investmentBalance),
            currentPreTaxIncome: formatCurrency(income),
            currentExpenses: formatCurrency(expenses)
        });
    };

    return (
        <Stack spacing={1}>
            {(error || saveSuccess) && (
                <Fade in timeout={500}>
                    <Alert 
                        severity={error ? "error" : "success"}
                        sx={{ 
                            borderRadius: 2,
                            boxShadow: theme => `0 2px 8px ${theme.palette[error ? "error" : "success"].main}`,
                            '& .MuiAlert-icon': {
                                fontSize: '1.5rem'
                            }
                        }}
                    >
                        {error || 'Changes saved successfully!'}
                    </Alert>
                </Fade>
            )}

            <Box sx={{ py: 2 }}>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3
                }}>
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            fontWeight: 700,
                            color: theme.palette.text.primary,
                        }}
                    >
                        Current Balances
                    </Typography>
                    <Tooltip title="Sync with statements" placement="left">
                        <IconButton 
                            onClick={handleStatementSync}
                            sx={{
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'rotate(180deg)',
                                    backgroundColor: theme.palette.primary.main,
                                }
                            }}
                        >
                            <SyncIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Cash Balance"
                            value={displayValues.startingCashBalance}
                            onChange={handleCurrencyInputChange('startingCashBalance')}
                            fullWidth
                            InputProps={{
                                sx: {
                                    borderRadius: 2,
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.divider,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: 2,
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Investment Balance"
                            value={displayValues.startingInvestmentBalance}
                            onChange={handleCurrencyInputChange('startingInvestmentBalance')}
                            fullWidth
                            InputProps={{
                                sx: {
                                    borderRadius: 2,
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.divider,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: 2,
                                    },
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{ 
                borderColor: theme.palette.divider,
                my: 2 
            }} />

            <Box sx={{ py: 2 }}>
                <Typography 
                    variant="h6" 
                    sx={{ 
                        mb: 3,
                        fontWeight: 700,
                        color: theme.palette.text.primary,
                    }}
                >
                    Annual Income & Expenses
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Pre-tax Income"
                            value={displayValues.currentPreTaxIncome}
                            onChange={handleCurrencyInputChange('currentPreTaxIncome')}
                            fullWidth
                            InputProps={{
                                sx: {
                                    borderRadius: 2,
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.divider,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: 2,
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Expenses"
                            value={displayValues.currentExpenses}
                            onChange={handleCurrencyInputChange('currentExpenses')}
                            fullWidth
                            InputProps={{
                                sx: {
                                    borderRadius: 2,
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.divider,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: 2,
                                    },
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{ 
                borderColor: theme.palette.divider,
                my: 2 
            }} />

            <Box sx={{ py: 2 }}>
                <Typography 
                    variant="h6" 
                    sx={{ 
                        mb: 3,
                        fontWeight: 700,
                        color: theme.palette.text.primary,
                    }}
                >
                    Annual Income & Expense Changes
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Real Income Change (%)"
                            value={formValues.realWageGrowth}
                            onChange={handleInputChange('realWageGrowth')}
                            fullWidth
                            InputProps={{
                                sx: {
                                    borderRadius: 2,
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.divider,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: 2,
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Real Expense Change (%)"
                            value={formValues.realSpendingGrowth}
                            onChange={handleInputChange('realSpendingGrowth')}
                            fullWidth
                            InputProps={{
                                sx: {
                                    borderRadius: 2,
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.divider,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: 2,
                                    },
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{ 
                borderColor: theme.palette.divider,
                my: 2 
            }} />

            <Box sx={{ py: 2 }}>
                <Typography 
                    variant="h6" 
                    sx={{ 
                        mb: 3,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontWeight: 700,
                        color: theme.palette.text.primary,
                    }}
                >
                    <TrendingUpIcon 
                        sx={{ 
                            color: theme.palette.primary.main,
                            filter: `drop-shadow(0 2px 4px ${theme.palette.primary.main})`,
                        }} 
                    />
                    Return Profile
                </Typography>

                <ToggleButtonGroup
                    value={formValues.returnProfile}
                    exclusive
                    onChange={handleReturnProfileChange}
                    aria-label="return profile"
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        '& .MuiToggleButton-root': {
                            flex: '1 1 auto',
                            borderRadius: 2,
                            border: `1px solid ${theme.palette.divider}`,
                            py: 1,
                            fontWeight: 600,
                            transition: 'all 0.2s ease-in-out',
                            '&.Mui-selected': {
                                background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                                color: theme.palette.primary.contrastText,
                                boxShadow: `0 4px 12px ${theme.palette.primary.main}`,
                                '&:hover': {
                                    background: `linear-gradient(135deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                                },
                            },
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                borderColor: theme.palette.primary.main,
                                transform: 'translateY(-2px)',
                            },
                        },
                    }}
                >
                    {RETURN_PROFILES.map((profile) => (
                        <ToggleButton 
                            key={profile} 
                            value={profile}
                        >
                            {profile}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Zoom in={hasChanges()} unmountOnExit>
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        disabled={isSaving}
                        startIcon={<SaveIcon />}
                        sx={{
                            px: 4,
                            py: 1.5,
                            borderRadius: 2,
                            fontWeight: 600,
                            background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                            boxShadow: `0 4px 12px ${theme.palette.primary.main}`,
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: `0 6px 16px ${theme.palette.primary.main}`,
                            },
                            '&:active': {
                                transform: 'translateY(1px)',
                            }
                        }}
                    >
                        {isSaving ? 'Saving...' : 'Save Changes'}
                    </Button>
                </Zoom>
            </Box>
        </Stack>
    );
};

export default InputForm;
