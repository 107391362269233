import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import {
    Box,
    TextField,
    MenuItem,
    Grid,
    Typography,
    Paper,
    Button,
    Stack,
    CircularProgress,
    Alert,
    Avatar,
    Divider,
    useTheme,
    Card,
    IconButton,
    Tooltip,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { updateProfile } from '../../store/userDataSlice';
import { UserProfile } from '../../types/index';
import api from '../../services/api';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CakeIcon from '@mui/icons-material/Cake';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import FavoriteIcon from '@mui/icons-material/Favorite';

const US_STATES = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

const UserProfileComponent: React.FC = () => {
    const theme = useTheme();
    const [formValues, setFormValues] = useState<UserProfile | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [originalValues, setOriginalValues] = useState<UserProfile | null>(null);

    const userProfile = useSelector((state: RootState) => state.userData.userProfile);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (userProfile) {
            setFormValues(userProfile);
            setOriginalValues(userProfile);
            setLoading(false);
        }
    }, [userProfile]);

    const handleChange = (field: keyof UserProfile) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!formValues) return;

        const value = field === 'age' || field === 'retirementAge'
            ? Number(event.target.value)
            : event.target.value;
        setFormValues({
            ...formValues,
            [field]: value
        });
    };

    const handleSave = async () => {
        if (!formValues) return;

        try {
            setLoading(true);
            await api.put('/updateUserProfile', formValues);
            setSuccessMessage('Profile updated successfully');
            setError(null);
            dispatch(updateProfile(formValues));
            setOriginalValues(formValues);
            setIsEditing(false);
        } catch (err: any) {
            setError(err?.response?.data?.error || 'Failed to update profile');
            setSuccessMessage(null);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setFormValues(originalValues);
        setIsEditing(false);
        setError(null);
        setSuccessMessage(null);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (!formValues) {
        return (
            <Box p={3}>
                <Alert severity="error">
                    {error || 'No user profile data available'}
                </Alert>
            </Box>
        );
    }

    const isRetirementAgeValid = formValues.retirementAge > formValues.age;

    return (
        <Box sx={{ 
            width: '100%',
            height: '100%',
            overflow: 'auto',
            p: { xs: 2, sm: 3 },
            backgroundColor: theme.palette.mode === 'dark' ? alpha(theme.palette.background.paper, 0.2) : alpha(theme.palette.background.paper, 0.5),
        }}>
            <Card 
                elevation={0}
                sx={{
                    borderRadius: 3,
                    overflow: 'visible',
                    border: `1px solid ${theme.palette.divider}`,
                    bgcolor: 'background.paper',
                }}
            >
                {/* Header Section */}
                <Box
                    sx={{
                        p: { xs: 2, sm: 3 },
                        background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        color: 'white',
                        borderRadius: '12px 12px 0 0',
                        position: 'relative',
                    }}
                >
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'flex-start', 
                        gap: 3, 
                        mb: 2,
                        flexDirection: { xs: 'column', sm: 'row' },
                        width: '100%'
                    }}>
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: { xs: 'center', sm: 'flex-start' }, 
                            gap: 3,
                            flexDirection: { xs: 'column', sm: 'row' },
                            width: { xs: '100%', sm: 'auto' }
                        }}>
                            <Avatar
                                sx={{
                                    width: { xs: 80, sm: 100 },
                                    height: { xs: 80, sm: 100 },
                                    bgcolor: alpha(theme.palette.common.white, 0.2),
                                    border: `4px solid ${alpha(theme.palette.common.white, 0.3)}`,
                                    boxShadow: `0 8px 16px ${alpha(theme.palette.common.black, 0.2)}`,
                                }}
                            >
                                <PersonIcon sx={{ fontSize: { xs: 40, sm: 50 } }} />
                            </Avatar>
                            <Box sx={{ 
                                textAlign: { xs: 'center', sm: 'left' },
                                width: { xs: '100%', sm: 'auto' }
                            }}>
                                <Typography 
                                    variant="h4" 
                                    fontWeight="bold"
                                    sx={{ 
                                        mb: 0.5,
                                        textShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.2)}`
                                    }}
                                >
                                    {formValues.firstName} {formValues.lastName}
                                </Typography>
                                <Typography 
                                    variant="subtitle1" 
                                    sx={{ 
                                        opacity: 0.9,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        justifyContent: { xs: 'center', sm: 'flex-start' }
                                    }}
                                >
                                    <EmailIcon sx={{ fontSize: 20 }} />
                                    {formValues.email}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ 
                            ml: { xs: 0, sm: 'auto' },
                            width: { xs: '100%', sm: 'auto' },
                            display: 'flex',
                            justifyContent: { xs: 'center', sm: 'flex-end' }
                        }}>
                            {!isEditing ? (
                                <Button
                                    variant="contained"
                                    startIcon={<EditIcon />}
                                    onClick={() => setIsEditing(true)}
                                    sx={{ 
                                        bgcolor: alpha(theme.palette.common.white, 0.1),
                                        color: theme.palette.common.white,
                                        borderRadius: 2,
                                        px: { xs: 2, sm: 3 },
                                        py: 1,
                                        textTransform: 'none',
                                        fontWeight: 600,
                                        backdropFilter: 'blur(8px)',
                                        border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
                                        boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.2)}`,
                                        '&:hover': { 
                                            bgcolor: alpha(theme.palette.common.white, 0.2),
                                            boxShadow: `0 6px 16px ${alpha(theme.palette.common.black, 0.3)}`,
                                        },
                                        width: { xs: '100%', sm: 'auto' }
                                    }}
                                >
                                    Edit Profile
                                </Button>
                            ) : (
                                <Stack 
                                    direction={{ xs: 'column', sm: 'row' }} 
                                    spacing={1.5}
                                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                                >
                                    <Button
                                        variant="contained"
                                        startIcon={<SaveIcon />}
                                        onClick={handleSave}
                                        disabled={!isRetirementAgeValid}
                                        sx={{ 
                                            bgcolor: alpha(theme.palette.success.main, 0.1),
                                            color: theme.palette.common.white,
                                            borderRadius: 2,
                                            px: { xs: 2, sm: 3 },
                                            py: 1,
                                            textTransform: 'none',
                                            fontWeight: 600,
                                            backdropFilter: 'blur(8px)',
                                            border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
                                            boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.2)}`,
                                            '&:hover': { 
                                                bgcolor: alpha(theme.palette.success.main, 0.2),
                                                boxShadow: `0 6px 16px ${alpha(theme.palette.common.black, 0.3)}`,
                                            },
                                            '&:disabled': {
                                                bgcolor: alpha(theme.palette.common.white, 0.05),
                                                color: alpha(theme.palette.common.white, 0.5),
                                            },
                                            width: { xs: '100%', sm: 'auto' }
                                        }}
                                    >
                                        Save Changes
                                    </Button>
                                    <Button
                                        variant="contained"
                                        startIcon={<CancelIcon />}
                                        onClick={handleCancel}
                                        sx={{ 
                                            bgcolor: alpha(theme.palette.error.main, 0.1),
                                            color: theme.palette.common.white,
                                            borderRadius: 2,
                                            px: { xs: 2, sm: 3 },
                                            py: 1,
                                            textTransform: 'none',
                                            fontWeight: 600,
                                            backdropFilter: 'blur(8px)',
                                            border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
                                            boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.2)}`,
                                            '&:hover': { 
                                                bgcolor: alpha(theme.palette.error.main, 0.2),
                                                boxShadow: `0 6px 16px ${alpha(theme.palette.common.black, 0.3)}`,
                                            },
                                            width: { xs: '100%', sm: 'auto' }
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Stack>
                            )}
                        </Box>
                    </Box>
                </Box>

                {/* Alerts */}
                {(successMessage || error) && (
                    <Box sx={{ p: { xs: 2, sm: 3 } }}>
                        {successMessage && (
                            <Alert 
                                severity="success" 
                                sx={{ 
                                    mb: error ? 2 : 0,
                                    borderRadius: 2,
                                    boxShadow: `0 2px 8px ${alpha(theme.palette.success.main, 0.15)}`,
                                }}
                            >
                                {successMessage}
                            </Alert>
                        )}
                        {error && (
                            <Alert 
                                severity="error"
                                sx={{ 
                                    borderRadius: 2,
                                    boxShadow: `0 2px 8px ${alpha(theme.palette.error.main, 0.15)}`,
                                }}
                            >
                                {error}
                            </Alert>
                        )}
                    </Box>
                )}

                {/* Form Section */}
                <Box sx={{ p: { xs: 2, sm: 3 } }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="First Name"
                                value={formValues.firstName}
                                onChange={handleChange('firstName')}
                                disabled={!isEditing}
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2,
                                        bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.white, 0.8),
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                value={formValues.lastName}
                                onChange={handleChange('lastName')}
                                disabled={!isEditing}
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2,
                                        bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.white, 0.8),
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                type="email"
                                value={formValues.email}
                                onChange={handleChange('email')}
                                disabled={!isEditing}
                                InputProps={{
                                    startAdornment: <EmailIcon sx={{ mr: 1, color: theme.palette.text.secondary }} />,
                                }}
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2,
                                        bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.white, 0.8),
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Age"
                                type="number"
                                value={formValues.age}
                                onChange={handleChange('age')}
                                disabled={!isEditing}
                                InputProps={{ 
                                    inputProps: { min: 0, max: 120 },
                                    startAdornment: <CakeIcon sx={{ mr: 1, color: theme.palette.text.secondary }} />,
                                }}
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2,
                                        bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.white, 0.8),
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Retirement Age"
                                type="number"
                                value={formValues.retirementAge}
                                onChange={handleChange('retirementAge')}
                                disabled={!isEditing}
                                InputProps={{ 
                                    inputProps: { 
                                        min: formValues.age,
                                        max: 120 
                                    },
                                    startAdornment: <WatchLaterIcon sx={{ mr: 1, color: theme.palette.text.secondary }} />,
                                }}
                                error={!isRetirementAgeValid}
                                helperText={!isRetirementAgeValid ? 
                                    "Retirement age must be greater than current age" : ""}
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2,
                                        bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.white, 0.8),
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                select
                                label="State of Residence"
                                value={formValues.stateOfResidence}
                                onChange={handleChange('stateOfResidence')}
                                disabled={!isEditing}
                                InputProps={{
                                    startAdornment: <LocationOnIcon sx={{ mr: 1, color: theme.palette.text.secondary }} />,
                                }}
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2,
                                        bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.white, 0.8),
                                    }
                                }}
                            >
                                {US_STATES.map((state) => (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                       
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>Tax Details</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Tax Filing Status"
                                        value={formValues.taxFilingStatus}
                                        onChange={handleChange('taxFilingStatus')}
                                        disabled={!isEditing}
                                        sx={{ 
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.white, 0.8),
                                            }
                                        }}
                                    >
                                        <MenuItem value="Single">Single</MenuItem>
                                        <MenuItem value="Married">Married</MenuItem>
                                        <MenuItem value="Head of Household">Head of Household</MenuItem>
                                        <MenuItem value="Married filing separately">Married Filing Separately</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Deduction Type"
                                        value={formValues.deductionType}
                                        onChange={handleChange('deductionType')}
                                        disabled={!isEditing}
                                        sx={{ 
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.white, 0.8),
                                            }
                                        }}
                                    >
                                        <MenuItem value="Standard">Standard</MenuItem>
                                        <MenuItem value="Itemized">Itemized</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Number of Dependents"
                                        value={formValues.numberOfDependents}
                                        onChange={handleChange('numberOfDependents')}
                                        disabled={!isEditing}
                                        InputProps={{ 
                                            inputProps: { min: 0 }
                                        }}
                                        sx={{ 
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.white, 0.8),
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Itemized Deductions"
                                        value={formValues.itemizedDeductions}
                                        onChange={handleChange('itemizedDeductions')}
                                        disabled={!isEditing || formValues.deductionType === 'Standard'}
                                        InputProps={{ 
                                            inputProps: { min: 0 },
                                            startAdornment: <span style={{ marginRight: '8px' }}>$</span>
                                        }}
                                        sx={{ 
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.white, 0.8),
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Property Taxes"
                                        value={formValues.propertyTaxs}
                                        onChange={handleChange('propertyTaxs')}
                                        disabled={!isEditing}
                                        InputProps={{ 
                                            inputProps: { min: 0 },
                                            startAdornment: <span style={{ marginRight: '8px' }}>$</span>
                                        }}
                                        sx={{ 
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.white, 0.8),
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Local Taxes"
                                        value={formValues.localTaxs}
                                        onChange={handleChange('localTaxs')}
                                        disabled={!isEditing}
                                        InputProps={{ 
                                            inputProps: { min: 0 },
                                            startAdornment: <span style={{ marginRight: '8px' }}>$</span>
                                        }}
                                        sx={{ 
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.white, 0.8),
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Box>
    );
};

export default UserProfileComponent;
