import { UserInputs, OneTimeExpense, FinancialProjection, TaxBracket, ReturnProfile, TaxBreak } from '../types/index';

const SOCIAL_SECURITY_RATE = 0.062; // 6.2% social security tax
const MEDICARE_RATE = 0.0145; // 1.45% medicare tax
const maxTaxableIncome = 176100; // 2025 limit
const INFLATION_RATE = 0.02; // 2% annual inflation
const RISK_FREE_RETURN_RATE = 0.045; // 4.5% risk-free return
const REAL_RISK_FREE_RETURN_RATE = RISK_FREE_RETURN_RATE - INFLATION_RATE;

// Return profile parameters
const RETURN_PROFILES = {
    'Very Bad': { beta: 1, equityRiskPremium: 0.0086 },
    'Bad': { beta: 1, equityRiskPremium: 0.0293 },
    'Normal': { beta: 1, equityRiskPremium: 0.05 },
    'Good': { beta: 1, equityRiskPremium: 0.0707 },
    'Great': { beta: 1, equityRiskPremium: 0.0914 }
};

const calculateInvestmentReturn = (returnProfile: ReturnProfile): number => {
    const { beta, equityRiskPremium } = RETURN_PROFILES[returnProfile];
    return RISK_FREE_RETURN_RATE + (beta * equityRiskPremium) - INFLATION_RATE;
};

// 2025 Federal Standard Deductions
export const FEDERAL_STANDARD_DEDUCTIONS: TaxBreak[] = [
    { "year": 2025, "status": "Single", "type": 'Deduction', "amount": 15000},
    { "year": 2025, "status": "Married", "type": 'Deduction', "amount": 30000},
    { "year": 2025, "status": "Married filing separately", "type": 'Deduction', "amount": 15000},
    { "year": 2025, "status": "Head of Household", "type": 'Deduction', "amount": 22500},
];

// 2024 State Standard Deductions
export const STATE_STANDARD_DEDUCTIONS: { [key: string]: TaxBreak[] } = {
    'AL': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 3000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 8500}
    ],
    'AZ': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 14600},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 29200}
    ],
    'AR': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 2340},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 4680}
    ],
    'CA': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 5363},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 10726}
    ],
    'CO': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 14600},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 29200}
    ],
    'DE': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 3250},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 6500}
    ],
    'GA': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 12000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 24000}
    ],
    'HI': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 2200},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 4400}
    ],
    'ID': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 14600},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 29200}
    ],
    'KS': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 3500},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 8000}
    ],
    'KY': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 3160},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 6320}
    ],
    'ME': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 14600},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 29200}
    ],
    'MD': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 2550},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 5150}
    ],
    'MN': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 14575},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 29150}
    ],
    'MS': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 2300},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 4600}
    ],
    'MO': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 14600},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 29200}
    ],
    'MT': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 14600},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 29200}
    ],
    'NE': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 7900},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 15800}
    ],
    'NM': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 14600},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 29200}
    ],
    'NY': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 8000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 16050}
    ],
    'NC': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 12750},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 25500}
    ],
    'ND': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 14600},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 29200}
    ],
    'OK': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 6350},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 12700}
    ],
    'OR': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 2745},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 5495}
    ],
    'RI': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 10550},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 21150}
    ],
    'SC': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 14600},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 29200}
    ],
    'UT': [
        { "year": 2024, "status": "Single", "type": 'Credit', "amount": 876},
        { "year": 2024, "status": "Married", "type": 'Credit', "amount": 1752}
    ],
    'VT': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 7000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 14050}
    ],
    'VA': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 8000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 16000}
    ],
    'WA': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 250000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 250000}
    ],
    'WI': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 13230},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 24490}
    ],
    'DC': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 14600},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 29200}
    ]
};

// 2024 State Personal Exemption
export const STATE_PERSONAL_EXEMPTIONS: { [key: string]: TaxBreak[] } = {
    'AL': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 1500},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 3000},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 1000},
    ],
    'AZ': [
        { "year": 2024, "status": "Single", "type": 'Credit', "amount": 0},
        { "year": 2024, "status": "Married", "type": 'Credit', "amount": 0},
        { "year": 2024, "status": "Dependent", "type": 'Credit', "amount": 100},
    ],
    'AR': [
        { "year": 2024, "status": "Single", "type": 'Credit', "amount": 29},
        { "year": 2024, "status": "Married", "type": 'Credit', "amount": 58},
        { "year": 2024, "status": "Dependent", "type": 'Credit', "amount": 29},
    ],
    'CA': [
        { "year": 2024, "status": "Single", "type": 'Credit', "amount": 144},
        { "year": 2024, "status": "Married", "type": 'Credit', "amount": 288},
        { "year": 2024, "status": "Dependent", "type": 'Credit', "amount": 446},
    ],
    'CT': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 15000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 24000},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 0},
    ],
    'DE': [
        { "year": 2024, "status": "Single", "type": 'Credit', "amount": 110},
        { "year": 2024, "status": "Married", "type": 'Credit', "amount": 220},
        { "year": 2024, "status": "Dependent", "type": 'Credit', "amount": 110},
    ],
    'GA': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 0},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 0},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 3000},
    ],
    'HI': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 1144},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 2288},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 1144},
    ],
    'IL': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 2775},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 5550},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 2775},
    ],
    'ID': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 1000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 1000},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 1000},
    ],
    'IA': [
        { "year": 2024, "status": "Single", "type": 'Credit', "amount": 40},
        { "year": 2024, "status": "Married", "type": 'Credit', "amount": 80},
        { "year": 2024, "status": "Dependent", "type": 'Credit', "amount": 40},
    ],
    'KS': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 2250},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 4500},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 2250},
    ],
    'LA': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 4500},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 9000},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 1000},
    ],
    'ME': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 5000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 10000},
        { "year": 2024, "status": "Dependent", "type": 'Credit', "amount": 300},
    ],
    'MD': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 3200},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 6400},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 3200},
    ],
    'MA': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 4400},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 8800},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 1000},
    ],
    'MI': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 5600},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 11200},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 5600},
    ],
    'MN': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 0},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 0},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 5050},
    ],
    'MS': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 6000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 12000},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 1500},
    ],
    'NE': [
        { "year": 2024, "status": "Single", "type": 'Credit', "amount": 157},
        { "year": 2024, "status": "Married", "type": 'Credit', "amount": 314},
        { "year": 2024, "status": "Dependent", "type": 'Credit', "amount": 157},
    ],
    'NH': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 2400},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 4800},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 0},
    ],
    'NJ': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 1000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 2000},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 1500},
    ],
    'NM': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 0},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 0},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 4000},
    ],
    'NY': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 0},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 0},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 1000},
    ],
    'OH': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 2400},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 4800},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 2500},
    ],
    'OK': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 1000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 2000},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 1000},
    ],
    'OR': [
        { "year": 2024, "status": "Single", "type": 'Credit', "amount": 249},
        { "year": 2024, "status": "Married", "type": 'Credit', "amount": 498},
        { "year": 2024, "status": "Dependent", "type": 'Credit', "amount": 249},
    ],
    'RI': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 4950},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 9900},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 4950},
    ],
    'SC': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 0},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 0},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 4610},
    ],
    'UT': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 0},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 0},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 1941},
    ],
    'VT': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 4850},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 9700},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 4850},
    ],
    'VA': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 930},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 1860},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 930},
    ],
    'WV': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 2000},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 4000},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 2000},
    ],
    'WI': [
        { "year": 2024, "status": "Single", "type": 'Deduction', "amount": 700},
        { "year": 2024, "status": "Married", "type": 'Deduction', "amount": 1400},
        { "year": 2024, "status": "Dependent", "type": 'Deduction', "amount": 700},
    ]
};

// 2025 Federal Capital Gains Tax Brackets
export const CAPITAL_GAINS_TAX_BRACKETS: TaxBracket[] = [
    { "year": 2025, "status": "Single", "min": 0, "max": 48350, "rate": 0.0 },
    { "year": 2025, "status": "Single", "min": 48350, "max": 533400, "rate": 0.15 },
    { "year": 2025, "status": "Single", "min": 533400, "max": Infinity, "rate": 0.2 },

    { "year": 2025, "status": "Married", "min": 0, "max": 96700, "rate": 0.0 },
    { "year": 2025, "status": "Married", "min": 96700, "max": 600050, "rate": 0.15 },
    { "year": 2025, "status": "Married", "min": 600050, "max": Infinity, "rate": 0.2 },

    { "year": 2025, "status": "Married filing separately", "min": 0, "max": 48350, "rate": 0.0 },
    { "year": 2025, "status": "Married filing separately", "min": 48350, "max": 300000, "rate": 0.15 },
    { "year": 2025, "status": "Married filing separately", "min": 300000, "max": Infinity, "rate": 0.2 },

    { "year": 2025, "status": "Head of Household", "min": 0, "max": 64750, "rate": 0.0 },
    { "year": 2025, "status": "Head of Household", "min": 64750, "max": 566700, "rate": 0.15 },
    { "year": 2025, "status": "Head of Household", "min": 566700, "max": Infinity, "rate": 0.2 },
];

// 2025 Federal Tax Brackets
export const FEDERAL_TAX_BRACKETS: TaxBracket[] = [
    { "year": 2025, "status": "Single", "min": 0, "max": 11925, "rate": 0.10 },
    { "year": 2025, "status": "Married", "min": 0, "max": 23850, "rate": 0.10 },
    { "year": 2025, "status": "Married filing separately", "min": 0, "max": 11925, "rate": 0.10 },
    { "year": 2025, "status": "Head of Household", "min": 0, "max": 17000, "rate": 0.10 },
    
    { "year": 2025, "status": "Single", "min": 11926, "max": 48475, "rate": 0.12 },
    { "year": 2025, "status": "Married", "min": 23851, "max": 96950, "rate": 0.12 },
    { "year": 2025, "status": "Married filing separately", "min": 11926, "max": 48475, "rate": 0.12 },
    { "year": 2025, "status": "Head of Household", "min": 17001, "max": 64850, "rate": 0.12 },
    
    { "year": 2025, "status": "Single", "min": 48476, "max": 103350, "rate": 0.22 },
    { "year": 2025, "status": "Married", "min": 96951, "max": 206700, "rate": 0.22 },
    { "year": 2025, "status": "Married filing separately", "min": 48476, "max": 103350, "rate": 0.22 },
    { "year": 2025, "status": "Head of Household", "min": 64851, "max": 103350, "rate": 0.22 },
    
    { "year": 2025, "status": "Single", "min": 103351, "max": 197300, "rate": 0.24 },
    { "year": 2025, "status": "Married", "min": 206701, "max": 394600, "rate": 0.24 },
    { "year": 2025, "status": "Married filing separately", "min": 103351, "max": 197300, "rate": 0.24 },
    { "year": 2025, "status": "Head of Household", "min": 103351, "max": 197300, "rate": 0.24 },
    
    { "year": 2025, "status": "Single", "min": 197301, "max": 250525, "rate": 0.32 },
    { "year": 2025, "status": "Married", "min": 394601, "max": 501050, "rate": 0.32 },
    { "year": 2025, "status": "Married filing separately", "min": 197301, "max": 250525, "rate": 0.32 },
    { "year": 2025, "status": "Head of Household", "min": 197301, "max": 250500, "rate": 0.32 },
    
    { "year": 2025, "status": "Single", "min": 250526, "max": 626350, "rate": 0.35 },
    { "year": 2025, "status": "Married", "min": 501051, "max": 751600, "rate": 0.35 },
    { "year": 2025, "status": "Married filing separately", "min": 250526, "max": 375800, "rate": 0.35 },
    { "year": 2025, "status": "Head of Household", "min": 250501, "max": 626350, "rate": 0.35 },
    
    { "year": 2025, "status": "Single", "min": 626351, "max": Infinity, "rate": 0.37 },
    { "year": 2025, "status": "Married", "min": 751601, "max": Infinity, "rate": 0.37 },
    { "year": 2025, "status": "Married filing separately", "min": 375801, "max": Infinity, "rate": 0.37 },
    { "year": 2025, "status": "Head of Household", "min": 626351, "max": Infinity, "rate": 0.37 }
]
;

// 2024 State Tax Brackets
const STATE_TAX_BRACKETS: { [key: string]: TaxBracket[] } = {
    'AL': [
        { year: 2024, status: 'Single', min: 0, max: 500, rate: 0.02 }, 
        { year: 2024, status: 'Single', min: 500, max: 3000, rate: 0.04 },
        { year: 2024, status: 'Single', min: 3000, max: Infinity, rate: 0.05 },
        { year: 2024, status: 'Married', min: 0, max: 1000, rate: 0.02 }, 
        { year: 2024, status: 'Married', min: 1000, max: 6000, rate: 0.04 }, 
        { year: 2024, status: 'Married', min: 6000, max: Infinity, rate: 0.05 }
    ],
    'AK': [], // No state income tax
    'AZ': [
        { year: 2024, status: 'Single', min: 0, max: Infinity, rate: 0.025 },
        { year: 2024, status: 'Married', min: 0, max: Infinity, rate: 0.025 }
    ],
    "AR": [
        { "year": 2024, "status": "Single", "min": 0, "max": 4400, "rate": 0.02 }, 
        { "year": 2024, "status": "Single", "min": 4400, "max": 8800, "rate": 0.04 },
        { "year": 2024, "status": "Single", "min": 8800, "max": Infinity, "rate": 0.044 },
        { "year": 2024, "status": "Married", "min": 0, "max": 4400, "rate": 0.02 }, 
        { "year": 2024, "status": "Married", "min": 4400, "max": 8800, "rate": 0.04 }, 
        { "year": 2024, "status": "Married", "min": 8800, "max": Infinity, "rate": 0.044 }
    ],
    'CA': [
        { "year": 2024, "status": "Single", "min": 0, "max": 10412, "rate": 0.01 }, 
        { "year": 2024, "status": "Single", "min": 10412, "max": 24684, "rate": 0.02 },
        { "year": 2024, "status": "Single", "min": 24684, "max": 38959, "rate": 0.04 },
        { "year": 2024, "status": "Single", "min": 38959, "max": 54081, "rate": 0.06 },
        { "year": 2024, "status": "Single", "min": 54081, "max": 68350, "rate": 0.08 },
        { "year": 2024, "status": "Single", "min": 68350, "max": 349137, "rate": 0.093 },
        { "year": 2024, "status": "Single", "min": 349137, "max": 418961, "rate": 0.103 },
        { "year": 2024, "status": "Single", "min": 418961, "max": 698271, "rate": 0.113 },
        { "year": 2024, "status": "Single", "min": 698271, "max": 1000000, "rate": 0.123 },
        { "year": 2024, "status": "Single", "min": 1000000, "max": Infinity, "rate": 0.133 },
        
        { "year": 2024, "status": "Married", "min": 0, "max": 20824, "rate": 0.01 }, 
        { "year": 2024, "status": "Married", "min": 20824, "max": 49368, "rate": 0.02 },
        { "year": 2024, "status": "Married", "min": 49368, "max": 77918, "rate": 0.04 },
        { "year": 2024, "status": "Married", "min": 77918, "max": 108162, "rate": 0.06 },
        { "year": 2024, "status": "Married", "min": 108162, "max": 136700, "rate": 0.08 },
        { "year": 2024, "status": "Married", "min": 136700, "max": 698274, "rate": 0.093 },
        { "year": 2024, "status": "Married", "min": 698274, "max": 837922, "rate": 0.103 },
        { "year": 2024, "status": "Married", "min": 837922, "max": 1000000, "rate": 0.113 },
        { "year": 2024, "status": "Married", "min": 1000000, "max": 1396542, "rate": 0.123 },
        { "year": 2024, "status": "Married", "min": 1396542, "max": Infinity, "rate": 0.133 }
    ],
    'CO': [{ year: 2024, status: 'Single', min: 0, max: Infinity, rate: 0.044 },
           { year: 2024, status: 'Married', min: 0, max: Infinity, rate: 0.044 }
    ], // Flat rate
    "CT": [
        { "year": 2024, "status": "Single", "min": 0, "max": 10000, "rate": 0.02 }, 
        { "year": 2024, "status": "Single", "min": 10000, "max": 50000, "rate": 0.045 },
        { "year": 2024, "status": "Single", "min": 50000, "max": 100000, "rate": 0.055 },
        { "year": 2024, "status": "Single", "min": 100000, "max": 200000, "rate": 0.06 },
        { "year": 2024, "status": "Single", "min": 200000, "max": 250000, "rate": 0.065 },
        { "year": 2024, "status": "Single", "min": 250000, "max": 500000, "rate": 0.069 },
        { "year": 2024, "status": "Single", "min": 500000, "max": Infinity, "rate": 0.0699 },
        
        { "year": 2024, "status": "Married", "min": 0, "max": 20000, "rate": 0.02 }, 
        { "year": 2024, "status": "Married", "min": 20000, "max": 100000, "rate": 0.045 },
        { "year": 2024, "status": "Married", "min": 100000, "max": 200000, "rate": 0.055 },
        { "year": 2024, "status": "Married", "min": 200000, "max": 400000, "rate": 0.06 },
        { "year": 2024, "status": "Married", "min": 400000, "max": 500000, "rate": 0.065 },
        { "year": 2024, "status": "Married", "min": 500000, "max": 1000000, "rate": 0.069 },
        { "year": 2024, "status": "Married", "min": 1000000, "max": Infinity, "rate": 0.0699 }
    ],
    "DE": [
        { "year": 2024, "status": "Single", "min": 2000, "max": 5000, "rate": 0.022 }, 
        { "year": 2024, "status": "Single", "min": 5000, "max": 10000, "rate": 0.039 },
        { "year": 2024, "status": "Single", "min": 10000, "max": 20000, "rate": 0.048 },
        { "year": 2024, "status": "Single", "min": 20000, "max": 25000, "rate": 0.052 },
        { "year": 2024, "status": "Single", "min": 25000, "max": 60000, "rate": 0.0555 },
        { "year": 2024, "status": "Single", "min": 60000, "max": Infinity, "rate": 0.066 },
        
        { "year": 2024, "status": "Married", "min": 2000, "max": 5000, "rate": 0.022 }, 
        { "year": 2024, "status": "Married", "min": 5000, "max": 10000, "rate": 0.039 },
        { "year": 2024, "status": "Married", "min": 10000, "max": 20000, "rate": 0.048 },
        { "year": 2024, "status": "Married", "min": 20000, "max": 25000, "rate": 0.052 },
        { "year": 2024, "status": "Married", "min": 25000, "max": 60000, "rate": 0.0555 },
        { "year": 2024, "status": "Married", "min": 60000, "max": Infinity, "rate": 0.066 }
    ],
    "DC": [
        { "year": 2024, "status": "Single", "min": 0, "max": 10000, "rate": 0.04 }, 
        { "year": 2024, "status": "Single", "min": 10000, "max": 40000, "rate": 0.06 },
        { "year": 2024, "status": "Single", "min": 40000, "max": 60000, "rate": 0.065 },
        { "year": 2024, "status": "Single", "min": 60000, "max": 250000, "rate": 0.085 },
        { "year": 2024, "status": "Single", "min": 250000, "max": 500000, "rate": 0.0925 },
        { "year": 2024, "status": "Single", "min": 500000, "max": 1000000, "rate": 0.0975 },
        { "year": 2024, "status": "Single", "min": 1000000, "max": Infinity, "rate": 0.1075 },
        
        { "year": 2024, "status": "Married", "min": 0, "max": 10000, "rate": 0.04 }, 
        { "year": 2024, "status": "Married", "min": 10000, "max": 40000, "rate": 0.06 },
        { "year": 2024, "status": "Married", "min": 40000, "max": 60000, "rate": 0.065 },
        { "year": 2024, "status": "Married", "min": 60000, "max": 250000, "rate": 0.085 },
        { "year": 2024, "status": "Married", "min": 250000, "max": 500000, "rate": 0.0925 },
        { "year": 2024, "status": "Married", "min": 500000, "max": 1000000, "rate": 0.0975 },
        { "year": 2024, "status": "Married", "min": 1000000, "max": Infinity, "rate": 0.1075 }
    ],
    'FL': [], // No state income tax
    'GA': [
        { year: 2024, status: 'Single', min: 0, max: Infinity, rate: 0.0549 },
        { year: 2024, status: 'Married', min: 0, max: Infinity, rate: 0.0549 }
    ],
    "HI": [
        { "year": 2024, "status": "Single", "min": 0, "max": 2400, "rate": 0.014 }, 
        { "year": 2024, "status": "Single", "min": 2400, "max": 4800, "rate": 0.032 },
        { "year": 2024, "status": "Single", "min": 4800, "max": 9600, "rate": 0.055 },
        { "year": 2024, "status": "Single", "min": 9600, "max": 14400, "rate": 0.064 },
        { "year": 2024, "status": "Single", "min": 14400, "max": 19200, "rate": 0.068 },
        { "year": 2024, "status": "Single", "min": 19200, "max": 24000, "rate": 0.072 },
        { "year": 2024, "status": "Single", "min": 24000, "max": 36000, "rate": 0.076 },
        { "year": 2024, "status": "Single", "min": 36000, "max": 48000, "rate": 0.079 },
        { "year": 2024, "status": "Single", "min": 48000, "max": 150000, "rate": 0.0825 },
        { "year": 2024, "status": "Single", "min": 150000, "max": 175000, "rate": 0.09 },
        { "year": 2024, "status": "Single", "min": 175000, "max": 200000, "rate": 0.10 },
        { "year": 2024, "status": "Single", "min": 200000, "max": Infinity, "rate": 0.11 },

        { "year": 2024, "status": "Married", "min": 0, "max": 4800, "rate": 0.014 }, 
        { "year": 2024, "status": "Married", "min": 4800, "max": 9600, "rate": 0.032 },
        { "year": 2024, "status": "Married", "min": 9600, "max": 19200, "rate": 0.055 },
        { "year": 2024, "status": "Married", "min": 19200, "max": 28800, "rate": 0.064 },
        { "year": 2024, "status": "Married", "min": 28800, "max": 38400, "rate": 0.068 },
        { "year": 2024, "status": "Married", "min": 38400, "max": 48000, "rate": 0.072 },
        { "year": 2024, "status": "Married", "min": 48000, "max": 72000, "rate": 0.076 },
        { "year": 2024, "status": "Married", "min": 72000, "max": 96000, "rate": 0.079 },
        { "year": 2024, "status": "Married", "min": 96000, "max": 300000, "rate": 0.0825 },
        { "year": 2024, "status": "Married", "min": 300000, "max": 350000, "rate": 0.09 },
        { "year": 2024, "status": "Married", "min": 350000, "max": 400000, "rate": 0.10 },
        { "year": 2024, "status": "Married", "min": 400000, "max": Infinity, "rate": 0.11 }
    ],
    "ID": [
        { "year": 2024, "status": "Single", "min": 4489, "max": Infinity, "rate": 0.058 }, 
        { "year": 2024, "status": "Married", "min": 8978, "max": Infinity, "rate": 0.058 }
    ],
    "IL": [
        { "year": 2024, "status": "Single", "min": 0, "max": Infinity, "rate": 0.0495 }, 
        { "year": 2024, "status": "Married", "min": 0, "max": Infinity, "rate": 0.0495 }
    ],
    "IN": [
        { "year": 2024, "status": "Single", "min": 0, "max": Infinity, "rate": 0.0305 }, 
        { "year": 2024, "status": "Married", "min": 0, "max": Infinity, "rate": 0.0305 }
    ],
    "IA": [
        { "year": 2024, "status": "Single", "min": 0, "max": 6210, "rate": 0.044 }, 
        { "year": 2024, "status": "Single", "min": 6210, "max": 31050, "rate": 0.0482 },
        { "year": 2024, "status": "Single", "min": 31050, "max": Infinity, "rate": 0.057 },

        { "year": 2024, "status": "Married", "min": 0, "max": 12420, "rate": 0.044 }, 
        { "year": 2024, "status": "Married", "min": 12420, "max": 62100, "rate": 0.0482 },
        { "year": 2024, "status": "Married", "min": 62100, "max": Infinity, "rate": 0.057 }
    ],
    "KS": [
        { "year": 2024, "status": "Single", "min": 0, "max": 15000, "rate": 0.031 }, 
        { "year": 2024, "status": "Single", "min": 15000, "max": 30000, "rate": 0.0525 },
        { "year": 2024, "status": "Single", "min": 30000, "max": Infinity, "rate": 0.057 },

        { "year": 2024, "status": "Married", "min": 0, "max": 30000, "rate": 0.031 }, 
        { "year": 2024, "status": "Married", "min": 30000, "max": 60000, "rate": 0.0525 },
        { "year": 2024, "status": "Married", "min": 60000, "max": Infinity, "rate": 0.057 }
    ],
    "KY": [
        { "year": 2024, "status": "Single", "min": 0, "max": Infinity, "rate": 0.04 }, 
        { "year": 2024, "status": "Married", "min": 0, "max": Infinity, "rate": 0.04 }
    ],
    "LA": [
        { "year": 2024, "status": "Single", "min": 0, "max": 12500, "rate": 0.0185 }, 
        { "year": 2024, "status": "Single", "min": 12500, "max": 50000, "rate": 0.035 },
        { "year": 2024, "status": "Single", "min": 50000, "max": Infinity, "rate": 0.0425 },

        { "year": 2024, "status": "Married", "min": 0, "max": 25000, "rate": 0.0185 }, 
        { "year": 2024, "status": "Married", "min": 25000, "max": 100000, "rate": 0.035 },
        { "year": 2024, "status": "Married", "min": 100000, "max": Infinity, "rate": 0.0425 }
    ],
    "ME": [
        { "year": 2024, "status": "Single", "min": 0, "max": 26050, "rate": 0.058 }, 
        { "year": 2024, "status": "Single", "min": 26050, "max": 61600, "rate": 0.0675 },
        { "year": 2024, "status": "Single", "min": 61600, "max": Infinity, "rate": 0.0715 },

        { "year": 2024, "status": "Married", "min": 0, "max": 52100, "rate": 0.058 }, 
        { "year": 2024, "status": "Married", "min": 52100, "max": 123250, "rate": 0.0675 },
        { "year": 2024, "status": "Married", "min": 123250, "max": Infinity, "rate": 0.0715 }
    ],
    "MD": [
        { "year": 2024, "status": "Single", "min": 0, "max": 1000, "rate": 0.02 }, 
        { "year": 2024, "status": "Single", "min": 1000, "max": 2000, "rate": 0.03 },
        { "year": 2024, "status": "Single", "min": 2000, "max": 3000, "rate": 0.04 },
        { "year": 2024, "status": "Single", "min": 3000, "max": 100000, "rate": 0.0475 },
        { "year": 2024, "status": "Single", "min": 100000, "max": 125000, "rate": 0.05 },
        { "year": 2024, "status": "Single", "min": 125000, "max": 150000, "rate": 0.0525 },
        { "year": 2024, "status": "Single", "min": 150000, "max": 250000, "rate": 0.055 },
        { "year": 2024, "status": "Single", "min": 250000, "max": Infinity, "rate": 0.0575 },

        { "year": 2024, "status": "Married", "min": 0, "max": 1000, "rate": 0.02 }, 
        { "year": 2024, "status": "Married", "min": 1000, "max": 2000, "rate": 0.03 },
        { "year": 2024, "status": "Married", "min": 2000, "max": 3000, "rate": 0.04 },
        { "year": 2024, "status": "Married", "min": 3000, "max": 150000, "rate": 0.0475 },
        { "year": 2024, "status": "Married", "min": 150000, "max": 175000, "rate": 0.05 },
        { "year": 2024, "status": "Married", "min": 175000, "max": 225000, "rate": 0.0525 },
        { "year": 2024, "status": "Married", "min": 225000, "max": 300000, "rate": 0.055 },
        { "year": 2024, "status": "Married", "min": 300000, "max": Infinity, "rate": 0.0575 }
    ],
    "MA": [
        { "year": 2024, "status": "Single", "min": 0, "max": 1000000, "rate": 0.05 }, 
        { "year": 2024, "status": "Single", "min": 1000000, "max": Infinity, "rate": 0.09 },

        { "year": 2024, "status": "Married", "min": 0, "max": 1000000, "rate": 0.05 }, 
        { "year": 2024, "status": "Married", "min": 1000000, "max": Infinity, "rate": 0.09 }
    ],
    "MI": [
        { "year": 2024, "status": "Single", "min": 0, "max": Infinity, "rate": 0.0425 }, 
        { "year": 2024, "status": "Married", "min": 0, "max": Infinity, "rate": 0.0425 }
    ],
    "MN": [
        { "year": 2024, "status": "Single", "min": 0, "max": 31690, "rate": 0.0535 }, 
        { "year": 2024, "status": "Single", "min": 31690, "max": 104090, "rate": 0.068 },
        { "year": 2024, "status": "Single", "min": 104090, "max": 193240, "rate": 0.0785 },
        { "year": 2024, "status": "Single", "min": 193240, "max": Infinity, "rate": 0.0985 },

        { "year": 2024, "status": "Married", "min": 0, "max": 46330, "rate": 0.0535 }, 
        { "year": 2024, "status": "Married", "min": 46330, "max": 184040, "rate": 0.068 },
        { "year": 2024, "status": "Married", "min": 184040, "max": 321450, "rate": 0.0785 },
        { "year": 2024, "status": "Married", "min": 321450, "max": Infinity, "rate": 0.0985 }
    ],
    "MS": [
        { "year": 2024, "status": "Single", "min": 10000, "max": Infinity, "rate": 0.047 }, 
        { "year": 2024, "status": "Married", "min": 10000, "max": Infinity, "rate": 0.047 }
    ],
    "MO": [
        { "year": 2024, "status": "Single", "min": 1273, "max": 2546, "rate": 0.02 }, 
        { "year": 2024, "status": "Single", "min": 2546, "max": 3819, "rate": 0.025 },
        { "year": 2024, "status": "Single", "min": 3819, "max": 5092, "rate": 0.03 },
        { "year": 2024, "status": "Single", "min": 5092, "max": 6365, "rate": 0.035 },
        { "year": 2024, "status": "Single", "min": 6365, "max": 7638, "rate": 0.04 },
        { "year": 2024, "status": "Single", "min": 7638, "max": 8911, "rate": 0.045 },
        { "year": 2024, "status": "Single", "min": 8911, "max": Infinity, "rate": 0.048 },

        { "year": 2024, "status": "Married", "min": 1207, "max": 2414, "rate": 0.02 }, 
        { "year": 2024, "status": "Married", "min": 2414, "max": 3621, "rate": 0.025 },
        { "year": 2024, "status": "Married", "min": 3621, "max": 4828, "rate": 0.03 },
        { "year": 2024, "status": "Married", "min": 4828, "max": 6035, "rate": 0.035 },
        { "year": 2024, "status": "Married", "min": 6035, "max": 7242, "rate": 0.04 },
        { "year": 2024, "status": "Married", "min": 7242, "max": 8449, "rate": 0.045 },
        { "year": 2024, "status": "Married", "min": 8449, "max": Infinity, "rate": 0.048 }
    ],
    "MT": [
        { "year": 2024, "status": "Single", "min": 0, "max": 20500, "rate": 0.047 }, 
        { "year": 2024, "status": "Single", "min": 20500, "max": Infinity, "rate": 0.059 },

        { "year": 2024, "status": "Married", "min": 0, "max": 41000, "rate": 0.047 }, 
        { "year": 2024, "status": "Married", "min": 41000, "max": Infinity, "rate": 0.059 }
    ],
    "NE": [
        { "year": 2024, "status": "Single", "min": 0, "max": 3700, "rate": 0.0246 }, 
        { "year": 2024, "status": "Single", "min": 3700, "max": 22170, "rate": 0.0351 },
        { "year": 2024, "status": "Single", "min": 22170, "max": 35730, "rate": 0.0501 },
        { "year": 2024, "status": "Single", "min": 35730, "max": Infinity, "rate": 0.0584 },

        { "year": 2024, "status": "Married", "min": 0, "max": 7390, "rate": 0.0246 }, 
        { "year": 2024, "status": "Married", "min": 7390, "max": 44350, "rate": 0.0351 },
        { "year": 2024, "status": "Married", "min": 44350, "max": 71460, "rate": 0.0501 },
        { "year": 2024, "status": "Married", "min": 71460, "max": Infinity, "rate": 0.0584 }
    ],
    "NV": [],
    "NH": [], // 3% on interest and dividends so assume 0%
    "NJ": [
        { "year": 2024, "status": "Single", "min": 0, "max": 20000, "rate": 0.014 }, 
        { "year": 2024, "status": "Single", "min": 20000, "max": 35000, "rate": 0.0175 },
        { "year": 2024, "status": "Single", "min": 35000, "max": 40000, "rate": 0.035 },
        { "year": 2024, "status": "Single", "min": 40000, "max": 75000, "rate": 0.05525 },
        { "year": 2024, "status": "Single", "min": 75000, "max": 500000, "rate": 0.0637 },
        { "year": 2024, "status": "Single", "min": 500000, "max": 1000000, "rate": 0.0897 },
        { "year": 2024, "status": "Single", "min": 1000000, "max": Infinity, "rate": 0.1075 },

        { "year": 2024, "status": "Married", "min": 0, "max": 20000, "rate": 0.014 }, 
        { "year": 2024, "status": "Married", "min": 20000, "max": 50000, "rate": 0.0175 },
        { "year": 2024, "status": "Married", "min": 50000, "max": 70000, "rate": 0.0245 },
        { "year": 2024, "status": "Married", "min": 70000, "max": 80000, "rate": 0.035 },
        { "year": 2024, "status": "Married", "min": 80000, "max": 150000, "rate": 0.05525 },
        { "year": 2024, "status": "Married", "min": 150000, "max": 500000, "rate": 0.0637 },
        { "year": 2024, "status": "Married", "min": 500000, "max": 1000000, "rate": 0.0897 },
        { "year": 2024, "status": "Married", "min": 1000000, "max": Infinity, "rate": 0.1075 }
    ],
    "NM": [
        { "year": 2024, "status": "Single", "min": 0, "max": 5500, "rate": 0.017 }, 
        { "year": 2024, "status": "Single", "min": 5500, "max": 11000, "rate": 0.032 },
        { "year": 2024, "status": "Single", "min": 11000, "max": 16000, "rate": 0.047 },
        { "year": 2024, "status": "Single", "min": 16000, "max": 210000, "rate": 0.049 },
        { "year": 2024, "status": "Single", "min": 210000, "max": Infinity, "rate": 0.059 },

        { "year": 2024, "status": "Married", "min": 0, "max": 8000, "rate": 0.017 }, 
        { "year": 2024, "status": "Married", "min": 8000, "max": 16000, "rate": 0.032 },
        { "year": 2024, "status": "Married", "min": 16000, "max": 24000, "rate": 0.047 },
        { "year": 2024, "status": "Married", "min": 24000, "max": 315000, "rate": 0.049 },
        { "year": 2024, "status": "Married", "min": 315000, "max": Infinity, "rate": 0.059 }
    ],
    "NY": [
        { "year": 2024, "status": "Single", "min": 0, "max": 8500, "rate": 0.04 }, 
        { "year": 2024, "status": "Single", "min": 8500, "max": 11700, "rate": 0.045 },
        { "year": 2024, "status": "Single", "min": 11700, "max": 13900, "rate": 0.0525 },
        { "year": 2024, "status": "Single", "min": 13900, "max": 80650, "rate": 0.055 },
        { "year": 2024, "status": "Single", "min": 80650, "max": 215400, "rate": 0.06 },
        { "year": 2024, "status": "Single", "min": 215400, "max": 1077550, "rate": 0.0685 },
        { "year": 2024, "status": "Single", "min": 1077550, "max": 5000000, "rate": 0.0965 },
        { "year": 2024, "status": "Single", "min": 5000000, "max": 25000000, "rate": 0.103 },
        { "year": 2024, "status": "Single", "min": 25000000, "max": Infinity, "rate": 0.109 },

        { "year": 2024, "status": "Married", "min": 0, "max": 17150, "rate": 0.04 }, 
        { "year": 2024, "status": "Married", "min": 17150, "max": 23600, "rate": 0.045 },
        { "year": 2024, "status": "Married", "min": 23600, "max": 27900, "rate": 0.0525 },
        { "year": 2024, "status": "Married", "min": 27900, "max": 161550, "rate": 0.055 },
        { "year": 2024, "status": "Married", "min": 161550, "max": 323200, "rate": 0.06 },
        { "year": 2024, "status": "Married", "min": 323200, "max": 2155350, "rate": 0.0685 },
        { "year": 2024, "status": "Married", "min": 2155350, "max": 5000000, "rate": 0.0965 },
        { "year": 2024, "status": "Married", "min": 5000000, "max": 25000000, "rate": 0.103 },
        { "year": 2024, "status": "Married", "min": 25000000, "max": Infinity, "rate": 0.109 }
    ],
    "NC": [
        { "year": 2024, "status": "Single", "min": 0, "max": Infinity, "rate": 0.045 }, 
        { "year": 2024, "status": "Married", "min": 0, "max": Infinity, "rate": 0.045 }
    ],
    "ND": [
        { "year": 2024, "status": "Single", "min": 44725, "max": 225975, "rate": 0.0195 }, 
        { "year": 2024, "status": "Single", "min": 225975, "max": Infinity, "rate": 0.025 },

        { "year": 2024, "status": "Married", "min": 74750, "max": 275100, "rate": 0.0195 }, 
        { "year": 2024, "status": "Married", "min": 275100, "max": Infinity, "rate": 0.025 }
    ],
    "OH": [
        { "year": 2024, "status": "Single", "min": 26050, "max": 92150, "rate": 0.0275 }, 
        { "year": 2024, "status": "Single", "min": 92150, "max": Infinity, "rate": 0.035 },

        { "year": 2024, "status": "Married", "min": 26050, "max": 92150, "rate": 0.0275 }, 
        { "year": 2024, "status": "Married", "min": 92150, "max": Infinity, "rate": 0.035 }
    ],
    "OK": [
        { "year": 2024, "status": "Single", "min": 0, "max": 1000, "rate": 0.0025 }, 
        { "year": 2024, "status": "Single", "min": 1000, "max": 2500, "rate": 0.0075 },
        { "year": 2024, "status": "Single", "min": 2500, "max": 3750, "rate": 0.0175 },
        { "year": 2024, "status": "Single", "min": 3750, "max": 4900, "rate": 0.0275 },
        { "year": 2024, "status": "Single", "min": 4900, "max": 7200, "rate": 0.0375 },
        { "year": 2024, "status": "Single", "min": 7200, "max": Infinity, "rate": 0.0475 },

        { "year": 2024, "status": "Married", "min": 0, "max": 2000, "rate": 0.0025 }, 
        { "year": 2024, "status": "Married", "min": 2000, "max": 5000, "rate": 0.0075 },
        { "year": 2024, "status": "Married", "min": 5000, "max": 7500, "rate": 0.0175 },
        { "year": 2024, "status": "Married", "min": 7500, "max": 9800, "rate": 0.0275 },
        { "year": 2024, "status": "Married", "min": 9800, "max": 12200, "rate": 0.0375 },
        { "year": 2024, "status": "Married", "min": 12200, "max": Infinity, "rate": 0.0475 }
    ],
    "OR": [
        { "year": 2024, "status": "Single", "min": 0, "max": 4300, "rate": 0.0475 }, 
        { "year": 2024, "status": "Single", "min": 4300, "max": 10750, "rate": 0.0675 },
        { "year": 2024, "status": "Single", "min": 10750, "max": 125000, "rate": 0.0875 },
        { "year": 2024, "status": "Single", "min": 125000, "max": Infinity, "rate": 0.099 },

        { "year": 2024, "status": "Married", "min": 0, "max": 8600, "rate": 0.0475 }, 
        { "year": 2024, "status": "Married", "min": 8600, "max": 21500, "rate": 0.0675 },
        { "year": 2024, "status": "Married", "min": 21500, "max": 250000, "rate": 0.0875 },
        { "year": 2024, "status": "Married", "min": 250000, "max": Infinity, "rate": 0.099 }
    ],
    "PA": [
        { "year": 2024, "status": "Single", "min": 0, "max": Infinity, "rate": 0.0307 }, 
        { "year": 2024, "status": "Married", "min": 0, "max": Infinity, "rate": 0.0307 }
    ],
    "RI": [
        { "year": 2024, "status": "Single", "min": 0, "max": 77450, "rate": 0.0375 }, 
        { "year": 2024, "status": "Single", "min": 77450, "max": 176050, "rate": 0.0475 },
        { "year": 2024, "status": "Single", "min": 176050, "max": Infinity, "rate": 0.0599 },

        { "year": 2024, "status": "Married", "min": 0, "max": 77450, "rate": 0.0375 }, 
        { "year": 2024, "status": "Married", "min": 77450, "max": 176050, "rate": 0.0475 },
        { "year": 2024, "status": "Married", "min": 176050, "max": Infinity, "rate": 0.0599 }
    ],
    "SC": [
        { "year": 2024, "status": "Single", "min": 0, "max": 3460, "rate": 0.00 }, 
        { "year": 2024, "status": "Single", "min": 3460, "max": 17330, "rate": 0.03 },
        { "year": 2024, "status": "Single", "min": 17330, "max": Infinity, "rate": 0.064 },

        { "year": 2024, "status": "Married", "min": 0, "max": 3460, "rate": 0.00 }, 
        { "year": 2024, "status": "Married", "min": 3460, "max": 17330, "rate": 0.03 },
        { "year": 2024, "status": "Married", "min": 17330, "max": Infinity, "rate": 0.064 }
    ],
    "SD": [],
    "TN": [],
    "TX": [],
    "UT": [
        { "year": 2024, "status": "Single", "min": 0, "max": Infinity, "rate": 0.0465 }, 
        { "year": 2024, "status": "Married", "min": 0, "max": Infinity, "rate": 0.0465 }
    ],
    "VT": [
        { "year": 2024, "status": "Single", "min": 0, "max": 45400, "rate": 0.0335 }, 
        { "year": 2024, "status": "Single", "min": 45400, "max": 110050, "rate": 0.066 },
        { "year": 2024, "status": "Single", "min": 110050, "max": 229550, "rate": 0.076 },
        { "year": 2024, "status": "Single", "min": 229550, "max": Infinity, "rate": 0.0875 },

        { "year": 2024, "status": "Married", "min": 0, "max": 75850, "rate": 0.0335 }, 
        { "year": 2024, "status": "Married", "min": 75850, "max": 183400, "rate": 0.066 },
        { "year": 2024, "status": "Married", "min": 183400, "max": 279450, "rate": 0.076 },
        { "year": 2024, "status": "Married", "min": 279450, "max": Infinity, "rate": 0.0875 }
    ],
    "VA": [
        { "year": 2024, "status": "Single", "min": 0, "max": 3000, "rate": 0.02 }, 
        { "year": 2024, "status": "Single", "min": 3000, "max": 5000, "rate": 0.03 },
        { "year": 2024, "status": "Single", "min": 5000, "max": 17000, "rate": 0.05 },
        { "year": 2024, "status": "Single", "min": 17000, "max": Infinity, "rate": 0.0575 },

        { "year": 2024, "status": "Married", "min": 0, "max": 3000, "rate": 0.02 }, 
        { "year": 2024, "status": "Married", "min": 3000, "max": 5000, "rate": 0.03 },
        { "year": 2024, "status": "Married", "min": 5000, "max": 17000, "rate": 0.05 },
        { "year": 2024, "status": "Married", "min": 17000, "max": Infinity, "rate": 0.0575 }
    ],
    "WA": [], // 7% on capital gains only assume to be 0 for now
    "WV": [
        { "year": 2024, "status": "Single", "min": 0, "max": 10000, "rate": 0.0236 }, 
        { "year": 2024, "status": "Single", "min": 10000, "max": 25000, "rate": 0.0315 },
        { "year": 2024, "status": "Single", "min": 25000, "max": 40000, "rate": 0.0354 },
        { "year": 2024, "status": "Single", "min": 40000, "max": 60000, "rate": 0.0472 },
        { "year": 2024, "status": "Single", "min": 60000, "max": Infinity, "rate": 0.0512 },

        { "year": 2024, "status": "Married", "min": 0, "max": 10000, "rate": 0.0236 }, 
        { "year": 2024, "status": "Married", "min": 10000, "max": 25000, "rate": 0.0315 },
        { "year": 2024, "status": "Married", "min": 25000, "max": 40000, "rate": 0.0354 },
        { "year": 2024, "status": "Married", "min": 40000, "max": 60000, "rate": 0.0472 },
        { "year": 2024, "status": "Married", "min": 60000, "max": Infinity, "rate": 0.0512 }
    ],
    "WI": [
        { "year": 2024, "status": "Single", "min": 0, "max": 14320, "rate": 0.035 }, 
        { "year": 2024, "status": "Single", "min": 14320, "max": 28640, "rate": 0.044 },
        { "year": 2024, "status": "Single", "min": 28640, "max": 315310, "rate": 0.053 },
        { "year": 2024, "status": "Single", "min": 315310, "max": Infinity, "rate": 0.0765 },

        { "year": 2024, "status": "Married", "min": 0, "max": 19090, "rate": 0.035 }, 
        { "year": 2024, "status": "Married", "min": 19090, "max": 38190, "rate": 0.044 },
        { "year": 2024, "status": "Married", "min": 38190, "max": 420420, "rate": 0.053 },
        { "year": 2024, "status": "Married", "min": 420420, "max": Infinity, "rate": 0.0765 }
    ],
    "WY": []
};

export const filterTaxBrackets = (brackets: TaxBracket[], status: string) => {
    return brackets.filter(bracket => bracket.status === status);
}

export const calculateTax = (income: number, tax_brackets: TaxBracket[], status: string, deductionType: string, standardDeductions: TaxBreak[] = [], personalExemptions: TaxBreak[] = [], dependents: number = 0, itemizedDeduction: number = 0): number => {
    // Filter brackets and tax breaks for the given filing status
    const filteredBrackets = filterTaxBrackets(tax_brackets, status);
    const filteredStandardDeductions = standardDeductions.filter(taxBreak => taxBreak.status === status);
    const filteredPersonalExemptions = personalExemptions.filter(taxBreak => taxBreak.status === status);
    const dependentBreaks = personalExemptions.filter(taxBreak => taxBreak.status === 'Dependent');
    let deductions = 0;
    let credits = 0;

    // Apply standard or itemized deductions to reduce taxable income
    if (deductionType === 'Standard') {
        deductions = filteredStandardDeductions
            .filter(taxBreak => taxBreak.type === 'Deduction')
            .reduce((total, deduction) => total + deduction.amount, 0);
    } else if (deductionType === 'Itemized') {
        deductions = itemizedDeduction;
    }
    // Apply personal exemptions deductions
    const personalExemptionDeductions = filteredPersonalExemptions
        .filter(taxBreak => taxBreak.type === 'Deduction')
        .reduce((total, deduction) => total + deduction.amount, 0);

    // Apply dependent deductions for personal exemptions
    const dependentDeductions = dependentBreaks
        .filter(taxBreak => taxBreak.type === 'Deduction')
        .reduce((total, deduction) => total + (deduction.amount * dependents), 0);

    // Calculate tax on income after deductions
    let taxableIncome = Math.max(0, income - deductions - dependentDeductions - personalExemptionDeductions);
    let tax = 0;
    let remainingIncome = taxableIncome;

    // Sort brackets by min value to ensure correct calculation order
    const sortedBrackets = filteredBrackets.sort((a, b) => a.min - b.min);

    // Calculate tax for each bracket
    for (const bracket of sortedBrackets) {
        const taxableInBracket = Math.min(
            Math.max(0, remainingIncome),
            bracket.max === Infinity ? remainingIncome : bracket.max - bracket.min
        );
        tax += taxableInBracket * bracket.rate;
        remainingIncome -= taxableInBracket;
        if (remainingIncome <= 0) break;
    }

    // Apply tax credits to reduce tax owed
    if (deductionType === 'Standard') {
        credits = filteredStandardDeductions
            .filter(taxBreak => taxBreak.type === 'Credit')
            .reduce((total, credit) => total + credit.amount, 0);
    } else if (deductionType === 'Itemized') {
        credits = 0;
    }
    // Apply personal exemptions credits
    const personalExemptionCredits = filteredPersonalExemptions
        .filter(taxBreak => taxBreak.type === 'Credit')
        .reduce((total, credit) => total + credit.amount, 0);
    // Apply dependent credits for personal exemptions
    const dependentCredits = dependentBreaks
        .filter(taxBreak => taxBreak.type === 'Credit')
        .reduce((total, credit) => total + (credit.amount * dependents), 0);
    console.log(dependentCredits, dependents);
    // Tax cannot be negative even after credits
    return Math.max(0, tax - credits - dependentCredits - personalExemptionCredits);
};

export const calculateStateTax = (income: number, state: string, status: string, deductionType: string, dependents: number = 0, itemizedDeduction: number = 0): number => {
    const stateBrackets = STATE_TAX_BRACKETS[state] || [];
    const stateDeductions = STATE_STANDARD_DEDUCTIONS[state] || [];
    const stateExemptions = STATE_PERSONAL_EXEMPTIONS[state] || [];
    
    // If state has no income tax (empty brackets array), return 0
    if (!stateBrackets || stateBrackets.length === 0) {
        return 0;
    }

    // Convert status to state tax status (states typically only have Single/Married)
    const stateStatus = (status === 'Married' ? 'Married' : 'Single')
    
    return calculateTax(income, stateBrackets, stateStatus, deductionType, stateDeductions, stateExemptions, dependents, itemizedDeduction);
};

export const calculateSocialSecurityTax = (income: number): number => {
    return Math.min(income, maxTaxableIncome) * SOCIAL_SECURITY_RATE;
};

export const calculateMedicareTax = (income: number): number => {
    return income * MEDICARE_RATE;
};

export const calculateCapitalGainsTax = (income: number, tax_brackets: TaxBracket[], capitalGains: number, status: string): number => {
    // Filter and find the appropriate bracket for the given income and filing status
    const filteredBrackets = filterTaxBrackets(tax_brackets, status);
    const bracket = filteredBrackets.find(br => br.min <= income && br.max >= income);
    const rate = bracket?.rate || 0;
    return capitalGains * rate;
};

export const generateProjections = (
    inputs: UserInputs,
    userProfile: { age: number; retirementAge: number; stateOfResidence: string; taxFilingStatus: string; deductionType: string; itemizedDeductions: number; numberOfDependents: number; propertyTaxs: number; localTaxs: number },
    oneTimeExpenses: OneTimeExpense[]
): FinancialProjection[] => {
    const projections: FinancialProjection[] = [];
    let currentIncome = inputs.currentPreTaxIncome;
    let currentExpenses = inputs.currentExpenses;
    let investmentBalance = inputs.startingInvestmentBalance;
    let cashBalance = inputs.startingCashBalance;
    const projectionYears = 100 - userProfile.age;
    const investmentReturn = calculateInvestmentReturn(inputs.returnProfile);
    let cumulativeInvestmentGains = 0;
    const cleanedProperty = (userProfile.propertyTaxs === undefined || userProfile.propertyTaxs === null) ? 0 : Number(userProfile.propertyTaxs);
    const cleanedLocal = (userProfile.localTaxs === undefined || userProfile.localTaxs === null) ? 0 : Number(userProfile.localTaxs);
    const dependents = userProfile.numberOfDependents || 0;

    // Generate projections for each year until 100 years old
    for (let i = 0; i <= projectionYears; i++) {
        const age = userProfile.age + i;
        const year = new Date().getFullYear() + i;
        const isRetired = age >= userProfile.retirementAge;

        // Calculate income (0 if retired)
        const yearlyIncome = isRetired ? 0 : currentIncome;
        
        // Calculate taxes
        const federalTax = calculateTax(yearlyIncome, FEDERAL_TAX_BRACKETS, userProfile.taxFilingStatus, userProfile.deductionType, FEDERAL_STANDARD_DEDUCTIONS, [], dependents, userProfile.itemizedDeductions);
        const stateTax = calculateStateTax(yearlyIncome, userProfile.stateOfResidence, userProfile.taxFilingStatus, userProfile.deductionType, dependents, userProfile.itemizedDeductions);
        const socialSecurityTax = calculateSocialSecurityTax(yearlyIncome);
        const medicareTax = calculateMedicareTax(yearlyIncome);
        const totalTax = federalTax + stateTax + socialSecurityTax + medicareTax + cleanedProperty + cleanedLocal;

        console.log('federalTax', federalTax)
        console.log('stateTax', stateTax)
        console.log('socialSecurityTax', socialSecurityTax)
        console.log('medicareTax', medicareTax)
        console.log('propertyTax', cleanedProperty)
        console.log('localTax', cleanedLocal)
        console.log('totalTax', totalTax)

        // Calculate one-time expenses for this year
        const yearlyOneTimeExpenses = oneTimeExpenses
            .filter(expense => expense.year === year)
            .reduce((sum, expense) => sum + expense.amount, 0);

        // Calculate net income and savings
        const netIncome = yearlyIncome - totalTax;
        const yearlySavings = netIncome - currentExpenses - yearlyOneTimeExpenses;
        let capitalGainsTax = 0;

        // Update cash balance
        cashBalance += yearlySavings;
        const capitalGainsPercentage = cumulativeInvestmentGains / investmentBalance;
        if (cashBalance < 0 && (investmentBalance + cashBalance) < 0) {
            const capitalGains = investmentBalance * capitalGainsPercentage;
            capitalGainsTax = calculateCapitalGainsTax(yearlyIncome, CAPITAL_GAINS_TAX_BRACKETS, capitalGains, userProfile.taxFilingStatus);
            capitalGainsTax += calculateStateTax(yearlyIncome + capitalGains, userProfile.stateOfResidence, userProfile.taxFilingStatus, userProfile.deductionType, dependents, userProfile.itemizedDeductions) - calculateStateTax(yearlyIncome, userProfile.stateOfResidence, userProfile.taxFilingStatus, userProfile.deductionType, dependents, userProfile.itemizedDeductions);
            cashBalance = investmentBalance + cashBalance - capitalGainsTax;
            investmentBalance = 0;
        }
        else if (cashBalance < 0 && (investmentBalance + cashBalance) >= 0) {
            const taxStatus = userProfile.taxFilingStatus;
            const capitalGainsRate = CAPITAL_GAINS_TAX_BRACKETS.find(br => br.min <= (Math.abs(yearlySavings)) && br.max >= (Math.abs(yearlySavings)) && br.status === taxStatus)?.rate || 0;
            const stateTaxRate = STATE_TAX_BRACKETS[userProfile.stateOfResidence].find(br => br.min <= (Math.abs(yearlySavings)) && br.max >= (Math.abs(yearlySavings)) && br.status === taxStatus)?.rate || 0;
            const saleAmount = cashBalance / (1 - ((capitalGainsRate + stateTaxRate) * capitalGainsPercentage)); 
            investmentBalance += saleAmount;
            cashBalance = 0;
            cumulativeInvestmentGains += saleAmount * capitalGainsPercentage;
        }
        else if (cashBalance > 0) {
            cashBalance = cashBalance * (1 + REAL_RISK_FREE_RETURN_RATE);
        }
        
        // Update investment balance with returns
        const investmentReturns = investmentBalance * investmentReturn;
        investmentBalance += investmentReturns;
        cumulativeInvestmentGains += investmentReturns;

        if (cashBalance > currentExpenses) {
            const excessCash = cashBalance - currentExpenses;
            cashBalance = currentExpenses;
            investmentBalance += excessCash;
        }
        
        // Create projection for this year
        projections.push({
            year,
            age,
            income: yearlyIncome,
            expenses: currentExpenses,
            oneTimeExpenses: yearlyOneTimeExpenses,
            federalTax,
            stateTax,
            socialSecurityTax,
            medicareTax,
            propertyTax: cleanedProperty,
            localTax: cleanedLocal,
            totalTax,
            netIncome,
            savings: yearlySavings,
            investmentBalance,
            cashBalance,
            investmentReturns,
            totalNetWorth: investmentBalance + cashBalance
        });

        // Update values for next year
        if (!isRetired) {
            currentIncome *= (1 + inputs.realWageGrowth / 100);
            currentExpenses *= (1 + inputs.realSpendingGrowth / 100);
        }
    }

    return projections;
};
