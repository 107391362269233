import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Container, 
    Paper, 
    TextField, 
    Button, 
    Typography, 
    Box,
    Link,
    CircularProgress,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    useTheme,
    useMediaQuery,
    Fade,
    Alert,
    IconButton,
    InputAdornment,
    Stepper,
    Step,
    StepLabel,
    Stack,
    Divider,
    Zoom,
    alpha
} from '@mui/material';
import { loginStart, loginSuccess, loginFailure, logout } from '../store/authSlice';
import { RootState } from '../store';
import { SHA256 } from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import api from '../services/api';
import { 
    Visibility, 
    VisibilityOff,
    AccountCircle as AccountCircleIcon,
    Email as EmailIcon,
    Lock as LockIcon,
    Person as PersonIcon,
    Home as HomeIcon,
    Cake as CakeIcon,
    LocationOn as LocationOnIcon,
    Favorite as FavoriteIcon,
} from '@mui/icons-material';

const US_STATES = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

const SIGNUP_STEPS = ['Account', 'Personal Info', 'Tax Info'];

const HomePage: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const [isLogin, setIsLogin] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    // Form states
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [age, setAge] = useState('');
    const [retirementAge, setRetirementAge] = useState('');
    const [stateOfResidence, setStateOfResidence] = useState('');
    const [taxFilingStatus, setTaxFilingStatus] = useState('Single');
    const [deductionType, setDeductionType] = useState('Standard');
    const [itemizedDeductions, setItemizedDeductions] = useState('0');
    const [numberOfDependents, setNumberOfDependents] = useState('0');
    const [propertyTaxs, setPropertyTaxs] = useState('0');
    const [localTaxs, setLocalTaxs] = useState('0');
    
    const dispatch = useDispatch();
    const { loading, error } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (!isLogin && activeStep !== SIGNUP_STEPS.length - 1) {
            handleNext();
            return;
        }

        setIsSubmitting(true);
        dispatch(loginStart());
        const hashedPassword = SHA256(password).toString();
        
        try {
            if (isLogin) {
                const response = await api.post('/login', { email, password: hashedPassword });
                dispatch(loginSuccess(response.data));
                navigate('/home');
            } else {
                const userId = uuidv4();
                const userData = {
                    userId,
                    email,
                    password: hashedPassword,
                    firstName,
                    lastName,
                    age: parseInt(age),
                    retirementAge: parseInt(retirementAge),
                    stateOfResidence,
                    taxFilingStatus,
                    deductionType,
                    itemizedDeductions: parseFloat(itemizedDeductions),
                    numberOfDependents: parseInt(numberOfDependents),
                    propertyTaxs: parseFloat(propertyTaxs),
                    localTaxs: parseFloat(localTaxs),
                };
                
                const response = await api.put('/createUserProfile', userData);
                dispatch(loginSuccess(response.data));
                navigate('/home');
            }
        } catch (error) {
            console.error('Authentication error:', error);
            dispatch(loginFailure('Invalid credentials. Please try again.'));
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderAccountForm = () => (
        <Stack spacing={3}>
            <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                type="email"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EmailIcon color="action" />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                fullWidth
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LockIcon color="action" />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Stack>
    );

    const renderPersonalInfoForm = () => (
        <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                    fullWidth
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    fullWidth
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                    fullWidth
                    label="Current Age"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    required
                    type="number"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CakeIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    fullWidth
                    label="Retirement Age"
                    value={retirementAge}
                    onChange={(e) => setRetirementAge(e.target.value)}
                    required
                    type="number"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CakeIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            <FormControl fullWidth>
                <InputLabel>State of Residence</InputLabel>
                <Select
                    value={stateOfResidence}
                    onChange={(e) => setStateOfResidence(e.target.value)}
                    required
                    label="State of Residence"
                    startAdornment={
                        <InputAdornment position="start">
                            <LocationOnIcon color="action" />
                        </InputAdornment>
                    }
                >
                    {US_STATES.map((state) => (
                        <MenuItem key={state} value={state}>{state}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );

    const renderPreferencesForm = () => (
        <Stack spacing={3}>
            <Typography variant="h6" sx={{ mb: 1 }}>Tax Details</Typography>
            <FormControl fullWidth>
                <InputLabel>Tax Filing Status</InputLabel>
                <Select
                    value={taxFilingStatus}
                    onChange={(e) => setTaxFilingStatus(e.target.value)}
                    required
                    label="Tax Filing Status"
                >
                    <MenuItem value="Single">Single</MenuItem>
                    <MenuItem value="Married">Married</MenuItem>
                    <MenuItem value="Head of Household">Head of Household</MenuItem>
                    <MenuItem value="Married filing separately">Married Filing Separately</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel>Deduction Type</InputLabel>
                <Select
                    value={deductionType}
                    onChange={(e) => setDeductionType(e.target.value)}
                    required
                    label="Deduction Type"
                >
                    <MenuItem value="Standard">Standard</MenuItem>
                    <MenuItem value="Itemized">Itemized</MenuItem>
                </Select>
            </FormControl>
            <TextField
                fullWidth
                label="Number of Dependents"
                value={numberOfDependents}
                onChange={(e) => setNumberOfDependents(e.target.value)}
                required
                type="number"
                InputProps={{
                    inputProps: { min: 0 }
                }}
            />
            <TextField
                fullWidth
                label="Itemized Deductions"
                value={itemizedDeductions}
                onChange={(e) => setItemizedDeductions(e.target.value)}
                required
                type="number"
                disabled={deductionType === 'Standard'}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputProps: { min: 0 }
                }}
            />
            <TextField
                fullWidth
                label="Property Taxes"
                value={propertyTaxs}
                onChange={(e) => setPropertyTaxs(e.target.value)}
                required
                type="number"
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputProps: { min: 0 }
                }}
            />
            <TextField
                fullWidth
                label="Local Taxes"
                value={localTaxs}
                onChange={(e) => setLocalTaxs(e.target.value)}
                required
                type="number"
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputProps: { min: 0 }
                }}
            />
        </Stack>
    );

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return renderAccountForm();
            case 1:
                return renderPersonalInfoForm();
            case 2:
                return renderPreferencesForm();
            default:
                return 'Unknown step';
        }
    };

    return (
        <Container maxWidth="sm">
            <Box 
                sx={{ 
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    py: 4,
                }}
            >
                <Fade in timeout={800}>
                    <Paper 
                        elevation={theme.palette.mode === 'dark' ? 2 : 1}
                        sx={{
                            p: { xs: 3, sm: 4 },
                            width: '100%',
                            borderRadius: 3,
                            background: theme.palette.mode === 'dark'
                                ? alpha(theme.palette.background.paper, 0.8)
                                : theme.palette.background.paper,
                            backdropFilter: 'blur(10px)',
                            border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                            boxShadow: theme.palette.mode === 'dark'
                                ? `0 4px 20px 0 ${alpha(theme.palette.common.black, 0.3)}`
                                : `0 8px 32px 0 ${alpha(theme.palette.primary.main, 0.1)}`,
                        }}
                    >
                        <Box sx={{ mb: 4, textAlign: 'center' }}>
                            
                            <Typography 
                                variant="h4" 
                                component="h1"
                                fontWeight={700}
                                gutterBottom
                                sx={{
                                    background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    textShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.1)}`,
                                }}
                            >
                                {isLogin ? 'Welcome Back!' : 'Create Account'}
                            </Typography>
                            <Typography 
                                variant="body1" 
                                color="text.secondary"
                                sx={{ maxWidth: 300, mx: 'auto' }}
                            >
                                {isLogin 
                                    ? 'Sign in to access your financial dashboard'
                                    : 'Get started with your financial planning journey'
                                }
                            </Typography>
                        </Box>

                        {error && (
                            <Zoom in>
                                <Alert 
                                    severity="error" 
                                    sx={{ 
                                        mb: 3,
                                        borderRadius: 2,
                                        boxShadow: `0 2px 8px ${alpha(theme.palette.error.main, 0.15)}`,
                                    }}
                                    onClose={() => dispatch(loginFailure(error))}
                                >
                                    {error}
                                </Alert>
                            </Zoom>
                        )}

                        {!isLogin && (
                            <Stepper 
                                activeStep={activeStep} 
                                alternativeLabel
                                sx={{ 
                                    mb: 4,
                                    '& .MuiStepLabel-root .Mui-completed': {
                                        color: theme.palette.success.main,
                                    },
                                    '& .MuiStepLabel-root .Mui-active': {
                                        color: theme.palette.primary.main,
                                    },
                                }}
                            >
                                {SIGNUP_STEPS.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        )}

                        <form onSubmit={handleSubmit}>
                            <Box sx={{ mb: 4 }}>
                                {isLogin ? renderAccountForm() : getStepContent(activeStep)}
                            </Box>

                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                gap: 2,
                                mb: 3
                            }}>
                                {!isLogin && activeStep > 0 && (
                                    <Button
                                        onClick={handleBack}
                                        variant="outlined"
                                        sx={{ 
                                            flex: 1,
                                            py: 1.5,
                                            borderRadius: 2,
                                            borderWidth: 2,
                                            '&:hover': {
                                                borderWidth: 2,
                                            }
                                        }}
                                    >
                                        Back
                                    </Button>
                                )}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth={isLogin || activeStep === 0}
                                    sx={{ 
                                        flex: isLogin || activeStep === 0 ? 'none' : 1,
                                        py: 1.5,
                                        borderRadius: 2,
                                        boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
                                        background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                                        transition: 'all 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'translateY(-1px)',
                                            boxShadow: `0 6px 16px ${alpha(theme.palette.primary.main, 0.3)}`,
                                        },
                                        '&:active': {
                                            transform: 'translateY(1px)',
                                        }
                                    }}
                                    disabled={isSubmitting && (isLogin || activeStep === SIGNUP_STEPS.length - 1)}
                                >
                                    {isSubmitting && (isLogin || activeStep === SIGNUP_STEPS.length - 1) ? (
                                        <CircularProgress 
                                            size={24} 
                                            sx={{ 
                                                color: theme.palette.common.white,
                                                opacity: 0.9
                                            }} 
                                        />
                                    ) : isLogin ? (
                                        'Sign In'
                                    ) : activeStep === SIGNUP_STEPS.length - 1 ? (
                                        'Create Account'
                                    ) : (
                                        'Next'
                                    )}
                                </Button>
                            </Box>
                        </form>

                        <Divider 
                            sx={{ 
                                my: 3,
                                '&::before, &::after': {
                                    borderColor: alpha(theme.palette.divider, 0.1),
                                },
                            }} 
                        >
                            <Typography 
                                variant="body2" 
                                color="text.secondary"
                                sx={{ px: 2 }}
                            >
                                or
                            </Typography>
                        </Divider>

                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="body2" color="text.secondary">
                                {isLogin ? "Don't have an account? " : 'Already have an account? '}
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        setIsLogin(!isLogin);
                                        setActiveStep(0);
                                    }}
                                    sx={{ 
                                        fontWeight: 600,
                                        color: theme.palette.primary.main,
                                        textDecoration: 'none',
                                        transition: 'all 0.2s ease-in-out',
                                        '&:hover': {
                                            color: theme.palette.primary.dark,
                                            textDecoration: 'none',
                                            transform: 'translateY(-1px)',
                                        },
                                    }}
                                >
                                    {isLogin ? 'Sign Up' : 'Sign In'}
                                </Link>
                            </Typography>
                        </Box>
                    </Paper>
                </Fade>
            </Box>
        </Container>
    );
};

export default HomePage;
