import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Container,
    Grid,
    Box,
    Typography,
    Paper,
    useTheme,
    Fade,
    Grow,
    useMediaQuery,
    Skeleton,
    alpha,
} from '@mui/material';
import { motion } from 'framer-motion';
import InputForm from '../InputForm';
import OneTimeExpenseForm from '../OneTimeExpenseForm';
import ProjectionsTable from '../ProjectionsTable';
import ProjectionsChart from '../ProjectionsChart';
import { RootState } from '../../store/index';

// Styled components
const MotionPaper = motion(Paper);

const DashboardCard: React.FC<{
    children: React.ReactNode;
    timeout: number;
    title?: string;
    titleColor?: string;
}> = ({ children, timeout, title, titleColor = 'primary.main' }) => {
    const theme = useTheme();
    
    return (
        <Grow in timeout={timeout}>
            <MotionPaper
                elevation={0}
                // component={motion.div}
                whileHover={{ y: -4 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                sx={{
                    p: { xs: 2, sm: 3 },
                    height: '100%',
                    borderRadius: 2,
                    background: alpha(theme.palette.background.paper, 0.8),
                    backdropFilter: 'blur(10px)',
                    border: '1px solid',
                    borderColor: 'divider',
                    boxShadow: theme.shadows[2],
                    '&:hover': {
                        boxShadow: theme.shadows[4],
                    },
                }}
            >
                {title && (
                    <Typography
                        variant="h6"
                        component="h2"
                        sx={{
                            mb: 3,
                            fontWeight: 500,
                            color: titleColor,
                            fontSize: { xs: '1.1rem', sm: '1.25rem' },
                        }}
                    >
                        {title}
                    </Typography>
                )}
                {children}
            </MotionPaper>
        </Grow>
    );
};

const Dashboard: React.FC = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const { userInputs, oneTimeExpenses } = useSelector((state: RootState) => state.userData);
    const userProfile = useSelector((state: RootState) => state.userData.userProfile);

    
    return (
        <Container maxWidth="xl">
            <Box
                component={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                sx={{
                    width: '100%',
                    minHeight: '100vh',
                    py: { xs: 2, sm: 3, md: 4 },
                    px: { xs: 1, sm: 2, md: 4 },
                }}
            >
                <Fade in timeout={800}>
                    <Box>
                        <Typography
                            variant="h4"
                            component={motion.h1}
                            initial={{ y: -20 }}
                            animate={{ y: 0 }}
                            sx={{
                                mb: { xs: 2, sm: 3, md: 4 },
                                fontWeight: 600,
                                fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
                                textAlign: { xs: 'center', sm: 'left' },
                                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                letterSpacing: '-0.5px',
                            }}
                        >
                            Financial Planner
                        </Typography>

                        <Grid container spacing={{ xs: 2, sm: 3 }}>
                            <Grid item xs={12} md={6}>
                                <DashboardCard
                                    timeout={1000}
                                    title="Income & Expenses"
                                    titleColor="primary.main"
                                >
                                    <InputForm />
                                </DashboardCard>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DashboardCard
                                    timeout={1200}
                                    title="One-Time Expenses"
                                    titleColor="secondary.main"
                                >
                                    <OneTimeExpenseForm />
                                </DashboardCard>
                            </Grid>

                            <Grid item xs={12}>
                                <DashboardCard timeout={1400}>
                                    <ProjectionsChart />
                                </DashboardCard>
                            </Grid>

                            <Grid item xs={12}>
                                <DashboardCard timeout={1600}>
                                    <ProjectionsTable />
                                </DashboardCard>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Box>
        </Container>
    );
};

export default React.memo(Dashboard);
