import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { 
    Button, 
    Box, 
    Tooltip, 
    useTheme,
    Zoom,
} from '@mui/material';
import { 
    AccountBalance as AccountBalanceIcon,
} from '@mui/icons-material';
import NetSavingsAllocation from './NetSavingsAllocation';

const allCategories = [
    'Cash',
    'Checking accounts',
    'Savings accounts',
    'Money Market accounts',
    'Certificates of Deposit (CDs)',
    'Treasury bills (T-bills)',
    'Stocks',
    'Bonds',
    'Gold',
    'Commodities',
    'Cryptocurrency',
    'Private investments',
    'Other Asset'
];

const ManageSavingsButton: React.FC = () => {
    const theme = useTheme();
    const [savingsAllocationOpen, setSavingsAllocationOpen] = useState(false);
    const savingsAllocations = useSelector((state: RootState) => state.userData.savingsAllocations);

    const handleSavingsAllocationClose = () => {
        setSavingsAllocationOpen(false);
    };

    return (
        <Box>
            <Zoom in={true} timeout={500}>
                <Tooltip 
                    title="Configure how your savings are allocated across different investment categories"
                    arrow
                    placement="top"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setSavingsAllocationOpen(true)}
                        startIcon={<AccountBalanceIcon />}
                        sx={{
                            mt: 2,
                            px: 3,
                            py: 1,
                            borderRadius: 2,
                            textTransform: 'none',
                            fontWeight: 600,
                            boxShadow: theme.shadows[2],
                            '&:hover': {
                                boxShadow: theme.shadows[4],
                                transform: 'translateY(-1px)',
                            },
                            transition: theme.transitions.create(
                                ['transform', 'box-shadow'],
                                { duration: 200 }
                            ),
                        }}
                    >
                        Manage Savings Allocation
                    </Button>
                </Tooltip>
            </Zoom>

            <NetSavingsAllocation
                open={savingsAllocationOpen}
                onClose={handleSavingsAllocationClose}
                initialAllocations={savingsAllocations}
                allCategories={allCategories}
            />
        </Box>
    );
};

export default ManageSavingsButton;
