import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { addBalanceItem, removeBalanceItem, updateBalanceItem } from '../../store/userDataSlice';
import dayjs from 'dayjs';
import api from '../../services/api';
import {
    Container,
    Typography,
    Paper,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tab,
    useTheme,
    useMediaQuery,
    Skeleton,
    alpha,
} from '@mui/material';
import { motion } from 'framer-motion';
import { DataGrid, GridColDef, GridToolbarContainer, GridRowModesModel, GridRowModes, GridEventListener, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { BalanceSheetType,
         LiquidAssetCategory,
         InvestmentCategory, 
         PropertyCategory, 
         PersonalAssetCategory, 
         OtherAssetCategory, 
         ShortTermLiabilityCategory, 
         LongTermLiabilityCategory, 
         BalanceSheetLineItem, 
         MonthlyBalanceProjection
        } from '../../types';
import { v4 as uuidv4 } from 'uuid';
import ManageSavingsButton from '../ManageSavingsButton';
import CollapsibleSection from '../CollapsibleSection';
import { formatCurrency, formatPercentage, formatDate } from '../../utils/formattingFunctions';
import { addListOfLists } from '../../utils/aggregationFunctions';

// Styled components
const StyledPaper = motion(Paper);

const SummaryCard: React.FC<{
    title: string;
    amount: number;
    color?: string;
}> = ({ title, amount, color = 'text.primary' }) => {
    const theme = useTheme();
    
    return (
        <StyledPaper
            elevation={0}
            
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            sx={{
                p: 3,
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                bgcolor: 'background.paper',
                '&:hover': {
                    boxShadow: theme.shadows[4],
                    transform: 'translateY(-4px)',
                    transition: 'all 0.3s ease-in-out'
                }
            }}
        >
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                {title}
            </Typography>
            <Typography variant="h4" color={color} fontWeight="bold">
                {formatCurrency(amount)}
            </Typography>
        </StyledPaper>
    );
};

const BalanceSheet: React.FC = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const { currentSheet, savingsAllocations, monthlyBalanceProjections } = useSelector((state: RootState) => state.userData);

    const [itemType, setItemType] = useState<BalanceSheetType>('Liquid Assets');
    const [category, setCategory] = useState<LiquidAssetCategory>('Cash');
    const [activeTab, setActiveTab] = useState(0);
    const [openSections, setOpenSections] = useState({
        currentAssets: false,
        longTermAssets: false,
        currentLiabilities: false,
        longTermLiabilities: false
    });
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const toggleSection = useCallback((section: 'currentAssets' | 'longTermAssets' | 'currentLiabilities' | 'longTermLiabilities') => {
        setOpenSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    }, []);

    const getProjectedAmount = useCallback((category: (LiquidAssetCategory | InvestmentCategory | PropertyCategory | PersonalAssetCategory | OtherAssetCategory | ShortTermLiabilityCategory | LongTermLiabilityCategory), projection: MonthlyBalanceProjection) => {
        switch (category) {
            case 'Cash': return projection.cash;
            case 'Checking accounts': return projection.checkingAccount;
            case 'Savings accounts': return projection.savingsAccount;
            case 'Money market accounts': return projection.moneyMarketAccount;
            case 'Certificates of deposit (CDs)': return projection.certificateOfDeposit;
            case 'Treasury bills (T-bills)': return projection.treasuryBill;
            case 'Stocks': return projection.stock;
            case 'Bonds': return projection.bond;
            case 'Gold': return projection.gold;
            case 'Commodities': return projection.commodity;
            case 'Cryptocurrency': return projection.cryptocurrency;
            case 'Private investments': return projection.privateInvestment;
            case 'Primary residence': return projection.primaryResidence;
            case 'Vacation homes': return projection.vacationHome;
            case 'Rental properties': return projection.rentalProperty;
            case 'Land': return projection.land;
            case 'Timeshares': return projection.timeshare;
            case 'Commercial real estate': return projection.commercialRealEstate;
            case 'Vehicles': return projection.vehicle;
            case 'Boats': return projection.boat;
            case 'Recreational vehicles': return projection.recreationalVehicle;
            case 'Jewelry': return projection.jewelry;
            case 'Furniture': return projection.furniture;
            case 'Art and collectibles': return projection.artAndCollectibles;
            case 'Business ownership interest': return projection.businessOwnershipInterest;
            case 'Life insurance': return projection.lifeInsurance;
            case 'Other asset': return projection.otherAsset;
            case 'Credit card debt': return projection.creditCardDebt;
            case 'Payday loans': return projection.paydayLoan;
            case 'Buy now, pay later balances': return projection.buyNowPayLaterBalance;
            case 'Medical bills': return projection.medicalBills;
            case 'Outstanding utility bills': return projection.outstandingUtilityBills;
            case 'Student loans': return projection.studentLoan;
            case 'Mortgage': return projection.mortgage;
            case 'Auto loans': return projection.autoLoan;
            case 'Personal loans': return projection.personalLoan;
            case 'Home equity loans': return projection.homeEquityLoan;
            case 'Other loans': return projection.otherLoan;
            case 'Tax debt': return projection.taxDebt;
            case 'Legal settlements': return projection.legalSettlement;
            default: return 0;
        }
    }, []);

    // Memoized values
    const rows = useMemo(() => currentSheet.lineItems.map(item => ({
        ...item,
        type: item.type || 'N/A',
        category: item.category || 'N/A'
    })), [currentSheet.lineItems]);

    // Event handlers with useCallback
    const handleRowEditStop = useCallback<GridEventListener<'rowEditStop'>>((params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    }, []);

    const handleEditClick = useCallback((id: string) => () => {
        setRowModesModel(prev => ({ ...prev, [id]: { mode: GridRowModes.Edit } }));
    }, []);

    const handleSaveClick = useCallback((id: string) => () => {
        setRowModesModel(prev => ({ ...prev, [id]: { mode: GridRowModes.View } }));
    }, []);

    const handleDeleteClick = useCallback((id: string) => async () => {
        try {
            await api.delete(`/deleteBalanceSheetItem/${id}`);
            dispatch(removeBalanceItem(id));
        } catch (error) {
            console.error('Error deleting balance sheet item:', error);
        }
    }, [dispatch]);

    const handleCancelClick = useCallback((id: string) => async () => {
        setRowModesModel(prev => ({
            ...prev,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        }));

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.isNew) {
            try {
                await api.delete(`/deleteBalanceSheetItem/${id}`);
                dispatch(removeBalanceItem(id));
            } catch (error) {
                console.error('Error deleting balance sheet item:', error);
            }
        }
    }, [dispatch, rows]);

    const processRowUpdate = useCallback(async (newRow: BalanceSheetLineItem) => {
        try {
            const response = await api.put(`/updateBalanceSheetItem/${newRow.id}`, 
                { ...newRow, isNew: false }
            );
            const updatedRow = response.data;
            dispatch(updateBalanceItem(updatedRow));
            return updatedRow;
        } catch (error) {
            console.error('Error updating balance sheet item:', error);
            throw error;
        }
    }, [dispatch]);

    // Custom toolbar component
    const EditToolbar = useCallback(() => {
        const handleClick = async () => {
            const id = uuidv4();
            const newItem = {
                id: id,
                description: '',
                amount: 0,
                type: itemType,
                category: category,
                returnRate: 0,
                isNew: true
            };
            
            try {
                const response = await api.post('/addBalanceSheetItem', newItem);
                const savedItem = response.data;
                dispatch(addBalanceItem(savedItem));
                setRowModesModel((oldModel) => ({
                    ...oldModel,
                    [id]: { mode: GridRowModes.Edit, fieldToFocus: 'description' },
                }));
            } catch (error) {
                console.error('Error adding balance sheet item:', error);
            }
        };

        return (
            <GridToolbarContainer>
                <Button
                    component={motion.button}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleClick}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        borderRadius: 2,
                        px: 3,
                        background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        '&:hover': {
                            background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`,
                        }
                    }}
                >
                    Add Line Item
                </Button>
            </GridToolbarContainer>
        );
    }, [dispatch, itemType, category, theme]);

    // DataGrid columns configuration
    const columns: GridColDef[] = useMemo(() => [
        { 
            field: 'description', 
            headerName: 'Description', 
            flex: 1,
            minWidth: 150,
            editable: true
        },
        { 
            field: 'amount', 
            headerName: 'Amount', 
            type: 'number',
            width: isMobile ? 120 : 150,
            editable: true,
            preProcessEditCellProps: (params) => ({
                ...params.props,
                error: params.props.value < 0 ? 'Amount cannot be negative' : null
            }),
            renderCell: (params) => (
                <Typography sx={{ fontWeight: 'bold' }}>
                    {formatCurrency(params.value)}
                </Typography>
            )
        },
        { 
            field: 'returnRate', 
            headerName: 'Return Rate', 
            type: 'number',
            width: isMobile ? 100 : 130,
            editable: true,
            preProcessEditCellProps: (params) => ({
                ...params.props,
                error: params.props.value < 0 ? 'Return rate cannot be negative' : null
            }),
            renderCell: (params) => (
                <Typography sx={{ fontWeight: 'medium' }}>
                    {formatPercentage(params.value)}
                </Typography>
            )
        },
        {
            field: 'type',
            headerName: 'Type',
            width: isMobile ? 150 : 180,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['Liquid Assets', 'Investments', 'Property', 'Personal Assets', 'Other Assets', 'Short-term Liabilities', 'Long-term Liabilities']
        },
        {
            field: 'category',
            headerName: 'Category',
            width: isMobile ? 170 : 200,
            editable: true,
            type: 'singleSelect',
            valueOptions: ({ row }) => {
                switch (row.type) {
                    case 'Liquid Assets':
                        return ['Cash', 'Checking accounts', 'Savings accounts', 'Money market accounts', 'Certificates of deposit (CDs)', 'Treasury bills (T-bills)'];
                    case 'Investments':
                        return ['Stocks', 'Bonds', 'Gold', 'Commodities', 'Cryptocurrency', 'Private investments'];
                    case 'Property':
                        return ['Primary residence', 'Vacation homes', 'Rental properties', 'Land', 'Timeshares', 'Commercial real estate'];
                    case 'Personal Assets':
                        return ['Vehicles', 'Boats', 'Recreational vehicles', 'Jewelry', 'Furniture', 'Art and collectibles'];
                    case 'Other Assets':
                        return ['Business ownership interest', 'Life insurance', 'Other asset'];
                    case 'Short-term Liabilities':
                        return ['Credit card debt', 'Payday loans', 'Buy now, pay later balances', 'Medical bills', 'Outstanding utility bills'];
                    case 'Long-term Liabilities':
                        return ['Mortgage', 'Student loans', 'Auto loans', 'Personal loans', 'Home equity loans', 'Other loans', 'Tax debt', 'Legal settlements', 'Other liabilities'];
                    default:
                        return [];
                }
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                const string_id = id.toString();
                
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(string_id)}
                            sx={{ color: 'success.main' }}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            onClick={handleCancelClick(string_id)}
                            sx={{ color: 'error.main' }}
                        />
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={handleEditClick(string_id)}
                        sx={{ color: 'primary.main' }}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(string_id)}
                        sx={{ color: 'error.main' }}
                    />
                ];
            }
        }
    ], [handleSaveClick, handleCancelClick, handleEditClick, handleDeleteClick, isMobile, rowModesModel]);

    return (
        <Container maxWidth="xl">
            <Box
                component={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                sx={{
                    width: '100%',
                    py: { xs: 2, sm: 3, md: 4 },
                    px: { xs: 1, sm: 2, md: 3 }
                }}
            >
                <StyledPaper
                    elevation={0}
                    sx={{
                        borderRadius: 3,
                        overflow: 'hidden',
                        bgcolor: 'background.paper',
                        border: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    {/* Header Section */}
                    <Box
                        sx={{
                            p: { xs: 2, sm: 3, md: 4 },
                            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            color: 'white',
                            mb: 2
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: { xs: 'flex-start', sm: 'center' },
                                justifyContent: 'space-between',
                                gap: 2
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="h4"
                                    component={motion.h1}
                                    initial={{ y: -20 }}
                                    animate={{ y: 0 }}
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
                                        mb: 1
                                    }}
                                >
                                    Balance Sheet
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        opacity: 0.9,
                                        fontSize: { xs: '0.875rem', sm: '1rem' }
                                    }}
                                >
                                    Track and manage your assets and liabilities
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                                width: { xs: '100%', sm: 'auto' }
                            }}>
                                <ManageSavingsButton />
                            </Box>
                        </Box>
                    </Box>

                    {/* Summary Cards */}
                    <Box
                        sx={{
                            px: { xs: 2, sm: 3, md: 4 },
                            mb: 4,
                            display: 'grid',
                            gap: 3,
                            gridTemplateColumns: {
                                xs: '1fr',
                                sm: 'repeat(2, 1fr)',
                                md: 'repeat(3, 1fr)'
                            }
                        }}
                    >
                        <SummaryCard
                            title="Total Assets"
                            amount={monthlyBalanceProjections[0]?.totalAssets || 0}
                            color="success.main"
                        />
                        <SummaryCard
                            title="Total Liabilities"
                            amount={monthlyBalanceProjections[0]?.totalLiabilities || 0}
                            color="error.main"
                        />
                        <SummaryCard
                            title="Net Worth"
                            amount={(monthlyBalanceProjections[0]?.netWorth || 0)}
                            color="primary.main"
                        />
                    </Box>

                    {/* DataGrid and Monthly Projections Tabs */}
                    <Box sx={{ px: { xs: 2, sm: 3, md: 4 }, pb: 4 }}>
                        <Tabs
                            value={activeTab}
                            onChange={(_, newValue) => setActiveTab(newValue)}
                            sx={{
                                mb: 3,
                                borderBottom: 1,
                                borderColor: 'divider',
                                '& .MuiTab-root': {
                                    textTransform: 'none',
                                    fontWeight: 600,
                                    fontSize: { xs: '0.875rem', sm: '1rem' },
                                    minHeight: 48,
                                }
                            }}
                        >
                            <Tab label="Accounts" />
                            <Tab label="Monthly Projections" />
                        </Tabs>

                        {activeTab === 0 && (
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                editMode="row"
                                rowModesModel={rowModesModel}
                                onRowModesModelChange={setRowModesModel}
                                onRowEditStop={handleRowEditStop}
                                processRowUpdate={processRowUpdate}
                                slots={{ toolbar: EditToolbar }}
                                sx={{
                                    border: 'none',
                                    '& .MuiDataGrid-cell': {
                                        borderColor: 'divider',
                                    },
                                    '& .MuiDataGrid-columnHeaders': {
                                        bgcolor: 'background.default',
                                        borderColor: 'divider',
                                    },
                                    '& .MuiDataGrid-row:hover': {
                                        bgcolor: alpha(theme.palette.primary.main, 0.04),
                                    },
                                }}
                            />
                        )}

                        {activeTab === 1 && monthlyBalanceProjections.length > 0 && (
                            <TableContainer
                                component={motion.div}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                sx={{
                                    overflowX: 'auto',
                                    '& .MuiTableCell-root': {
                                        borderColor: 'divider',
                                        whiteSpace: 'nowrap',
                                    },
                                }}
                            >
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox" />
                                            <TableCell sx={{ 
                                                fontWeight: 600,
                                                left: 0,
                                                bgcolor: 'background.paper',
                                            }}>
                                                Account
                                            </TableCell>
                                            {monthlyBalanceProjections.map((projection) => (
                                                <TableCell
                                                    key={dayjs(projection.month).toISOString()}
                                                    align="right"
                                                    sx={{ fontWeight: 600 }}
                                                >
                                                    {formatDate(dayjs(projection.month))}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Net Worth Row */}
                                        <TableRow sx={{
                                            bgcolor: alpha(theme.palette.primary.main, 0.1),
                                            '&:hover': {
                                                bgcolor: alpha(theme.palette.primary.main, 0.15),
                                            }
                                        }}>
                                            <TableCell />
                                            <TableCell sx={{ 
                                                bgcolor: alpha(theme.palette.primary.main, 0.1),
                                            }}>
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    Net Worth
                                                </Typography>
                                            </TableCell>
                                            {monthlyBalanceProjections.map((projection) => (
                                                <TableCell
                                                    key={dayjs(projection.month).toISOString()}
                                                    align="right"
                                                    sx={{ fontWeight: 'bold' }}
                                                >
                                                    {formatCurrency(projection.netWorth)}
                                                </TableCell>
                                            ))}
                                        </TableRow>

                                        {/* Total Assets Row */}
                                        <TableRow sx={{
                                            bgcolor: alpha(theme.palette.success.main, 0.1),
                                            '&:hover': {
                                                bgcolor: alpha(theme.palette.success.main, 0.15),
                                            }
                                        }}>
                                            <TableCell />
                                            <TableCell sx={{ 
                                                bgcolor: alpha(theme.palette.success.main, 0.05),
                                            }}>
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    Total Assets
                                                </Typography>
                                            </TableCell>
                                            {monthlyBalanceProjections.map((projection) => (
                                                <TableCell
                                                    key={dayjs(projection.month).toISOString()}
                                                    align="right"
                                                    sx={{ fontWeight: 'bold', color: 'success.main' }}
                                                >
                                                    {formatCurrency(projection.totalAssets)}
                                                </TableCell>
                                            ))}
                                        </TableRow>

                                        {/* Current Assets Section */}
                                        <CollapsibleSection
                                            title="Current Assets"
                                            isOpen={openSections.currentAssets}
                                            onToggle={() => toggleSection('currentAssets')}
                                            content={
                                                <Table size="small">
                                                    <TableBody>
                                                        {(['Cash', 'Checking accounts', 'Savings accounts', 'Money market accounts', 'Certificates of deposit', 'Other liquid assets'] as LiquidAssetCategory[])
                                                            .filter(category =>
                                                                currentSheet.lineItems.some(item =>
                                                                    item.category === category
                                                                ) || savingsAllocations.some(allocation =>
                                                                    allocation.category === category
                                                                )
                                                            )
                                                            .map(category => (
                                                                <TableRow key={category}>
                                                                    <TableCell />
                                                                    <TableCell sx={{ 
                                                                        left: 0,
                                                                        bgcolor: 'background.paper',
                                                                    }}>
                                                                        {category}
                                                                    </TableCell>
                                                                    {monthlyBalanceProjections.map(projection => (
                                                                        <TableCell
                                                                            key={dayjs(projection.month).toISOString()}
                                                                            align="right"
                                                                            sx={{ color: 'success.main' }}
                                                                        >
                                                                            {formatCurrency(getProjectedAmount(category, projection))}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            }
                                            months={monthlyBalanceProjections.map(p => p.month)}
                                            monthlyTotals={monthlyBalanceProjections.map(p => p.liquidAssets)}
                                            formatCurrency={formatCurrency}
                                        />

                                        {/* Long Term Assets Section */}
                                        <CollapsibleSection
                                            title="Long Term Assets"
                                            isOpen={openSections.longTermAssets}
                                            onToggle={() => toggleSection('longTermAssets')}
                                            content={
                                                <Table size="small">
                                                    <TableBody>
                                                        {(['Stocks', 'Bonds', 'Gold', 'Commodities', 'Cryptocurrency', 'Private investments', 'Primary residence', 'Vacation homes', 'Rental properties', 'Land', 'Timeshares', 'Commercial real estate', 'Vehicles', 'Boats', 'Recreational vehicles', 'Jewelry', 'Furniture', 'Art and collectibles', 'Business ownership interest', 'Life insurance', 'Other asset'] as (InvestmentCategory | PropertyCategory | PersonalAssetCategory | OtherAssetCategory)[])
                                                            .filter(category =>
                                                                currentSheet.lineItems.some(item =>
                                                                    (item.type === 'Investments' || item.type === 'Property' || item.type === 'Personal Assets' || item.type === 'Other Assets') && item.category === category
                                                                ) || savingsAllocations.some(allocation =>
                                                                    allocation.category === category
                                                                )
                                                            )
                                                            .map(category => (
                                                                <TableRow key={category}>
                                                                    <TableCell />
                                                                    <TableCell sx={{ 
                                                                        bgcolor: 'background.paper',
                                                                    }}>
                                                                        {category}
                                                                    </TableCell>
                                                                    {monthlyBalanceProjections.map(projection => (
                                                                        <TableCell
                                                                            key={dayjs(projection.month).toISOString()}
                                                                            align="right"
                                                                            sx={{ color: 'success.main' }}
                                                                        >
                                                                            {formatCurrency(getProjectedAmount(category, projection))}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            }
                                            months={monthlyBalanceProjections.map(p => p.month)}
                                            monthlyTotals={addListOfLists([
                                                monthlyBalanceProjections.map(p => p.investments),
                                                monthlyBalanceProjections.map(p => p.property),
                                                monthlyBalanceProjections.map(p => p.personalAssets),
                                                monthlyBalanceProjections.map(p => p.otherAssets)
                                            ])}
                                            formatCurrency={formatCurrency}
                                        />

                                        {/* Total Liabilities Row */}
                                        <TableRow sx={{
                                            bgcolor: alpha(theme.palette.error.main, 0.05),
                                            '&:hover': {
                                                bgcolor: alpha(theme.palette.error.main, 0.1),
                                            }
                                        }}>
                                            <TableCell />
                                            <TableCell sx={{ 
                                                bgcolor: alpha(theme.palette.error.main, 0.1),
                                            }}>
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    Total Liabilities
                                                </Typography>
                                            </TableCell>
                                            {monthlyBalanceProjections.map((projection) => (
                                                <TableCell
                                                    key={dayjs(projection.month).toISOString()}
                                                    align="right"
                                                    sx={{ fontWeight: 'bold', color: 'error.main' }}
                                                >
                                                    {formatCurrency(projection.totalLiabilities)}
                                                </TableCell>
                                            ))}
                                        </TableRow>

                                        {/* Current Liabilities Section */}
                                        <CollapsibleSection
                                            title="Current Liabilities"
                                            isOpen={openSections.currentLiabilities}
                                            onToggle={() => toggleSection('currentLiabilities')}
                                            content={
                                                <Table size="small">
                                                    <TableBody>
                                                        {(['Credit card debt', 'Payday loans', 'Buy now, pay later balances', 'Medical bills', 'Outstanding utility bills'] as ShortTermLiabilityCategory[])
                                                            .filter(category =>
                                                                currentSheet.lineItems.some(item =>
                                                                    item.category === category
                                                                )
                                                            )
                                                            .map(category => (
                                                                <TableRow key={category}>
                                                                    <TableCell />
                                                                    <TableCell sx={{ 
                                                                        bgcolor: 'background.paper',
                                                                    }}>
                                                                        {category}
                                                                    </TableCell>
                                                                    {monthlyBalanceProjections.map(projection => (
                                                                        <TableCell
                                                                            key={dayjs(projection.month).toISOString()}
                                                                            align="right"
                                                                            sx={{ color: 'error.main' }}
                                                                        >
                                                                            {formatCurrency(getProjectedAmount(category, projection))}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            }
                                            months={monthlyBalanceProjections.map(p => p.month)}
                                            monthlyTotals={monthlyBalanceProjections.map(p => p.shortTermLiabilities)}
                                            formatCurrency={formatCurrency}
                                            
                                        />

                                        {/* Long Term Liabilities Section */}
                                        <CollapsibleSection
                                            title="Long Term Liabilities"
                                            isOpen={openSections.longTermLiabilities}
                                            onToggle={() => toggleSection('longTermLiabilities')}
                                            content={
                                                <Table size="small">
                                                    <TableBody>
                                                        {(['Student loans', 'Mortgage', 'Auto loans', 'Personal loans', 'Home equity loans', 'Other liabilities', 'Tax debt', 'Legal settlements'] as LongTermLiabilityCategory[])
                                                            .filter(category =>
                                                                currentSheet.lineItems.some(item =>
                                                                    item.category === category
                                                                )
                                                            )
                                                            .map(category => (
                                                                <TableRow key={category}>
                                                                    <TableCell />
                                                                    <TableCell sx={{ 
                                                                        bgcolor: 'background.paper',
                                                                    }}>
                                                                        {category}
                                                                    </TableCell>
                                                                    {monthlyBalanceProjections.map(projection => (
                                                                        <TableCell
                                                                            key={dayjs(projection.month).toISOString()}
                                                                            align="right"
                                                                            sx={{ color: 'error.main' }}
                                                                        >
                                                                            {formatCurrency(getProjectedAmount(category, projection))}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            }
                                            months={monthlyBalanceProjections.map(p => p.month)}
                                            monthlyTotals={monthlyBalanceProjections.map(p => p.longTermLiabilities)}
                                            formatCurrency={formatCurrency}
                                            
                                        />

                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>
                </StyledPaper>
            </Box>
        </Container>
    );
};

export default React.memo(BalanceSheet);
