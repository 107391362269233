import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IncomeStatement, LineItem, MonthlyProjection, savingsAllocation, BalanceSheetLineItem, BalanceSheet, MonthlyBalanceProjection, UserProfile, UserInputs, OneTimeExpense, FinancialProjection } from '../types';
import { generateMonthlyBalanceProjections } from '../utils/balanceSheetCalculations'
import { generateMonthlyIncomeProjection, generateMonthlyIncomeProjections } from '../utils/incomeStatementCalculations';
import { generateProjections } from '../utils/calculations';
import { v4 as uuidv4 } from 'uuid';

interface userData {
    currentStatement: IncomeStatement;
    monthlyIncomeProjections: MonthlyProjection[];
    savingsAllocations: savingsAllocation[];
    currentSheet: BalanceSheet;
    monthlyBalanceProjections: MonthlyBalanceProjection[];
    userProfile: UserProfile;
    userInputs: UserInputs;
    oneTimeExpenses: OneTimeExpense[];
    projections: FinancialProjection[];
}

const initialState: userData = {
    currentStatement: {
        lineItems: []
    },
    monthlyIncomeProjections: [],
    savingsAllocations: [{
        id: uuidv4(),
        category: 'Cash',
        percentage: 100
    }],
    currentSheet: {
        lineItems: []
    },
    monthlyBalanceProjections: [],
    userProfile: {
        firstName: '',
        lastName: '',
        email: '',
        age: 21,
        retirementAge: 65,
        stateOfResidence: '',
        taxFilingStatus: 'Single',
        deductionType: 'Standard',
        numberOfDependents: 0,
        itemizedDeductions: 0,
        propertyTaxs: 0,
        localTaxs: 0
    },
    userInputs: {
        id: '',
        startingCashBalance: 0,
        startingInvestmentBalance: 0,
        realWageGrowth: 2,
        realSpendingGrowth: 2,
        currentPreTaxIncome: 0,
        currentExpenses: 0,
        returnProfile: 'Normal'
    },
    oneTimeExpenses: [],
    projections: []
};

export const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        addLineItem: (state, action: PayloadAction<LineItem>) => {
            const newItem = action.payload;
            state.currentStatement.lineItems.push(newItem);
            
            // Generate income projections
            state.monthlyIncomeProjections = generateMonthlyIncomeProjections(
                state.currentStatement,
                state.userProfile.stateOfResidence,
                state.userProfile.taxFilingStatus,
                state.userProfile.deductionType,
                state.userProfile.propertyTaxs,
                state.userProfile.localTaxs,
                state.userProfile.numberOfDependents,
                state.userProfile.itemizedDeductions
            );

            // Generate balance sheet projections
            state.monthlyBalanceProjections = generateMonthlyBalanceProjections(
                state.currentSheet.lineItems,
                state.savingsAllocations,
                state.monthlyIncomeProjections
            );
        },
        removeLineItem: (state, action: PayloadAction<string>) => {
            const id = action.payload;
            state.currentStatement.lineItems = state.currentStatement.lineItems.filter(
                item => item.id !== id
            );
            // Generate income projections
            state.monthlyIncomeProjections = generateMonthlyIncomeProjections(
                state.currentStatement,
                state.userProfile.stateOfResidence,
                state.userProfile.taxFilingStatus,
                state.userProfile.deductionType,
                state.userProfile.propertyTaxs,
                state.userProfile.localTaxs,
                state.userProfile.numberOfDependents,
                state.userProfile.itemizedDeductions
            );

            // Generate balance sheet projections
            state.monthlyBalanceProjections = generateMonthlyBalanceProjections(
                state.currentSheet.lineItems,
                state.savingsAllocations,
                state.monthlyIncomeProjections
            );
        },
        updateLineItem: (state, action: PayloadAction<LineItem>) => {
            const updatedItem = action.payload;
            state.currentStatement.lineItems = state.currentStatement.lineItems.map(item => {
                if (item.id === updatedItem.id) {
                    return updatedItem;
                }
                return item;
            });
            // Generate income projections
            state.monthlyIncomeProjections = generateMonthlyIncomeProjections(
                state.currentStatement,
                state.userProfile.stateOfResidence,
                state.userProfile.taxFilingStatus,
                state.userProfile.deductionType,
                state.userProfile.propertyTaxs,
                state.userProfile.localTaxs,
                state.userProfile.numberOfDependents,
                state.userProfile.itemizedDeductions
            );

            // Generate balance sheet projections
            state.monthlyBalanceProjections = generateMonthlyBalanceProjections(
                state.currentSheet.lineItems,
                state.savingsAllocations,
                state.monthlyIncomeProjections
            );
        },
        saveSavingsAllocations: (state, action: PayloadAction<savingsAllocation[]>) => {
            state.savingsAllocations = action.payload;
            // Generate balance sheet projections
            state.monthlyBalanceProjections = generateMonthlyBalanceProjections(
                state.currentSheet.lineItems,
                state.savingsAllocations,
                state.monthlyIncomeProjections
            );
        },
        addBalanceItem(state, action: PayloadAction<BalanceSheetLineItem>) {
            const balanceItem = action.payload;
            state.currentSheet.lineItems.push(balanceItem);
            // Generate balance sheet projections
            state.monthlyBalanceProjections = generateMonthlyBalanceProjections(
                state.currentSheet.lineItems,
                state.savingsAllocations,
                state.monthlyIncomeProjections
            );
        },
        removeBalanceItem(state, action: PayloadAction<string>) {
            const id = action.payload;
            state.currentSheet.lineItems = state.currentSheet.lineItems.filter(
                item => item.id !== id
            );
            // Generate balance sheet projections
            state.monthlyBalanceProjections = generateMonthlyBalanceProjections(
                state.currentSheet.lineItems,
                state.savingsAllocations,
                state.monthlyIncomeProjections
            );
        },
        updateBalanceItem(state, action: PayloadAction<BalanceSheetLineItem>) {
            const updatedItem = action.payload;
            state.currentSheet.lineItems = state.currentSheet.lineItems.map(item => {
                if (item.id === updatedItem.id) {
                    return updatedItem;
                }
                return item;
            });
            // Generate balance sheet projections
            state.monthlyBalanceProjections = generateMonthlyBalanceProjections(
                state.currentSheet.lineItems,
                state.savingsAllocations,
                state.monthlyIncomeProjections
            );
        },
        updateProfile: (state, action: PayloadAction<Partial<UserProfile>>) => {
            state.userProfile = { ...state.userProfile, ...action.payload };
            // Generate income projections
            state.monthlyIncomeProjections = generateMonthlyIncomeProjections(
                state.currentStatement,
                state.userProfile.stateOfResidence,
                state.userProfile.taxFilingStatus,
                state.userProfile.deductionType,
                state.userProfile.propertyTaxs,
                state.userProfile.localTaxs,
                state.userProfile.numberOfDependents,
                state.userProfile.itemizedDeductions
            );

            // Generate balance sheet projections
            state.monthlyBalanceProjections = generateMonthlyBalanceProjections(
                state.currentSheet.lineItems,
                state.savingsAllocations,
                state.monthlyIncomeProjections
            );

            // Generate planning projections
            state.projections = generateProjections(
                state.userInputs,
                state.userProfile,
                state.oneTimeExpenses
            );
        },
        resetProfile: (state) => {
            state.userProfile = initialState.userProfile;
            // Generate income projections
            state.monthlyIncomeProjections = generateMonthlyIncomeProjections(
                state.currentStatement,
                state.userProfile.stateOfResidence,
                state.userProfile.taxFilingStatus,
                state.userProfile.deductionType,
                state.userProfile.propertyTaxs,
                state.userProfile.localTaxs,
                state.userProfile.numberOfDependents,
                state.userProfile.itemizedDeductions
            );

            // Generate balance sheet projections
            state.monthlyBalanceProjections = generateMonthlyBalanceProjections(
                state.currentSheet.lineItems,
                state.savingsAllocations,
                state.monthlyIncomeProjections
            );

            // Generate planning projections
            state.projections = generateProjections(
                state.userInputs,
                state.userProfile,
                state.oneTimeExpenses
            );
        },
        updateUserInputs: (state, action: PayloadAction<Partial<UserInputs>>) => {
            state.userInputs = { ...state.userInputs, ...action.payload };
            // Generate planning projections
            state.projections = generateProjections(
                state.userInputs,
                state.userProfile,
                state.oneTimeExpenses
            );
        },
        addOneTimeExpense: (state, action: PayloadAction<OneTimeExpense>) => {
            state.oneTimeExpenses.push(action.payload);
            // Generate planning projections
            state.projections = generateProjections(
                state.userInputs,
                state.userProfile,
                state.oneTimeExpenses
            );
        },
        removeOneTimeExpense: (state, action: PayloadAction<string>) => {
            const id = action.payload;
            state.oneTimeExpenses = state.oneTimeExpenses.filter(item => item.id !== id);
            // Generate planning projections
            state.projections = generateProjections(
                state.userInputs,
                state.userProfile,
                state.oneTimeExpenses
            );
        },
        setUserProfile: (state, action: PayloadAction<UserProfile>) => {
            state.userProfile = action.payload;
        },
        setUserInputs: (state, action: PayloadAction<UserInputs>) => {
            state.userInputs = action.payload;
        },
        setOneTimeExpenses: (state, action: PayloadAction<OneTimeExpense[]>) => {
            state.oneTimeExpenses = action.payload;
        },
        setStatement: (state, action: PayloadAction<IncomeStatement>) => {
            state.currentStatement = action.payload;
        },
        setBalanceSheet: (state, action: PayloadAction<BalanceSheet>) => {
            state.currentSheet = action.payload;
        },
        setSavingsAllocations: (state, action: PayloadAction<savingsAllocation[]>) => {
            state.savingsAllocations = action.payload;
        },
        calculateFinancialProjections: (state) => {
            // Only calculate if we have all required data
            if (state.userProfile && state.userInputs && state.oneTimeExpenses) {
                state.projections = generateProjections(
                    state.userInputs,
                    state.userProfile,
                    state.oneTimeExpenses
                );
            }
        },
        calculateIncomeStatementProjections: (state) => {
            if (state.currentStatement && state.userProfile) {
                state.monthlyIncomeProjections = generateMonthlyIncomeProjections(
                    state.currentStatement,
                    state.userProfile.stateOfResidence,
                    state.userProfile.taxFilingStatus,
                    state.userProfile.deductionType,
                    state.userProfile.propertyTaxs,
                    state.userProfile.localTaxs,
                    state.userProfile.numberOfDependents,
                    state.userProfile.itemizedDeductions
                );
            
            }
        },
        calculateBalanceSheetProjections: (state) => {
            if (state.currentSheet && state.savingsAllocations && state.monthlyIncomeProjections) {
                state.monthlyBalanceProjections = generateMonthlyBalanceProjections(
                    state.currentSheet.lineItems,
                    state.savingsAllocations,
                    state.monthlyIncomeProjections
                );
            }
        },
        resetUserData: (state) => {
            state = initialState;
            return state;
        }
    }
});

export const { 
    addLineItem, 
    removeLineItem, 
    updateLineItem,
    saveSavingsAllocations, 
    addBalanceItem, 
    removeBalanceItem, 
    updateBalanceItem,
    updateProfile, 
    resetProfile, 
    updateUserInputs, 
    addOneTimeExpense, 
    removeOneTimeExpense,  
    setUserProfile, 
    setUserInputs, 
    setOneTimeExpenses, 
    setStatement,
    setBalanceSheet,
    setSavingsAllocations,
    calculateBalanceSheetProjections,
    calculateIncomeStatementProjections,
    calculateFinancialProjections,
    resetUserData
} = userDataSlice.actions;

export default userDataSlice.reducer;
