import React, { useMemo } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label
} from 'recharts';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { 
    Paper, 
    Box, 
    Typography, 
    useTheme, 
    Fade,
    Zoom,
    IconButton,
    Tooltip as MuiTooltip,
    Stack,
} from '@mui/material';
import {
    TrendingUp as TrendingUpIcon,
    Info as InfoIcon,
} from '@mui/icons-material';

const formatLargeNumber = (value: number): string => {
    const absValue = Math.abs(value);
    if (absValue >= 1e9) {
        return `$${(value / 1e9).toFixed(1)}B`;
    } else if (absValue >= 1e6) {
        return `$${(value / 1e6).toFixed(1)}M`;
    } else if (absValue >= 1e3) {
        return `$${(value / 1e3).toFixed(1)}K`;
    }
    return `$${value.toFixed(0)}`;
};

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
    const theme = useTheme();
    
    if (active && payload && payload.length) {
        return (
            <Paper 
                elevation={3} 
                sx={{ 
                    p: 2,
                    background: 'rgba(255, 255, 255, 0.95)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                }}
            >
                <Typography 
                    variant="subtitle2" 
                    sx={{ 
                        mb: 1,
                        fontWeight: 600,
                        color: 'black' 
                    }}
                >
                    {label}
                </Typography>
                <Stack spacing={0.5}>
                    {payload.map((entry: any, index: number) => (
                        <Typography 
                            key={index} 
                            variant="body2" 
                            sx={{ 
                                color: 'black',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            {entry.name}: {formatLargeNumber(entry.value)}
                        </Typography>
                    ))}
                </Stack>
            </Paper>
        );
    }
    return null;
};

const ProjectionsChart: React.FC = () => {
    const theme = useTheme();
    const projections = useSelector((state: RootState) => state.userData.projections);

    const { yDomain, hasData } = useMemo(() => {
        const allValues = projections.map(p => p.totalNetWorth);
        const maxValue = Math.max(...allValues);
        const minValue = Math.min(...allValues);
        return {
            yDomain: [
                minValue < 0 ? minValue * 1.1 : 0,
                maxValue * 1.1
            ],
            hasData: allValues.length > 0
        };
    }, [projections]);

    const chartColors = {
        total: theme.palette.primary.main
    };

    return (
        <Fade in timeout={800}>
            <Box>
                <Box 
                    sx={{ 
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 3
                    }}
                >
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            color: 'text.primary',
                            fontWeight: 600
                        }}
                    >
                        <TrendingUpIcon 
                            sx={{ 
                                color: theme.palette.primary.main,
                                filter: `drop-shadow(0 2px 4px ${theme.palette.primary.main})`,
                            }} 
                        />
                        Net Worth Projection
                    </Typography>
                    <MuiTooltip 
                        title="This chart shows your projected net worth growth based on your current inputs and market assumptions"
                        arrow
                        placement="left"
                    >
                        <IconButton 
                            size="small"
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.main,
                                }
                            }}
                        >
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </MuiTooltip>
                </Box>

                <Zoom in={hasData} timeout={400}>
                    <Box 
                        height={400}
                        sx={{
                            '.recharts-cartesian-grid-horizontal line, .recharts-cartesian-grid-vertical line': {
                                stroke: theme.palette.divider,
                            },
                            '.recharts-tooltip-cursor': {
                                stroke: theme.palette.primary.main,
                                strokeWidth: 1,
                                strokeDasharray: '5 5',
                            },
                            '.recharts-line-curve': {
                                filter: `drop-shadow(0 4px 8px ${theme.palette.primary.main})`,
                            },
                        }}
                    >
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                data={projections}
                                margin={{
                                    top: 5,
                                    right: 10,
                                    left: 40,
                                    bottom: 20,
                                }}
                            >
                                <CartesianGrid 
                                    strokeDasharray="3 3" 
                                    vertical={false}
                                />
                                <XAxis 
                                    dataKey="year" 
                                    tick={{ 
                                        fontSize: 12,
                                        fill: theme.palette.text.secondary
                                    }}
                                    tickLine={{ stroke: theme.palette.divider }}
                                    axisLine={{ stroke: theme.palette.divider }}
                                    padding={{ left: 10, right: 10 }}
                                >
                                    <Label 
                                        value="Year" 
                                        position="bottom" 
                                        offset={0}
                                        style={{
                                            textAnchor: 'middle',
                                            fontSize: 12,
                                            fill: theme.palette.text.secondary,
                                        }}
                                    />
                                </XAxis>
                                <YAxis 
                                    tickFormatter={formatLargeNumber}
                                    domain={yDomain}
                                    tick={{ 
                                        fontSize: 12,
                                        fill: theme.palette.text.secondary
                                    }}
                                    tickLine={{ stroke: theme.palette.divider }}
                                    axisLine={{ stroke: theme.palette.divider }}
                                >
                                    <Label
                                        value="Net Worth"
                                        position="left"
                                        angle={-90}
                                        offset={0}
                                        style={{
                                            textAnchor: 'middle',
                                            fontSize: 12,
                                            fill: theme.palette.text.secondary,
                                        }}
                                    />
                                </YAxis>
                                <Tooltip 
                                    content={<CustomTooltip />}
                                    cursor={{ 
                                        stroke: theme.palette.primary.main,
                                        strokeWidth: 2,
                                    }}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="totalNetWorth"
                                    name="Net Worth"
                                    stroke={chartColors.total}
                                    strokeWidth={3}
                                    dot={false}
                                    activeDot={{ 
                                        r: 6,
                                        fill: theme.palette.primary.main,
                                        stroke: theme.palette.background.paper,
                                        strokeWidth: 2,
                                    }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                </Zoom>
            </Box>
        </Fade>
    );
};

export default ProjectionsChart;
