import React from 'react';
import { useSelector } from 'react-redux';
import {
    Container,
    Grid,
    Paper,
    Typography,
    Box,
    LinearProgress,
    Card,
    CardContent,
    IconButton,
    Button,
    useTheme,
    Fade,
    Zoom,
    Divider,
    alpha,
    useMediaQuery,
} from '@mui/material';
import { RootState } from '../../store';
import {
    AccountBalanceWallet as AccountBalanceWalletIcon,
    TrendingUp as TrendingUpIcon,
    Savings as SavingsIcon,
    ShowChart as ShowChartIcon,
    Timeline as TimelineIcon,
    Water as WaterIcon,
    Speed as SpeedIcon,
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon,
    Add as AddIcon,
} from '@mui/icons-material';
import { formatCurrency, formatMonths } from '../../utils/formattingFunctions';
import { useNavigate } from 'react-router-dom';

interface MetricCardProps {
    title: string;
    value: string;
    icon: React.ReactNode;
    trend?: number;
    color?: string;
    action?: () => void;
}

const MetricCard: React.FC<MetricCardProps> = ({ title, value, icon, trend, color = '#FFD700', action }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    return (
        <Zoom in timeout={500}>
            <Card 
                sx={{ 
                    height: '100%',
                    position: 'relative',
                    overflow: 'visible',
                    cursor: action ? 'pointer' : 'default',
                    bgcolor: 'background.paper',
                    borderRadius: 3,
                    border: `1px solid ${alpha(color, 0.1)}`,
                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                    '&:hover': action ? {
                        transform: 'translateY(-8px)',
                        boxShadow: `0 8px 30px 0 ${alpha(color, 0.2)}`,
                        border: `1px solid ${alpha(color, 0.3)}`,
                        '& .metric-icon': {
                            transform: 'scale(1.1)',
                            boxShadow: `0 8px 20px 0 ${alpha(color, 0.4)}`,
                        }
                    } : {},
                }}
                onClick={action}
            >
                <CardContent sx={{ p: 3 }}>
                    <Box 
                        className="metric-icon"
                        sx={{ 
                            position: 'absolute',
                            top: isMobile ? -16 : -20,
                            left: isMobile ? 16 : 20,
                            bgcolor: color,
                            borderRadius: '50%',
                            p: isMobile ? 1.5 : 2,
                            color: 'background.paper',
                            boxShadow: `0 4px 14px 0 ${alpha(color, 0.3)}`,
                            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& svg': {
                                fontSize: isMobile ? '1.5rem' : '1.75rem',
                            }
                        }}
                    >
                        {icon}
                    </Box>
                    <Box sx={{ pt: isMobile ? 3 : 4, pb: 1 }}>
                        <Typography 
                            variant="overline" 
                            sx={{
                                color: alpha(theme.palette.text.primary, 0.7),
                                fontWeight: 500,
                                letterSpacing: '0.1em',
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography 
                            variant="h4" 
                            component="div" 
                            sx={{ 
                                my: 1,
                                fontWeight: 700,
                                color: theme.palette.text.primary,
                                fontSize: isMobile ? '1.5rem' : '2rem',
                            }}
                        >
                            {value}
                        </Typography>
                        {trend !== undefined && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                <Box
                                    sx={{ 
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        p: 0.8,
                                        borderRadius: 2,
                                        bgcolor: trend >= 0 ? alpha(theme.palette.success.main, 0.1) : alpha(theme.palette.error.main, 0.1),
                                    }}
                                >
                                    {trend >= 0 ? <ArrowUpwardIcon sx={{ color: 'success.main', fontSize: '1.25rem' }} /> 
                                               : <ArrowDownwardIcon sx={{ color: 'error.main', fontSize: '1.25rem' }} />}
                                </Box>
                                <Typography 
                                    variant="body2" 
                                    sx={{
                                        color: trend >= 0 ? 'success.main' : 'error.main',
                                        fontWeight: 600,
                                        fontSize: '0.875rem',
                                    }}
                                >
                                    {Math.abs(trend)}% {trend >= 0 ? 'Increase' : 'Decrease'}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </Zoom>
    );
};

const Home: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const {monthlyBalanceProjections, monthlyIncomeProjections} = useSelector((state: RootState) => state.userData);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Calculate metrics
    const currentNetWorth = monthlyBalanceProjections[0]?.netWorth || 0;
    const projectedIncome = monthlyIncomeProjections.slice(0, 12).reduce((sum, month) => sum + month.totalIncome, 0);
    const projectedNetSavings = monthlyIncomeProjections.slice(0, 12).reduce((sum, month) => sum + month.netAmount, 0);
    const projectedNetWorthChange = monthlyBalanceProjections[12]?.netWorth - monthlyBalanceProjections[0]?.netWorth || 0;
    const projectedAssetReturns = projectedNetWorthChange - projectedNetSavings;
    const avgMonthlyExpenses = monthlyIncomeProjections.slice(0, 12).reduce((sum, month) => sum + month.totalExpenses, 0) / 12;
    const liquidAssets = monthlyBalanceProjections[0]?.liquidAssets || 0;
    const cashRunway = avgMonthlyExpenses > 0 ? liquidAssets / avgMonthlyExpenses : 0;
    const assetRunway = (projectedAssetReturns - (avgMonthlyExpenses * 12)) < 0 ? 
        currentNetWorth / Math.abs(((projectedAssetReturns / 12) - avgMonthlyExpenses)) : Infinity;

    const calculateTrendPercentage = (current: number, projected: number) => {
        if (current === 0) return 0;
        return Math.round((projected - current) / Math.abs(current) * 100);
    };

    const metrics = [
        {
            title: 'Current Net Worth',
            value: formatCurrency(currentNetWorth),
            icon: <AccountBalanceWalletIcon />,
            trend: calculateTrendPercentage(currentNetWorth, monthlyBalanceProjections[11]?.netWorth || 0),
            color: theme.palette.primary.main,
            action: () => navigate('/balance-sheet'),
        },
        {
            title: 'Projected Annual Income',
            value: formatCurrency(projectedIncome),
            icon: <TrendingUpIcon />,
            trend: calculateTrendPercentage(monthlyIncomeProjections[0]?.totalIncome * 12 || 0, projectedIncome),
            color: theme.palette.success.main,
            action: () => navigate('/income-statement'),
        },
        {
            title: 'Projected Net Savings',
            value: formatCurrency(projectedNetSavings),
            icon: <SavingsIcon />,
            trend: calculateTrendPercentage(monthlyIncomeProjections[0]?.netAmount * 12 || 0, projectedNetSavings),
            color: '#ed6c02',
            action: () => navigate('/income-statement'),
        },
        {
            title: 'Projected Asset Returns',
            value: formatCurrency(projectedAssetReturns),
            icon: <ShowChartIcon />,
            trend: calculateTrendPercentage(
                monthlyBalanceProjections[0]?.assetReturns - monthlyBalanceProjections[0]?.liabilityReturns || 0,
                monthlyBalanceProjections[11]?.assetReturns - monthlyBalanceProjections[11]?.liabilityReturns || 0
            ),
            color: theme.palette.secondary.main,
            action: () => navigate('/balance-sheet'),
        },
        {
            title: 'Net Worth Change (1 Year)',
            value: formatCurrency(projectedNetWorthChange),
            icon: <TimelineIcon />,
            trend: calculateTrendPercentage(currentNetWorth, currentNetWorth + projectedNetWorthChange),
            color: theme.palette.error.main,
        },
        {
            title: 'Cash Runway',
            value: formatMonths(cashRunway),
            icon: <WaterIcon />,
            color: theme.palette.info.main,
        },
        {
            title: 'Asset Runway',
            value: formatMonths(assetRunway),
            icon: <SpeedIcon />,
            color: theme.palette.success.dark,
        },
    ];

    const quickActions = [
        {
            title: 'Build your Budget',
            path: '/income-statement',
        },
        {
            title: 'Track your Net Worth',
            path: '/balance-sheet',
        },
        {
            title: 'Create your Financial Plan',
            path: '/financial-planning',
        },
    ];

    return (
        <Container maxWidth="xl" sx={{ py: 4 }}>
            <Box sx={{ mb: 6 }}>
                <Typography 
                    variant="h4" 
                    component="h1" 
                    sx={{ 
                        mb: 2,
                        fontWeight: 700,
                        color: theme.palette.primary.main,
                        textShadow: `0 2px 10px ${alpha(theme.palette.primary.main, 0.2)}`,
                    }}
                >
                    Financial Dashboard
                </Typography>
                <Typography 
                    variant="body1" 
                    sx={{ 
                        color: alpha(theme.palette.text.primary, 0.7),
                        maxWidth: 600,
                    }}
                >
                    Track your financial health with real-time metrics and projections..
                </Typography>
            </Box>

            <Grid container spacing={3}>
                {metrics.map((metric, index) => (
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        md={4} 
                        lg={3} 
                        key={metric.title}
                        sx={{
                            animation: 'fadeInUp 0.5s ease-out',
                            animationDelay: `${index * 0.1}s`,
                            '@keyframes fadeInUp': {
                                '0%': {
                                    opacity: 0,
                                    transform: 'translateY(20px)',
                                },
                                '100%': {
                                    opacity: 1,
                                    transform: 'translateY(0)',
                                },
                            },
                        }}
                    >
                        <MetricCard {...metric} />
                    </Grid>
                ))}
            </Grid>

            <Box sx={{ mt: 6, textAlign: 'center' }}>
                {quickActions.map((action, index) => (
                <Button
                    variant="contained"
                    size="large"
                    onClick={() => navigate(action.path)}
                    startIcon={<AddIcon />}
                    sx={{
                        px: 4,
                        py: 1.5,
                        borderRadius: 3,
                        m: 1,
                        background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
                        boxShadow: `0 4px 20px 0 ${alpha(theme.palette.primary.main, 0.4)}`,
                        '&:hover': {
                            background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
                            transform: 'translateY(-2px)',
                        }
                    }}
                >
                    {action.title}
                </Button>
                ))}
            </Box>
        </Container>
    );
};

export default Home;
