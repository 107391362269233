import { LineItem, MonthlyProjection } from '../types';
import { FEDERAL_TAX_BRACKETS, FEDERAL_STANDARD_DEDUCTIONS } from '../utils/calculations';
import { calculateTax, calculateStateTax, calculateSocialSecurityTax, calculateMedicareTax } from '../utils/calculations';

export const generateMonthlyIncomeProjections = (
    currentStatement: { lineItems: LineItem[] },
    stateOfResidence: string,
    userTaxFilingStatus: string,
    userDeductionType: string,
    propertyTax: number,
    localTax: number,
    numberOfDependents: number,
    itemizedDeductions: number
) => {
    // Generate 12 months of projections
    const projections: MonthlyProjection[] = [];
    const today = new Date();
    const cleanedProperty = (propertyTax === undefined || propertyTax === null) ? 0 : propertyTax
    const cleanedLocal = (localTax === undefined || localTax === null) ? 0 : localTax;
    const dependents = (numberOfDependents === undefined || numberOfDependents === null) ? 0 : numberOfDependents;

    for (let i = 0; i < 13; i++) {
        const month = new Date(today.getFullYear(), today.getMonth() + i, 1);
        projections.push(generateMonthlyIncomeProjection(currentStatement.lineItems, month, stateOfResidence, userTaxFilingStatus, userDeductionType, cleanedProperty, cleanedLocal, dependents, itemizedDeductions));
    }
    return projections;
};

export const generateMonthlyIncomeProjection = (
    lineItems: LineItem[], 
    month: Date,
    userState: string,
    userTaxFilingStatus: string,
    userDeductionType: string,
    propertyTax: number,
    localTax: number,
    numberOfDependents: number,
    itemizedDeductions: number
): MonthlyProjection => {
    const today = new Date();
    const lineItemProjections: { [key: string]: number } = {};
    let totalIncome = 0;
    let totalExpenses = 0;

    lineItems.forEach(item => {
        let amount = 0;
        const startDate = item.startDate ? new Date(item.startDate) : today;
        
        const itemMonth = startDate.getMonth();
        const itemYear = startDate.getFullYear();
        
        switch (item.frequency) {
            case 'Bi-weekly':
                if ((month.getMonth() >= itemMonth && month.getFullYear() === itemYear) || month.getFullYear() > itemYear) {
                    amount = item.amount * (52 / 24);
                }
                break;
            case 'Monthly':
                if ((month.getMonth() >= itemMonth && month.getFullYear() === itemYear) || month.getFullYear() > itemYear) {
                    amount = item.amount;
                }
                break;
            case 'Annual':
                if ((month.getMonth() >= itemMonth && month.getFullYear() === itemYear) || month.getFullYear() > itemYear) {
                    amount = item.amount / 12;
                }
                break;
            case 'One-Time':
                // Only include one-time items in their specific month
                if (month.getMonth() === itemMonth && month.getFullYear() === itemYear) {
                    amount = item.amount;
                }
                break;
        }

        lineItemProjections[item.id] = amount;
        
        if (item.type === 'Income') {
            totalIncome += amount;
        } else {
            totalExpenses += amount;
        }
    });

    // Calculate taxes on monthly income (annualized)
    const annualizedIncome = totalIncome * 12;
    const federalTax = calculateTax(annualizedIncome, FEDERAL_TAX_BRACKETS, userTaxFilingStatus, userDeductionType, FEDERAL_STANDARD_DEDUCTIONS, [], numberOfDependents, itemizedDeductions) / 12;
    const stateTax = calculateStateTax(annualizedIncome, userState, userTaxFilingStatus, userDeductionType, numberOfDependents, itemizedDeductions) / 12;
    const socialSecurityTax = calculateSocialSecurityTax(annualizedIncome) / 12;
    const medicareTax = calculateMedicareTax(annualizedIncome) / 12;
    const totalTax = federalTax + stateTax + socialSecurityTax + medicareTax + (propertyTax/12) + (localTax/12);
    
    // Calculate net income after taxes
    const netIncome = totalIncome - totalTax;
    const netAmount = netIncome - totalExpenses;

    return {
        month,
        lineItemProjections,
        totalIncome,
        totalExpenses,
        netAmount,
        federalTax,
        stateTax,
        socialSecurityTax,
        medicareTax,
        propertyTax: propertyTax/12,
        localTax: localTax/12,
        totalTax,
        netIncome
    };
};