import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { ThemeProvider, CssBaseline, Box, Container } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { store } from './store';
import { LineItem } from './types';
import theme from './theme';
import Home from './components/mainTabs/Home';
import Dashboard from './components/mainTabs/Dashboard';
import IncomeStatement from './components/mainTabs/IncomeStatement';
import BalanceSheet from './components/mainTabs/BalanceSheet';
import UserProfile from './components/mainTabs/UserProfile';
import Sidebar from './components/Sidebar';
import HomePage from './components/HomePage';
import ProtectedRoute from './components/ProtectedRoute';
import { RootState } from './store';
import api from './services/api';
import {  
  setUserInputs, 
  setOneTimeExpenses, 
  setUserProfile, 
  setStatement, 
  setBalanceSheet,
  setSavingsAllocations,
  calculateBalanceSheetProjections,
  calculateIncomeStatementProjections,
  calculateFinancialProjections,
} from './store/userDataSlice';
import { loginSuccess, logout } from './store/authSlice';
import { resetUserData } from './store/userDataSlice';
import SessionTimeoutModal from './components/SessionTimeoutModal';

// Separate component for initialization logic
function DataInitializer() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const lineItemStringToDate = (lineItem: LineItem) => {
    const stringDate = lineItem.startDate.toString();
    lineItem.startDate = new Date(stringDate);
    return lineItem; 
}

  useEffect(() => {
    const initializeData = async () => {
      if (!isAuthenticated) return;
      
      try {
        const [userInputsResponse, oneTimeExpensesResponse, userProfileResponse, statementResponse, balanceSheetResponse, savingsAllocationsResponse] = await Promise.all([
          api.get('/getUserInputs'),
          api.get('/getOneTimeExpenses'),
          api.get('/getCurrentUser'),
          api.get('/getIncomeStatementItems'),
          api.get('/getBalanceSheetItems'),
          api.get('/getSavingAllocations'),
        ]);
        
        const incomeStatementItemsDated = statementResponse.data.map(lineItemStringToDate);

        // Set all the data first
        dispatch(setUserProfile(userProfileResponse.data));
        dispatch(setOneTimeExpenses(oneTimeExpensesResponse.data));
        dispatch(setStatement({ lineItems: incomeStatementItemsDated }));
        dispatch(setBalanceSheet({ lineItems: balanceSheetResponse.data }));
        dispatch(setSavingsAllocations(savingsAllocationsResponse.data));

        if (userInputsResponse.data.length > 0) {
          dispatch(setUserInputs(userInputsResponse.data[0]));
        } else {
          dispatch(setUserInputs({
            id: '',
            startingCashBalance: 0,
            startingInvestmentBalance: 0,
            currentPreTaxIncome: 0,
            currentExpenses: 0,
            realWageGrowth: 2,
            realSpendingGrowth: 2,
            returnProfile: 'Normal'
          }));
        }

        dispatch(calculateIncomeStatementProjections());
        dispatch(calculateBalanceSheetProjections());
        dispatch(calculateFinancialProjections());
      } catch (error) {
        console.error('Error initializing data:', error);
      }
    };

    initializeData();
  }, [dispatch, isAuthenticated]);

  return null;
}

function AppContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [sessionTimeRemaining, setSessionTimeRemaining] = useState(0);
  const [sessionCheckInterval, setSessionCheckInterval] = useState<NodeJS.Timeout | null>(null);

  const checkSession = async () => {
    try {
      const response = await api.get('/getSessionTimeout');
      const timeoutInSeconds = response.data.timeoutInSeconds;
      setSessionTimeRemaining(timeoutInSeconds);
      console.log('Session timeout:', timeoutInSeconds);
      if (timeoutInSeconds <= 300 && timeoutInSeconds > 0) { // 5 minutes or less
        setShowTimeoutModal(true);
      }
    } catch (error) {
      console.error('Error checking session:', error);
      handleLogout();
    }
  };

  const extendSession = async () => {
    try {
      await api.post('/extendSession');
      setShowTimeoutModal(false);
      checkSession(); // Refresh the session time
    } catch (error) {
      console.error('Error extending session:', error);
      handleLogout();
    }
  };

  const handleLogout = async () => {
    if (sessionCheckInterval) {
      clearInterval(sessionCheckInterval);
    }
    dispatch(logout());
    dispatch(resetUserData());
    await api.post('/logout').then(() => {;
      navigate('/');
    });
    setShowTimeoutModal(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      // Initial session check
      checkSession();

      // Set up interval to check session every minute
      const interval = setInterval(checkSession, 60000);
      setSessionCheckInterval(interval);

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [isAuthenticated]);

  const checkAuth = async () => {
    try {
      const response = await api.post('/checkAuth');
      if (response.data.isAuthenticated) {
        dispatch(loginSuccess());
        navigate('/home');
      }
    } catch (error) {
      dispatch(logout());
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  return (
    <>
      <SessionTimeoutModal
        open={showTimeoutModal}
        onExtend={extendSession}
        onLogout={handleLogout}
        remainingTime={sessionTimeRemaining}
      />
      <Box sx={{ 
        display: 'flex', 
        minHeight: '100vh',
        width: '100%',
        overflow: 'hidden'
      }}>
        <Sidebar />
        <Box 
          component="main" 
          sx={{ 
            flexGrow: 1, 
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            p: 0,
            overflow: 'auto'
          }}
        >
          <Routes>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/profile" element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            } />
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/financial-planning"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/income-statement" element={
              <ProtectedRoute>
                <IncomeStatement />
              </ProtectedRoute>
            } />
            <Route path="/balance-sheet" element={
              <ProtectedRoute>
                <BalanceSheet />
              </ProtectedRoute>
            } />
          </Routes>
        </Box>
      </Box>
    </>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Router>
            <DataInitializer />
            <Container maxWidth={false}>
              <AppContent />
            </Container>
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
