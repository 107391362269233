import { BalanceSheetLineItem, MonthlyBalanceProjection, savingsAllocation, MonthlyProjection } from '../types';

const generateMonthlyBalanceProjection = (
    lineItems: BalanceSheetLineItem[],
    month: Date,
    savingsAllocations: savingsAllocation[],
    incomeProjections: MonthlyProjection[]
): MonthlyBalanceProjection => {
    const lineItemProjections: { [key: string]: number } = {};
    let cash = 0;
    let checkingAccount = 0;
    let savingsAccount = 0;
    let moneyMarketAccount = 0;
    let certificateOfDeposit = 0;
    let treasuryBill = 0;
    let stock = 0;
    let bond = 0;
    let gold = 0;
    let commodity = 0;
    let cryptocurrency = 0;
    let privateInvestment = 0;
    let primaryResidence = 0;
    let vacationHome = 0;
    let rentalProperty = 0;
    let land = 0;
    let timeshare = 0;
    let commercialRealEstate = 0;
    let vehicle = 0;
    let boat = 0;
    let recreationalVehicle = 0;
    let jewelry = 0;
    let furniture = 0;
    let artAndCollectibles = 0;
    let businessOwnershipInterest = 0;
    let lifeInsurance = 0;
    let otherAsset = 0;
    let creditCardDebt = 0;
    let paydayLoan = 0;
    let buyNowPayLaterBalance = 0;
    let medicalBills = 0;
    let outstandingUtilityBills = 0;
    let mortgage = 0;
    let studentLoan = 0;
    let autoLoan = 0;
    let personalLoan = 0;
    let homeEquityLoan = 0;
    let otherLoan = 0;
    let taxDebt = 0;
    let legalSettlement = 0;
    let otherLiabilities = 0;
    let liquidAssets = 0;
    let investments = 0;
    let property = 0;
    let personalAssets = 0;
    let otherAssets = 0;
    let shortTermLiabilities = 0;
    let longTermLiabilities = 0;

    let assetReturns = 0;
    let liabilityReturns = 0;

    lineItems.forEach(item => {
        let projectedAmount = item.amount;
        
        
        // Apply return/interest rate if available
        if (item.returnRate) {
            const monthsSinceStart = (month.getFullYear() - new Date().getFullYear()) * 12 + 
                                   (month.getMonth() - new Date().getMonth());
            const monthlyRate = Math.pow(1 + (item.returnRate / 100), 1/12) - 1;
            projectedAmount *= Math.pow(1 + monthlyRate, monthsSinceStart);

            let interest = projectedAmount- (Math.pow(1 + monthlyRate, monthsSinceStart - 1) * item.amount);

            if (item.type === 'Short-term Liabilities' || item.type === 'Long-term Liabilities') {
                liabilityReturns += interest;
            } else {
                assetReturns += interest;
        }
        }

        lineItemProjections[item.id] = projectedAmount;

        // Aggregate by type
        switch (item.category) {
            case 'Cash':
                cash += projectedAmount;
                break;
            case 'Checking accounts':
                checkingAccount += projectedAmount;
                break;
            case 'Savings accounts':
                savingsAccount += projectedAmount;
                break;
            case 'Money market accounts':
                moneyMarketAccount += projectedAmount;
                break;
            case 'Certificates of deposit (CDs)':
                certificateOfDeposit += projectedAmount;
                break;
            case 'Treasury bills (T-bills)':
                treasuryBill += projectedAmount;
                break;
            case 'Stocks':
                stock += projectedAmount;
                break;
            case 'Bonds':
                bond += projectedAmount;
                break;
            case 'Gold':
                gold += projectedAmount;
                break;
            case 'Commodities':
                commodity += projectedAmount;
                break;
            case 'Cryptocurrency':
                cryptocurrency += projectedAmount;
                break;
            case 'Private investments':
                privateInvestment += projectedAmount;
                break;
            case 'Primary residence':
                primaryResidence += projectedAmount;
                break;
            case 'Vacation homes':
                vacationHome += projectedAmount;
                break;
            case 'Rental properties':
                rentalProperty += projectedAmount;
                break;
            case 'Land':
                land += projectedAmount;
                break;
            case 'Timeshares':
                timeshare += projectedAmount;
                break;
            case 'Commercial real estate':
                commercialRealEstate += projectedAmount;
                break;
            case 'Vehicles':
                vehicle += projectedAmount;
                break;
            case 'Boats':
                boat += projectedAmount;
                break;
            case 'Recreational vehicles':
                recreationalVehicle += projectedAmount;
                break;
            case 'Jewelry':
                jewelry += projectedAmount;
                break;
            case 'Furniture':
                furniture += projectedAmount;
                break;
            case 'Art and collectibles':
                artAndCollectibles += projectedAmount;
                break;
            case 'Business ownership interest':
                businessOwnershipInterest += projectedAmount;
                break;
            case 'Life insurance':
                lifeInsurance += projectedAmount;
                break;
            case 'Other asset':
                otherAsset += projectedAmount;
                break;
            case 'Credit card debt':
                creditCardDebt += projectedAmount;
                break;
            case 'Payday loans':
                paydayLoan += projectedAmount;
                break;
            case 'Buy now, pay later balances':
                buyNowPayLaterBalance += projectedAmount;
                break;
            case 'Medical bills':
                medicalBills += projectedAmount;
                break;
            case 'Outstanding utility bills':
                outstandingUtilityBills += projectedAmount;
                break;
            case 'Mortgage':
                mortgage += projectedAmount;
                break;
            case 'Student loans':
                studentLoan += projectedAmount;
                break;
            case 'Auto loans':
                autoLoan += projectedAmount;
                break;
            case 'Personal loans':
                personalLoan += projectedAmount;
                break;
            case 'Home equity loans':
                homeEquityLoan += projectedAmount;
                break;
            case 'Other loans':
                otherLoan += projectedAmount;
                break;
            case 'Tax debt':
                taxDebt += projectedAmount;
                break;
            case 'Legal settlements':
                legalSettlement += projectedAmount;
                break;
            case 'Other liabilities':
                otherLiabilities += projectedAmount;
                break;
        }
    });

    let netAmount = 0;
    let amountArray = [];
    let avgMonths = 0;
    if (incomeProjections.length > 0) {
        let projectionIndex = incomeProjections.findIndex(p => (p.month.getFullYear() === month.getFullYear()) && (p.month.getMonth() === month.getMonth()));
        for (let i = 0; i < projectionIndex + 1; i++) {
            netAmount += incomeProjections[i].netAmount;
            amountArray.push(incomeProjections[i].netAmount);
        }
        
        if (netAmount > 0) {
            amountArray = amountArray.map(a => a / netAmount);
            avgMonths = amountArray.reduce((a, b, c) => a + (b * c));
        } 
    }
    
    for (let i = 0; i < savingsAllocations.length; i++) {
        const allocation = savingsAllocations[i];

        let count = 0;
        let rate = 0;

        lineItems.forEach(item => {
            if (item.category === allocation.category) {
                count++;
                rate += item.returnRate || 0;
            }
        });
        
        const avgRate = count > 0 ? rate / count : 0;

        const savings = netAmount * (allocation.percentage / 100);
        const savingsPlusInterest = Math.pow(1 + (avgRate / 100 / 12), avgMonths) * savings;
        
        const savingsMonthlyInterest = savingsPlusInterest - (Math.pow(1 + (avgRate / 100 / 12), avgMonths - 1) * savings);
        assetReturns += savingsMonthlyInterest; 

        switch (allocation.category) {
            case 'Cash':
                cash += savingsPlusInterest;
                break;
            case 'Checking accounts':
                checkingAccount += savingsPlusInterest;
                break;
            case 'Savings accounts':
                savingsAccount += savingsPlusInterest;
                break;
            case 'Money market accounts':
                moneyMarketAccount += savingsPlusInterest;
                break;
            case 'Certificates of deposit (CDs)':
                certificateOfDeposit += savingsPlusInterest;
                break;
            case 'Treasury bills (T-bills)':
                treasuryBill += savingsPlusInterest;
                break;
            case 'Stocks':
                stock += savingsPlusInterest;
                break;
            case 'Bonds':
                bond += savingsPlusInterest;
                break;
            case 'Gold':
                gold += savingsPlusInterest;
                break;
            case 'Commodities':
                commodity += savingsPlusInterest;
                break;
            case 'Cryptocurrency':
                cryptocurrency += savingsPlusInterest;
                break;
            case 'Private investments':
                privateInvestment += savingsPlusInterest;
                break;
            case 'Other asset':
                otherAsset += savingsPlusInterest;
                break;
        }
    }

    liquidAssets += cash + checkingAccount + savingsAccount + moneyMarketAccount + certificateOfDeposit + treasuryBill;
    investments += stock + bond + gold + commodity + cryptocurrency + privateInvestment;
    property += primaryResidence + vacationHome + rentalProperty + land + timeshare + commercialRealEstate;
    personalAssets += vehicle + boat + recreationalVehicle + jewelry + furniture + artAndCollectibles;
    otherAssets += businessOwnershipInterest + lifeInsurance + otherAsset;
    shortTermLiabilities += creditCardDebt + paydayLoan + buyNowPayLaterBalance + medicalBills + outstandingUtilityBills;
    longTermLiabilities += mortgage + studentLoan + autoLoan + personalLoan + homeEquityLoan + otherLoan + taxDebt + legalSettlement + otherLiabilities;
    
    const totalAssets = liquidAssets + investments + property + personalAssets + otherAssets;
    const totalLiabilities = shortTermLiabilities + longTermLiabilities;

    return {
        month,
        totalAssets,
        totalLiabilities,
        netWorth: totalAssets - totalLiabilities,
        liquidAssets,
        investments,
        property,
        personalAssets,
        otherAssets,
        shortTermLiabilities,
        longTermLiabilities,
        cash,
        checkingAccount,
        savingsAccount,
        moneyMarketAccount,
        certificateOfDeposit,
        treasuryBill,
        stock,
        bond,
        gold,
        commodity,
        cryptocurrency,
        privateInvestment,
        primaryResidence,
        vacationHome,
        rentalProperty,
        land,
        timeshare,
        commercialRealEstate,
        vehicle,
        boat,
        recreationalVehicle,
        jewelry,
        furniture,
        artAndCollectibles,
        businessOwnershipInterest,
        lifeInsurance,
        otherAsset,
        creditCardDebt,
        paydayLoan,
        buyNowPayLaterBalance,
        medicalBills,
        outstandingUtilityBills,
        mortgage,
        studentLoan,
        autoLoan,
        personalLoan,
        homeEquityLoan,
        otherLoan,
        taxDebt,
        legalSettlement,
        otherLiabilities,
        assetReturns,
        liabilityReturns,
        lineItemProjections
    };
};

export const generateMonthlyBalanceProjections = (
    lineItems: BalanceSheetLineItem[],
    savingsAllocations: savingsAllocation[],
    incomeMonthlyProjections: MonthlyProjection[]
): MonthlyBalanceProjection[] => {
    const projections: MonthlyBalanceProjection[] = [];
    const today = new Date();
    
    for (let i = 0; i < 13; i++) {
        const month = new Date(today.getFullYear(), today.getMonth() + i, 1);
        projections.push(generateMonthlyBalanceProjection(lineItems, month, savingsAllocations, incomeMonthlyProjections));
    }
    
    return projections;
};
